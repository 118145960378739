/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { CompanyModelSelector } from "./CompanyModel.base"
import { FinalDilligenceCompanyModel, FinalDilligenceCompanyModelType } from "./FinalDilligenceCompanyModel"
import { FinalDilligenceCompanyModelSelector } from "./FinalDilligenceCompanyModel.base"
import { FundModel, FundModelType } from "./FundModel"
import { FundModelSelector } from "./FundModel.base"
import { RootStoreType } from "./index"


/**
 * TrendBase
 * auto generated base class for the model TrendModel.
 */
export const TrendModelBase = ModelBase
  .named('Trend')
  .props({
    __typename: types.optional(types.literal("Trend"), "Trend"),
    id: types.union(types.undefined, types.integer),
    fund_id: types.union(types.undefined, types.integer),
    fund: types.union(types.undefined, types.late((): any => FundModel)),
    name: types.union(types.undefined, types.null, types.string),
    icon: types.union(types.undefined, types.null, types.string),
    ordinal: types.union(types.undefined, types.integer),
    companies_identified_count: types.union(types.undefined, types.null, types.integer),
    initial_calls_count: types.union(types.undefined, types.null, types.integer),
    secondary_dilligence_count: types.union(types.undefined, types.null, types.integer),
    final_dilligence_companies: types.union(types.undefined, types.array(types.late((): any => FinalDilligenceCompanyModel))),
    final_dilligence_count: types.union(types.undefined, types.null, types.integer),
    portfolio_companies: types.union(types.undefined, types.array(types.late((): any => CompanyModel))),
    selectable: types.union(types.undefined, types.null, types.boolean),
    fund_I_to_III_portoflio_companies: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => CompanyModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class TrendModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get fund_id() { return this.__attr(`fund_id`) }
  get name() { return this.__attr(`name`) }
  get icon() { return this.__attr(`icon`) }
  get ordinal() { return this.__attr(`ordinal`) }
  get companies_identified_count() { return this.__attr(`companies_identified_count`) }
  get initial_calls_count() { return this.__attr(`initial_calls_count`) }
  get secondary_dilligence_count() { return this.__attr(`secondary_dilligence_count`) }
  get final_dilligence_count() { return this.__attr(`final_dilligence_count`) }
  get selectable() { return this.__attr(`selectable`) }
  fund(builder?: string | FundModelSelector | ((selector: FundModelSelector) => FundModelSelector)) { return this.__child(`fund`, FundModelSelector, builder) }
  final_dilligence_companies(builder?: string | FinalDilligenceCompanyModelSelector | ((selector: FinalDilligenceCompanyModelSelector) => FinalDilligenceCompanyModelSelector)) { return this.__child(`final_dilligence_companies`, FinalDilligenceCompanyModelSelector, builder) }
  portfolio_companies(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`portfolio_companies`, CompanyModelSelector, builder) }
  fund_I_to_III_portoflio_companies(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`fund_I_to_III_portoflio_companies`, CompanyModelSelector, builder) }
}
export function selectFromTrend() {
  return new TrendModelSelector()
}

export const trendModelPrimitives = selectFromTrend().fund_id.name.icon.ordinal.companies_identified_count.initial_calls_count.secondary_dilligence_count.final_dilligence_count.selectable
