import * as React from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../../utils/theme";
import { AppPanelTitle, AppFormTitle, ToolTipContainer, Loading } from "../../UI";
import { PanelContainer, PanelWrapper, PanelContent, PanelFooter } from "../../Dumb";
import { useState, FunctionComponent, useEffect } from "react";
import { mobileSize } from "../../../utils/breakpoints";
import ReactTooltip from "react-tooltip";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";
import { CompanyPortfolioModal, VideoModal } from "../../Modals";
import { formatDate } from "../../../utils/format";
import { stageOrder } from "../../../utils/sort";
import { returnIfNormalFund } from "../../Dumb/utils";
import { trackPortfolioClicked } from "../../../services/mixpanel";

interface PortfolioProps {
  fund_id: number;
}

interface Coinvestor {
  name: string;
  url?: string;
}

interface FundingRounds {
  stage: string;
  total_investment: number;
  base10_invest: number;
  ownership: number;
  funding_date: Date;
  coinvestors: Coinvestor[];
}

const PortfolioPanel: FunctionComponent<PortfolioProps> = (props) => {
  const [companyPortfolioVisible, setCompanyPortfolioVisible] = useState(false);
  const [PortfolioInfoId, setPortfolioInfoId] = useState(0);
  const [videoLink, setVideoLink] = useState("");
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  const { store } = useQuery();

  const selectedFund = store.selected_fund;

  const selectedFundIsBase10PartnersFundIIOrIII = selectedFund?.id === 13 || selectedFund?.id === 19;

  const togglePortfolioModal = () => {
    setCompanyPortfolioVisible(!companyPortfolioVisible);
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const {
    data: portfolioData,
    loading: portfolioLoading,
    error: portfolioError,
    setQuery: portfolioSetQuery,
  } = useQuery((store) =>
    store.queryFetchCompanies(
      { fundId: props.fund_id },
      (qb) =>
        qb.logo_url.gross_moic.base10_total_investment.initial_moic.show_initial_moic.governance_maturity_index.name
          .funding_rounds((round) =>
            round.stage.total_investment.ownership.base10_invest.funding_date.coinvestors((inv) => inv.name.url),
          )
          .role.coinvestors((investor) => investor.name).founder_video_url.headquarters.industry_string.trend_string
          .company_total_investment.founder_photo_url.base10_fmv,
    ),
  );

  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.portfolio_footnote),
  );

  useEffect(() => {
    const query = store.queryFetchCompanies(
      { fundId: props.fund_id },
      (qb) =>
        qb.logo_url.gross_moic.base10_total_investment.initial_moic.show_initial_moic.governance_maturity_index.name
          .funding_rounds((round) =>
            round.stage.total_investment.ownership.base10_invest.funding_date.coinvestors((inv) => inv.name.url),
          )
          .role.coinvestors((investor) => investor.name).founder_video_url.headquarters.industry_string.trend_string
          .company_total_investment.founder_photo_url.base10_fmv.growth_category,
    );
    portfolioSetQuery(query);
  }, [props.fund_id]);

  useEffect(() => {
    const query = store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.portfolio_footnote);
    fundQuery(query);
  }, [props.fund_id]);

  if (portfolioLoading || fundLoading) {
    return (
      <PanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </PanelContainer>
    );
  }

  if (portfolioError || !portfolioData || !portfolioData.fetchCompanies || fundError || !fundData || !fundData.fund) {
    return <p>Error</p>;
  }

  console.log("portfolio data: ", portfolioData);

  const { fetchCompanies } = portfolioData;

  const {
    fund: { portfolio_footnote },
  } = fundData;

  const showFootNoteForAdvancmentInitiativeII = selectedFund?.id === 16;

  const advancementInitiativeIIFootNote =
    "Opportunistic investments include select, non-core opportunistic investments that will not represent more than 5% of the fund.";

  /**
   * If truthy, show the founder video column. Else, do not.
   */
  const show_videos = !!fetchCompanies.filter((c) => !!c.founder_video_url).length!;

  return (
    <PanelContainer>
      {companyPortfolioVisible && (
        <CompanyPortfolioModal
          companyId={PortfolioInfoId}
          toggleModal={togglePortfolioModal}
          showModal={companyPortfolioVisible}
          displayLimitedInfo={!returnIfNormalFund(props.fund_id)}
        />
      )}
      <VideoModal toggleModal={toggleVideoModal} showModal={videoModalVisible} url={videoLink} />
      <PanelWrapper>
        <AppPanelTitle>PORTFOLIO</AppPanelTitle>
        <PanelDescription>Click on logos for more information.</PanelDescription>
        <PanelContent>
          <TableDiv>
            <TableContainer>
              <thead>
                <tr>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell>Gross MOIC</TableHeaderCell>

                  {returnIfNormalFund(props.fund_id) ? (
                    <TableHeaderCell>Round(s)</TableHeaderCell>
                  ) : (
                    <TableHeaderCell>Stage</TableHeaderCell>
                  )}

                  <TableHeaderCell>Base10 Investment ($M)</TableHeaderCell>
                  {returnIfNormalFund(props.fund_id) && <TableHeaderCell>Ownership</TableHeaderCell>}
                  <TableHeaderCell>Date</TableHeaderCell>
                  <TableHeaderCell>FMV($M)</TableHeaderCell>
                  {returnIfNormalFund(props.fund_id) && <TableHeaderCell>Role</TableHeaderCell>}
                  {/* {!selectedFundIsBase10PartnersFundIIOrIII && <TableHeaderCell>Coinvestors</TableHeaderCell>} */}
                  <TableHeaderCell>
                    <div data-tip data-for={`industry`}>
                      Industry
                    </div>
                    <ReactTooltip
                      id={`industry`}
                      effect="solid"
                      // delayHide={1000}
                    >
                      <ToolTipContainer style={{ width: 200, overflow: "hidden" }}>
                        Industry in which the customer operates(for vertical SaaS products) or functional department in
                        which the customer operates (for horizontal SaaS products)
                      </ToolTipContainer>
                    </ReactTooltip>
                  </TableHeaderCell>
                  {/* <TableHeaderCell>Trend</TableHeaderCell> */}
                  {/* {show_videos && <TableHeaderCell>Founder Video</TableHeaderCell>} */}
                  <TableHeaderCell>Location</TableHeaderCell>
                  <TableHeaderCell>
                    <div data-tip data-for={`governance_maturity_index`}>
                      Governance Maturity Index
                    </div>
                    <ReactTooltip
                      id={`governance_maturity_index`}
                      effect="solid"
                      // delayHide={1000}
                    >
                      <ToolTipContainer style={{ width: 200, overflow: "hidden" }}>
                        Measures company management and governance maturity using insights from interviews with public
                        companies, industry experts, and investors
                      </ToolTipContainer>
                    </ReactTooltip>
                  </TableHeaderCell>
                </tr>
              </thead>
              <tbody>
                {fetchCompanies.slice().map((company) => {
                  return (
                    <tr key={company.id}>
                      <TableLogoCell>
                        <StyledLogo
                          onClick={() => {
                            // returnIfNormalFund(props.fund_id)
                            setPortfolioInfoId(company.id);
                            togglePortfolioModal();
                            trackPortfolioClicked(company.name);
                          }}
                          src={company.logo_url}
                        />
                      </TableLogoCell>
                      <TableCell>
                        <div data-tip data-for={`moic_${company.id}`}>
                          {company.gross_moic}x{company.show_initial_moic ? ` (${company.initial_moic}x)` : ``}
                        </div>
                        <ReactTooltip
                          disable={!company.show_initial_moic}
                          place="top"
                          multiline
                          id={`moic_${company.id}`}
                          effect="solid"
                          // delayHide={1000}
                        >
                          <ToolTipContainer style={{ width: 150 }}>
                            <span>
                              <strong>{company.gross_moic}</strong> is the gross MOIC while{" "}
                              <strong>{company.initial_moic}</strong>
                              &nbsp;represents the multiple on our initial investment only.
                            </span>
                          </ToolTipContainer>
                        </ReactTooltip>
                      </TableCell>
                      {/* {returnIfNormalFund(props.fund_id) && ( */}
                      <TableCell>
                        {company.funding_rounds
                          .filter((fr) => !!fr.base10_invest)
                          .sort(stageOrder)
                          .map((round) => {
                            return (
                              <TableCellItem key={`${company.id}${round.stage}${round.total_investment}`}>
                                {round.stage}
                              </TableCellItem>
                            );
                          })}
                        {/* {returnIfNormalFund(props.fund_id) ? (
                          company.funding_rounds
                            .filter((fr) => !!fr.base10_invest)
                            .sort(stageOrder)
                            .map((round) => {
                              return (
                                <TableCellItem key={`${company.id}${round.stage}${round.total_investment}`}>
                                  {round.stage}
                                </TableCellItem>
                              );
                            })
                        ) : (
                          <TableCellItem>
                            {!!company.growth_category &&
                              `${company.growth_category}`.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")}
                          </TableCellItem>
                        )} */}
                      </TableCell>
                      <TableCell>
                        {company.funding_rounds
                          .filter((fr) => !!fr.base10_invest)
                          .sort(stageOrder)
                          .map((round: FundingRounds, index) => {
                            return (
                              <TableCellItem key={`${company.id}${round.stage}${round.total_investment}`}>
                                <div data-tip data-for={`total_invesment_${company.id}`}>
                                  ${round.base10_invest}
                                </div>
                                <ReactTooltip
                                  id={`total_invesment_${company.id}`}
                                  effect="solid"
                                  // delayHide={1000}
                                >
                                  <ToolTipContainer>Total: ${company.base10_total_investment}</ToolTipContainer>
                                </ReactTooltip>
                              </TableCellItem>
                            );
                          })}
                      </TableCell>
                      {returnIfNormalFund(props.fund_id) && (
                        <TableCell>
                          {company.funding_rounds
                            .filter((fr) => !!fr.base10_invest)
                            .sort(stageOrder)
                            .map((round) => {
                              return (
                                <TableCellItem key={`${company.id}${round.stage}${round.total_investment}`}>
                                  {Number(round?.ownership)?.toFixed(1)}%
                                </TableCellItem>
                              );
                            })}
                        </TableCell>
                      )}
                      <TableCell>
                        {company.funding_rounds
                          .filter((fr) => !!fr.base10_invest)
                          .sort(stageOrder)
                          .map((round) => {
                            return (
                              <TableCellItem key={`${company.id}${round.stage}${round.total_investment}`}>
                                {formatDate(round.funding_date)}
                              </TableCellItem>
                            );
                          })}
                      </TableCell>

                      <TableCell>
                        <StyledCircle>
                          <StyledInnerCircle>${company.base10_fmv}</StyledInnerCircle>
                        </StyledCircle>
                      </TableCell>

                      {returnIfNormalFund(props.fund_id) && <TableCell>{company.role}</TableCell>}
                      {/* {!selectedFundIsBase10PartnersFundIIOrIII && (
                        <TableCell>
                          {company.funding_rounds.map((round: FundingRounds) => {
                            const coinvestors = round.coinvestors.map((coinv: Coinvestor, index) => {
                              return (
                                <TableCellItem
                                  style={
                                    returnIfNormalFund(props.fund_id)
                                      ? {}
                                      : {
                                          display: "inline-block",
                                          alignItems: "center",
                                          marginTop: "0px",
                                          margin: "auto 0px",
                                        }
                                  }
                                  key={coinv.name}
                                  data-tip
                                  data-for={`coinvester_${coinv.name}`}
                                >
                                  <TableCellLogo
                                    style={{
                                      maxWidth: returnIfNormalFund(props.fund_id) ? "80px" : "60px",
                                      margin: returnIfNormalFund(props.fund_id) ? "0px" : "5px",
                                    }}
                                    src={coinv.url}
                                  />
                                  <ReactTooltip
                                    id={`coinvester_${coinv.name}`}
                                    effect="solid"
                                    // delayHide={1000}
                                  >
                                    <ToolTipContainer>{coinv.name}</ToolTipContainer>
                                  </ReactTooltip>
                                </TableCellItem>
                              );
                            });
                            return coinvestors;
                          })}
                        </TableCell>
                      )} */}
                      <TableCell>{company.industry_string}</TableCell>
                      {/* <TableCell>{company.trend_string}</TableCell> */}
                      {/* {show_videos && (
                        <TableCell>
                          {!!company.founder_video_url && (
                            <VideoThumbnailContainer>
                              <VideoThumbnailOverlay
                                onClick={() => {
                                  toggleVideoModal();
                                  setVideoLink(company.founder_video_url);
                                }}
                              />
                              <VideoThumbnailImage src={company.founder_photo_url} />
                            </VideoThumbnailContainer>
                          )}
                        </TableCell>
                      )} */}
                      <TableCell>{company.headquarters}</TableCell>
                      <TableCell>
                        <TableCellItem key={`${company.id}${company.governance_maturity_index}`}>
                          {company.governance_maturity_index ? `${company.governance_maturity_index.toFixed(1)}` : "-"}
                        </TableCellItem>
                      </TableCell>
                    </tr>
                  );
                })}
              </tbody>
            </TableContainer>
          </TableDiv>
          <PanelFooter>
            {!showFootNoteForAdvancmentInitiativeII ? portfolio_footnote : advancementInitiativeIIFootNote}
          </PanelFooter>
        </PanelContent>
      </PanelWrapper>
    </PanelContainer>
  );
};

const TableDiv = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

const TableHeaderCell = styled.th`
  display: table-cell;
  vertical-align: middle;
  padding: 15px 10px;
  text-align: center;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  padding: 20px 10px;
  font-size: 14px;
  color: ${BLACK_COLOR};
  @media ${mobileSize} {
    padding: 5px;
  }
`;
const TableCellItem = styled.div`
  display: block;
  white-space: nowrap;
`;

const TableCellLogo = styled.img`
  display: block;
  border-style: none;
  max-width: 80px;
  max-height: 40px;
  margin-right: auto;
  margin-left: auto;
`;

const TableCell = styled.td`
  display: table-cell;
  vertical-align: middle;
  padding: 15px 10px;
  text-align: center;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  color: ${BLACK_COLOR};
  font-weight: 300;
  font-size: 14px;
  min-width: 80px;
  @media ${mobileSize} {
    padding: 5px;
  }
  ${TableCellItem}+${TableCellItem} {
    margin-top: 8px;
  }
  ${TableCellLogo}+${TableCellLogo} {
    margin-top: 8px;
  }
`;

const TableLogoCell = styled.th`
  display: table-cell;
  vertical-align: middle;
  padding: 15px 10px;
  padding-right: 60px;
  text-align: center;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  color: rgba(26, 26, 26, 0.8);
  @media ${mobileSize} {
    padding-right: 20px;
  }
`;

const TableContainer = styled.table`
  font-family: ${PRIMARY_FONT};
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  ${TableCell}:first-child {
    padding-left: 0 !important;
  }
  ${TableLogoCell}:first-child {
    padding-left: 0 !important;
  }
  ${TableCell}:last-child {
    padding-right: 0 !important;
  }
  ${TableHeaderCell}:first-child {
    padding-left: 0 !important;
  }
  ${TableHeaderCell}:last-child {
    padding-right: 0 !important;
  }
`;

const StyledLogo = styled.img`
  border-style: none;
  cursor: pointer;
  max-width: 100px;
  max-height: 60px;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  @media ${mobileSize} {
    max-width: 80px;
    max-height: 50px;
  }
  :hover {
    opacity: 0.6;
  }
`;

const StyledCircle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${PRIMARY_COLOR};
`;

const StyledInnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
`;

const PanelDescription = styled(AppFormTitle)`
  font-family: ${PRIMARY_FONT};
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.4);
  letter-spacing: 0;
`;

const VideoThumbnailContainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 70px;
`;

const VideoThumbnailImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  border-style: none;
  :hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

const VideoThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
  :hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border: 3px solid rgba(26, 26, 26, 0.6);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: border-color 0.25s;
    transition: border-color 0.25s;
    height: 40px;
    width: 40px;
    border-width: 2px;
    :hover {
      border-color: ${BLACK_COLOR};
    }
  }
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent transparent transparent rgba(26, 26, 26, 0.6);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: border-left-color 0.25s;
    transition: border-left-color 0.25s;
    border-width: 9px 0 9px 18px;
    margin-left: 2px;
    :hover {
      border-left-color: #1a1a1a;
    }
  }
`;

const PortfolioComponent = observer(PortfolioPanel);

export { PortfolioComponent };
