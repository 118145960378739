import React, { SFC, useState } from "react";
import styled from "styled-components";
import { Formik, FormikProps, FieldArray, FormikConsumer } from "formik";
import {
  Coinvestors,
  Competitors,
  KeyMembers,
  RelatedNews,
  RelatedVideos,
  CompanyIndustry,
  CompanyTrend,
} from "../../../types/dataTypes";
import { AppText, AppButton, AppFormTitle, AnimatedText, ProfileImage, Loading, AppErrorText } from "../../UI";
import { PRIMARY_COLOR, PRIMARY_FONT } from "../../../utils/theme";
import * as Yup from "yup";
import { FormInputField, FormAreaInputField, FormSelectField, CheckboxField, RadioButtonField } from "../../Forms";
import { TrashIcon } from "../../Dumb";
import { ImageUploadField } from "../../Forms/ImageUploadField";
import { useQuery } from "../../../models/reactUtils";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { RoundModal, EditRoundModal } from "../../Modals";
import { formatDate } from "../../../utils/format";
import { CompanyModelType } from "../../../models";

interface PortFolioFormProps {
  data: CompanyModelType;
}

interface MyFormikProps {
  name: string;
  gross_moic: number;
  initial_moic: number;
  show_initial_moic: boolean;
  base10_fmv: number;
  role: string;
  about: string;
  founded_date: number;
  invested_date: number;
  website: string;
  logo_url: string;
  bw_logo_url: string;
  headquarters: string;
  founder_name: string;
  founder_position: string;
  founder_photo_url: string;
  founder_quote: string;
  founder_video_url: string;
  coinvestors: Coinvestors[];
  competitors: Competitors[];
  key_team_members: KeyMembers[];
  related_videos: RelatedVideos[];
  related_news: RelatedNews[];
  industry: CompanyIndustry[];
  trend: CompanyTrend[];
  primary_trend_id: string;
  primary_industry_id: string;
  governance_maturity_index: number;
}

const PortfolioFormSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  founded: Yup.number().integer("* Must be a whole number").notRequired(),
  invested: Yup.number().integer("* Must be a whole number").notRequired(),
  trend: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
    }),
  ),
  primary_trend_id: Yup.number().when("trend", {
    is: (trend) => !!trend.length,
    then: Yup.number().integer().required("You must choose a primary trend"),
  }),
  industry: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
    }),
  ),
  primary_industry_id: Yup.number().when("trend", {
    is: (industry) => !!industry.length,
    then: Yup.number().integer().required("You must choose a primary industry"),
  }),
});

const PortfolioFormComponent: SFC<PortFolioFormProps> = ({ data: { id } }) => {
  const [roundModal, setRoundModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editRoundFundId, setEditRoundFundId] = useState(0);
  const toggleRoundModal = () => setRoundModal(!roundModal);
  const toggleEditRoundModal = () => setEditModal(!editModal);
  const { setQuery, loading, error, store } = useQuery();

  // Industry options
  const { data: industryData, loading: industryLoading, error: industryError } = useQuery((store) =>
    store.queryFetchIndustries({ fundId: store.selected_fund.id }, (qb) => qb.id.name),
  );

  // Trend options
  const { data: trendData, loading: trendLoading, error: trendError } = useQuery((store) =>
    store.queryFetchTrends({ fundId: store.selected_fund.id }, (qb) => qb.id.name),
  );

  const { data: companyData, loading: companyLoading, error: companyError } = useQuery((store) =>
    store.queryCompany({ where: { id } }, (qb) =>
      qb.id.name.about.color.initial_moic.primary_industry_id.primary_trend_id.show_initial_moic.founded_date.founder_name.founder_photo_url.founder_position.founder_quote.founder_video_url.gross_moic.headquarters.invested_date.logo_url.bw_logo_url.num_funding_rounds.ordinal.website.base10_fmv.governance_maturity_index.role
        .related_videos((b) => b.id.title.url.image_url)
        .related_news((b) => b.id.title.url)
        .key_team_members((k) => k.id.name.url.role)
        .funding_rounds(
          (b) =>
            b.id.stage.base10_invest.funding_date.round_number.coinvestors((co) => co.id.name.url).company_id.footnote
              .month_year.ownership.total_investment.valuation,
        )
        .competitors((c) => c.id.name)
        .coinvestors((c) => c.id.name)
        .trend((b) => b.id.name)
        .industry((b) => b.id.name),
    ),
  );

  const deleteCompany = () => {
    const mutation = store.mutateDeleteOneCompany({
      where: { id },
    });
    mutation.then(
      (v) => {
        toast("Company Deleted!");
        store.localRemoveCompany(id);
      },
      (e) => {
        toast("Could not delete company!");
      },
    );
    setQuery(mutation);
  };

  // Need to grab industry options - should already have
  // Need to grab trend options

  if (industryLoading || companyLoading || trendLoading) {
    return <Loading />;
  }
  if (industryError || companyError || trendError) {
    return <p>Error fetching industry</p>;
  }
  const industry_options = industryData.fetchIndustries.map((s) => ({
    label: s.name,
    value: s.id,
  }));
  const trend_options = trendData.fetchTrends.map((s) => ({
    label: s.name,
    value: s.id,
  }));
  const current_company = store.companies.filter((c) => c.id === id)[0];
  const funding_rounds =
    !!current_company && current_company.funding_rounds
      ? current_company.funding_rounds.sort((a, b) => a.round_number - b.round_number)
      : [];

  const {
    name,
    gross_moic,
    base10_fmv,
    initial_moic,
    show_initial_moic,
    logo_url,
    bw_logo_url,
    coinvestors,
    about,
    role,
    founded_date,
    invested_date,
    website,
    headquarters,
    founder_name,
    founder_photo_url,
    founder_quote,
    founder_video_url,
    founder_position,
    competitors,
    key_team_members,
    related_videos,
    related_news,
    trend,
    industry,
    primary_industry_id: pi,
    primary_trend_id: pt,
    governance_maturity_index,
  } = companyData.company;
  const primary_industry_id = industry.reduce((a, b, i) => {
    if (b.id === pi) {
      return a + i;
    }
    return a;
  }, 0);
  const primary_trend_id = trend.reduce((a, b, i) => {
    if (b.id === pt) {
      return a + i;
    }
    return a;
  }, 0);
  return (
    <PortfolioFormContainer>
      <RoundModal id={id} toggleModal={toggleRoundModal} showModal={roundModal} />
      <EditRoundModal id={id} round_id={editRoundFundId} toggleModal={toggleEditRoundModal} showModal={editModal} />
      <Formik
        initialValues={{
          name,
          logo_url,
          bw_logo_url,
          governance_maturity_index,
          gross_moic,
          initial_moic,
          show_initial_moic,
          base10_fmv,
          role,
          about,
          founded_date,
          invested_date,
          website: website,
          headquarters,
          founder_name,
          founder_photo_url,
          founder_quote,
          founder_video_url,
          founder_position,
          primary_industry_id: `${primary_industry_id}`,
          primary_trend_id: `${primary_trend_id}`,
          coinvestors: coinvestors.map(({ __typename, ...c }) => c),
          competitors: competitors.map(({ __typename, ...c }) => c),
          key_team_members: key_team_members.map(({ __typename, ...c }) => c),
          related_videos: related_videos.map(({ __typename, ...c }) => c),
          related_news: related_news.map(({ __typename, ...c }) => c),
          industry: industry.map((i) => ({
            id: i.id,
            primary: i.id === primary_industry_id,
          })),
          trend: trend.map((i) => ({
            id: i.id,
            primary: i.id === primary_trend_id,
          })),
        }}
        validationSchema={PortfolioFormSchema}
        onSubmit={async ({
          coinvestors,
          competitors,
          key_team_members,
          related_news,
          related_videos,
          trend,
          industry,
          primary_industry_id: pi,
          primary_trend_id: pt,
          governance_maturity_index,
          ...company
        }) => {
          // @ts-ignore
          const npi = !!industry.length ? industry[parseInt(pi)].id : 0;
          // @ts-ignore
          const npt = !!trend.length ? trend[parseInt(pt)].id : 0;
          const mutation = store.mutateUpdateCompany({
            children: {
              coinvestors: coinvestors.filter((c) => !!c.name),
              competitors: competitors.filter((c) => !!c.name),
              key_team_members: key_team_members.filter((c) => !!c.name),
              related_new: related_news.filter((c) => !!c.url),
              related_videos: related_videos.filter((c) => !!c.url),
              industries: industry
                .map((i) => i.id)
                // @ts-ignore
                .filter((i) => i !== "0")
                // @ts-ignore
                .map((i) => parseInt(i)),
              trends: trend
                .map((i) => i.id)
                // @ts-ignore
                .filter((i) => i !== "0")
                // @ts-ignore
                .map((i) => parseInt(i)),
              company: {
                ...company,
                // @ts-ignore
                primary_industry_id: parseInt(npi),
                // @ts-ignore
                primary_trend_id: parseInt(npt),
                id,
                governance_maturity_index,
              },
            },
          });
          mutation.then(
            (v) => {
              toast("Company Updated!");
              store.localUpdateCompany({
                id: v.updateCompany.id,
                name: v.updateCompany.name,
                ordinal: v.updateCompany.ordinal,
                industry_string: v.updateCompany.industry_string,
              });
            },
            (e) => {
              toast("Could not update Company!");
            },
          );
          setQuery(mutation);
        }}
      >
        {({ submitForm, values, errors }: FormikProps<MyFormikProps>) => {
          return (
            <FormContainer>
              <CompanySection>
                <FormInputField
                  name={"name"}
                  title="NAME"
                  placeholder="Name"
                  style={{
                    marginRight: 30,
                  }}
                />
                <FormInputField
                  name={"gross_moic"}
                  title="GROSS MOIC"
                  placeholder="0.0"
                  type="number"
                  style={{ marginRight: 30 }}
                />
                <FormInputField
                  width={160}
                  title="BASE10 FMV. ($M)"
                  name={`base10_fmv`}
                  placeholder="0.0"
                  noTopMargin
                  type="number"
                />
              </CompanySection>
              <CompanySection>
                <FormInputField
                  title="INITIAL MOIC"
                  name={`initial_moic`}
                  placeholder="Initial MOIC"
                  type="number"
                  width={260}
                  style={{ marginRight: 30 }}
                />
                <CheckboxField name="show_initial_moic" title="SHOW INITIAL MOIC" style={{ marginRight: 40 }} />
              </CompanySection>
              <CompanySection style={{ marginTop: 10 }}>
                <FormInputField
                  width={260}
                  title="INVESTMENT ROLE"
                  name={`role`}
                  placeholder="Base10 Role"
                  noTopMargin
                />
                <ImageUploadField width={160} name="bw_logo_url" title="B&W COMPANY LOGO" />
                <ImageUploadField width={160} name="logo_url" title="COMPANY LOGO" />
              </CompanySection>
              <RoundSection>
                <HeaderRow>
                  <RoundTitleText style={{ gridColumn: "1 / span 1" }}>ROUND #</RoundTitleText>
                  <RoundTitleText style={{ gridColumn: "2 / span 1" }}>STAGE</RoundTitleText>
                  <RoundTitleText style={{ gridColumn: "3 / span 1" }}>TOTAL (BASE10)</RoundTitleText>
                  <RoundTitleText style={{ gridColumn: "4 / span 1" }}>DATE</RoundTitleText>
                  <RoundTitleText style={{ gridColumn: "5 / span 1", justifySelf: "right" }}></RoundTitleText>
                </HeaderRow>
                {funding_rounds.map((round, i) => {
                  return (
                    <HeaderRow key={round.id} style={{ height: 55, alignItems: "center" }}>
                      <RoundNumberText style={{ gridColumn: " 1 / span 1" }}>
                        {`#${round.round_number || 0}`}
                      </RoundNumberText>
                      <RoundRegularText style={{ gridColumn: " 2 / span 1" }}>{round.stage}</RoundRegularText>
                      <RoundRegularText style={{ gridColumn: " 3 / span 1" }}>
                        {`${round.total_investment || 0}(${round.base10_invest || 0})`}
                      </RoundRegularText>
                      <RoundRegularText style={{ gridColumn: " 4 / span 1" }}>
                        {round.funding_date ? formatDate(round.funding_date) : "-"}
                      </RoundRegularText>
                      <AnimatedText
                        style={{
                          gridColumn: "5 / span 1",
                          justifySelf: "right",
                          alignSelf: "center",
                          height: "fit-content",
                        }}
                        onClick={() => {
                          setEditRoundFundId(round.id);
                          toggleEditRoundModal();
                        }}
                      >
                        Edit Round
                      </AnimatedText>
                    </HeaderRow>
                  );
                })}
                <AddRoundButton secondary onClick={toggleRoundModal}>
                  + Add round
                </AddRoundButton>
              </RoundSection>
              <AboutSection>
                <AboutGrid>
                  <FormAreaInputField
                    style={{
                      gridColumn: "1 / span 4",
                      gridRow: "1 / span 1",
                    }}
                    name={"about"}
                    title="ABOUT"
                    fontSize={20}
                  />
                  <FormInputField
                    style={{
                      gridColumn: "1 / span 1",
                      gridRow: "2 / span 1",
                      marginRight: 30,
                    }}
                    name={"founded_date"}
                    title="FOUNDED"
                    fontSize={20}
                    type="number"
                  />
                  <FormInputField
                    style={{
                      gridColumn: "2 / span 1",
                      gridRow: "2 / span 1",
                      marginRight: 30,
                    }}
                    name={"invested_date"}
                    title="INVESTED"
                    type="number"
                    fontSize={20}
                  />
                  <FormInputField
                    style={{
                      gridColumn: "3 / span 2",
                      gridRow: "2 / span 1",
                    }}
                    name={"website"}
                    title="WEBSITE"
                    fontSize={20}
                  />
                  <FormInputField
                    style={{
                      gridColumn: "1 / span 2",
                      gridRow: "3 / span 1",
                      marginRight: 30,
                    }}
                    name={"headquarters"}
                    title="HEADQUARTERS"
                    fontSize={20}
                  />
                  <FormInputField
                    style={{
                      gridColumn: "1 / span 2",
                      gridRow: "4 / span 1",
                      marginRight: 30,
                    }}
                    name={"founder_name"}
                    title="FOUNDER'S NAME"
                    fontSize={20}
                  />
                  <InputTitle
                    style={{
                      gridColumn: "3 / span 1",
                      gridRow: "4 / span 1",
                    }}
                  >
                    {`FOUNDER'S PHOTO`}
                  </InputTitle>
                  <ImageUploadField
                    style={{
                      gridColumn: "3 / span 1",
                      gridRow: "5 / span 1",
                      fontFamily: `${PRIMARY_FONT}`,
                      alignSelf: "flex-end",
                    }}
                    name="founder_photo_url"
                    width={165}
                  />
                  <FormikConsumer>
                    {({ values }: { values: MyFormikProps }) => {
                      return (
                        <div
                          style={{
                            gridColumn: "4 / span 1",
                            gridRow: "4 / span 2",
                            height: "90%",
                            width: "100%",
                          }}
                        >
                          <ProfileImage src={values.founder_photo_url} />
                        </div>
                      );
                    }}
                  </FormikConsumer>

                  <FormInputField
                    style={{
                      gridColumn: "1 / span 2",
                      gridRow: "5 / span 1",
                      marginRight: 30,
                    }}
                    name={"founder_position"}
                    title="FOUNDER'S POSITION"
                    fontSize={20}
                  />
                  <FormAreaInputField
                    style={{
                      gridColumn: "1 / span 4",
                      gridRow: "6 / span 1",
                    }}
                    name={"founder_quote"}
                    title="FOUNDER'S QUOTE"
                    fontSize={20}
                  />
                  <FormInputField
                    style={{
                      gridColumn: "1 / span 4",
                      gridRow: "7 / span 1",
                    }}
                    name={"founder_video_url"}
                    placeholder="http://..."
                    title="FOUNDER'S VIDEO"
                    fontSize={20}
                  />
                </AboutGrid>
              </AboutSection>
              <InputTitle>CO-INVESTORS</InputTitle>
              <FieldArray name="coinvestors">
                {({ insert, remove, name }) => (
                  <FieldArrayContainer>
                    {values.coinvestors.map((co, i) => {
                      return (
                        <Split key={co.id}>
                          <FormInputField
                            width={550}
                            name={`coinvestors[${i}].name`}
                            fontSize={20}
                            noTopMargin
                            placeholder="Co-Investor Name"
                          />
                          <TrashIcon onClick={() => remove(i)} />
                        </Split>
                      );
                    })}
                    <AddTextContainer>
                      <AnimatedText
                        onClick={() =>
                          insert(values.coinvestors.length, {
                            name: "",
                            id: 0,
                          })
                        }
                      >
                        Add co-investor
                      </AnimatedText>
                    </AddTextContainer>
                  </FieldArrayContainer>
                )}
              </FieldArray>

              <InputTitle>COMPETITORS</InputTitle>
              <FieldArray name="competitors">
                {({ insert, remove, name }) => (
                  <FieldArrayContainer>
                    {values.competitors.map((comp, i) => {
                      return (
                        <Split key={comp.id}>
                          <FormInputField
                            width={550}
                            name={`competitors[${i}].name`}
                            fontSize={20}
                            placeholder="Competitor Name"
                            noTopMargin
                          />
                          <TrashIcon onClick={() => remove(i)} />
                        </Split>
                      );
                    })}
                    <AddTextContainer>
                      <AnimatedText
                        onClick={() =>
                          insert(values.competitors.length, {
                            name: "",
                            id: 0,
                          })
                        }
                      >
                        Add competitor
                      </AnimatedText>
                    </AddTextContainer>
                  </FieldArrayContainer>
                )}
              </FieldArray>

              <InputTitle>KEY TEAM MEMBERS</InputTitle>
              <FieldArray name="key_team_members">
                {({ insert, remove, name }) => (
                  <FieldArrayContainer>
                    {values.key_team_members.map((ktm, i) => {
                      return (
                        <Split key={ktm.id}>
                          <MembersContainer>
                            <Split>
                              <FormInputField
                                width={240}
                                name={`key_team_members[${i}].name`}
                                fontSize={20}
                                placeholder="Member Name"
                                noTopMargin
                              />
                              <FormInputField
                                width={240}
                                name={`key_team_members[${i}].role`}
                                fontSize={20}
                                placeholder="Member Position"
                                noTopMargin
                              />
                            </Split>
                            <FormInputField
                              name={`key_team_members[${i}].url`}
                              fontSize={20}
                              placeholder="Linkedin Profile link"
                              noTopMargin
                            />
                          </MembersContainer>
                          <TrashIcon onClick={() => remove(i)} />
                        </Split>
                      );
                    })}
                    <AddTextContainer>
                      <AnimatedText
                        onClick={() => {
                          insert(values.key_team_members.length, {
                            name: "",
                            role: "",
                            url: "",
                            id: 0,
                          });
                        }}
                      >
                        Add member
                      </AnimatedText>
                    </AddTextContainer>
                  </FieldArrayContainer>
                )}
              </FieldArray>
              <InputTitle>RELATED VIDEOS</InputTitle>
              <FieldArray name="related_videos">
                {({ insert, remove, name }) => (
                  <FieldArrayContainer>
                    {values.related_videos.map((rv, i) => {
                      return (
                        <Split key={rv.id}>
                          <MembersContainer>
                            <Split>
                              <FormInputField
                                width={240}
                                name={`related_videos[${i}].url`}
                                fontSize={20}
                                placeholder="Video link"
                                noTopMargin
                              />
                              <FormInputField
                                width={240}
                                name={`related_videos[${i}].title`}
                                fontSize={20}
                                placeholder="Video title"
                                noTopMargin
                              />
                            </Split>
                            <ImageUploadField
                              uploadButtonText="Upload Thumbnail image"
                              replaceButtonText="Replace Thumbnail image"
                              name={`related_videos[${i}].image_url`}
                            />
                          </MembersContainer>
                          <TrashIcon onClick={() => remove(i)} />
                        </Split>
                      );
                    })}
                    <AddTextContainer>
                      <AnimatedText
                        onClick={() => {
                          insert(values.related_videos.length, {
                            url: "",
                            title: "",
                            id: 0,
                          });
                        }}
                      >
                        Add Video
                      </AnimatedText>
                    </AddTextContainer>
                  </FieldArrayContainer>
                )}
              </FieldArray>
              <InputTitle>RELATED NEWS</InputTitle>
              <FieldArray name="related_news">
                {({ insert, remove, name }) => (
                  <FieldArrayContainer>
                    {values.related_news.map((rn, i) => {
                      return (
                        <Split key={rn.id}>
                          <MembersContainer>
                            <Split>
                              <FormInputField
                                width={240}
                                name={`related_news[${i}].url`}
                                fontSize={20}
                                placeholder="Article link"
                                noTopMargin
                              />
                              <FormInputField
                                width={240}
                                name={`related_news[${i}].title`}
                                fontSize={20}
                                placeholder="Article title"
                                noTopMargin
                              />
                            </Split>
                          </MembersContainer>
                          <TrashIcon onClick={() => remove(i)} />
                        </Split>
                      );
                    })}
                    <AddTextContainer>
                      <AnimatedText
                        onClick={() => {
                          insert(values.related_news.length, {
                            url: "",
                            title: "",
                            id: 0,
                          });
                        }}
                      >
                        Add News
                      </AnimatedText>
                    </AddTextContainer>
                  </FieldArrayContainer>
                )}
              </FieldArray>
              <InputTitle>INDUSTRY</InputTitle>
              <FieldArray name="industry">
                {({ insert, remove, name }) => {
                  return (
                    <FieldArrayContainer>
                      {values.industry.map((ind, i) => {
                        return (
                          <Split key={ind.id}>
                            <FormSelectField
                              width={340}
                              name={`industry[${i}].id`}
                              fontSize={20}
                              placeholder="Choose Industry"
                              options={industry_options}
                              type="number"
                              style={{ marginRight: 30 }}
                            />
                            <RadioButtonField title="PRIMARY" name="primary_industry_id" id={`${i}`} />
                            <TrashIcon onClick={() => remove(i)} />
                          </Split>
                        );
                      })}
                      <AddTextContainer>
                        <AnimatedText
                          onClick={() => {
                            insert(values.industry.length, {
                              id: "0",
                            });
                          }}
                        >
                          Add Industry
                        </AnimatedText>
                      </AddTextContainer>
                    </FieldArrayContainer>
                  );
                }}
              </FieldArray>
              <InputTitle>TREND</InputTitle>
              <FieldArray name="trend">
                {({ insert, remove, name }) => {
                  return (
                    <FieldArrayContainer>
                      {values.trend.map((t, i) => {
                        return (
                          <Split key={t.id}>
                            <FormSelectField
                              width={340}
                              name={`trend[${i}].id`}
                              fontSize={20}
                              placeholder="Choose Trend"
                              options={trend_options}
                              type="number"
                              style={{ marginRight: 30 }}
                            />
                            <RadioButtonField title="PRIMARY" name="primary_trend_id" id={`${i}`} />
                            <TrashIcon onClick={() => remove(i)} />
                          </Split>
                        );
                      })}
                      <AddTextContainer>
                        <AnimatedText
                          onClick={() => {
                            insert(values.trend.length, {
                              id: "0",
                            });
                          }}
                        >
                          Add Trend
                        </AnimatedText>
                      </AddTextContainer>
                    </FieldArrayContainer>
                  );
                }}
              </FieldArray>

              <InputTitle>Governance Maturity Index</InputTitle>
              <FormInputField
                name="governance_maturity_index"
                fontSize={20}
                placeholder="100%"
                max={100}
                min={0}
                type="number"
              />
              <SubmitContainer>
                {loading ? <Loading /> : <StyledAppButton onClick={submitForm}>Save Changes</StyledAppButton>}
                {error && <AppErrorText>{error}</AppErrorText>}
              </SubmitContainer>
              <DeleteContainer>
                {loading ? <Loading /> : <DeleteText onClick={deleteCompany}>Delete Company</DeleteText>}
              </DeleteContainer>
            </FormContainer>
          );
        }}
      </Formik>
    </PortfolioFormContainer>
  );
};

const PortfolioFormContainer = styled.div`
  width: calc(100%-100px);
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CompanySection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RoundSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const RoundTitleText = styled(AppText)`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 100px 160px 160px 160px auto;
  width: 100%;
  font-weight: bold;
`;

//** Round Row Item */

const RoundNumberText = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${PRIMARY_COLOR};
`;

const RoundRegularText = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const AddRoundButton = styled(AppButton)`
  width: 100%;
  height: 70px;
  margin-top: 20px;
`;

//** About Section */

const AboutSection = styled.div`
  margin-top: 5px;
  margin-bottom: 30px;
`;

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: 185px 185px 185px 185px;
  grid-template-rows: 180px 90px 90px 90px 90px 180px 90px;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

//** Field Array sections */

const FieldArrayContainer = styled.div`
  width: 100%;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  justify-content: center;
`;

const DeleteText = styled(AppText)`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  opacity: 0.2;
  :hover {
    cursor: pointer;
  }
`;

const InputTitle = styled(AppFormTitle)`
  margin: 0;
  margin-bottom: 5px;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
`;

const PortfolioForm = observer(PortfolioFormComponent);

export { PortfolioForm };
