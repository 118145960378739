import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  CloseModal,
  ModalWrapper,
  AppTitle,
  AppButton,
  AppFormTitle,
  AnimatedText,
  ProfileImage,
  Loading,
} from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { PRIMARY_FONT } from "../../utils/theme";
import { FieldArray, FormikValues, FormikConsumer } from "formik";
import * as Yup from "yup";
import {
  FormInputField,
  FormSelectField,
  FormAreaInputField,
  FormDatePicker,
  ImageUploadField,
  CheckboxField,
  RadioButtonField,
} from "../Forms";
import { TrashIcon } from "../Dumb";
import {
  Round,
  Coinvestors,
  KeyMembers,
  RelatedNews,
  RelatedVideos,
  CompanyIndustry,
  CompanyTrend,
} from "../../types/dataTypes";
import { MultiStepFormContainer } from "../Smart";
import { observer } from "mobx-react";
import { useQuery } from "../../models/reactUtils";
import { toast } from "react-toastify";

interface AddCompanyModalProps {
  showModal: boolean;
  toggleModal: () => void;
  fund_id: number;
  onFinish?: () => void;
}

interface MyFormikProps {
  name: string;
  role: string;
  gross_moic: number;
  initial_moic: number;
  show_initial_moic: boolean;
  base10_fmv: number;
  about: string;
  founded: number;
  invested: number;
  website: string;
  headquarters: string;
  founders_name: string;
  founders_position: string;
  logo_url: string;
  bw_logo_url: string;
  founders_photo: string;
  founders_quote: string;
  founders_video: string;
  coinvestors: Coinvestors[];
  company_coinvestors: Coinvestors[];
  company_competitors: Coinvestors[];
  key_team_members: KeyMembers[];
  related_videos: RelatedVideos[];
  related_news: RelatedNews[];
  industry: CompanyIndustry[];
  trend: CompanyTrend[];
  primary_industry_id: number;
  primary_trend_id: number;
  rounds: Round[];
  governance_maturity_index: number;
}

interface FormikChildrenProps {
  onSubmit: () => void;
  validationSchema: Record<string, unknown>;
}

const FirstPageFormSchema = Yup.object().shape({
  name: Yup.string().required("* Field is required"),
  founded: Yup.number().integer("* Must be a whole number").notRequired(),
  invested: Yup.number().integer("* Must be a whole number").notRequired(),
  trend: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
    }),
  ),
  primary_trend_id: Yup.number().when("trend", {
    is: (trend) => !!trend.length,
    then: Yup.number().integer().required("You must choose a primary trend"),
  }),
  industry: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
    }),
  ),
  primary_industry_id: Yup.number().when("trend", {
    is: (industry) => !!industry.length,
    then: Yup.number().integer().required("You must choose a primary industry"),
  }),
});

const SecondPageFormSchema = Yup.object().shape({
  rounds: Yup.array().of(
    Yup.object().shape({
      stage: Yup.string().required("* Field is required"),
      round_number: Yup.number()
        .integer("* Please choose a whole number")
        .required("* Please select a round number")
        .min(0, "* Please choose a positive integer")
        .notOneOf(["0"], "* Please choose a positive integer"),
    }),
  ),
});

const FormPage = ({
  children,
  onSubmit,
  validationSchema,
}: {
  // children: FormikChildrenProps;
  children: any & FormikChildrenProps & FormikValues;
  onSubmit: any;
  validationSchema: any;
}) => children;

const AddCompanyModalComponent = (props: AddCompanyModalProps) => {
  const { setQuery, loading, error, store } = useQuery();
  console.log("fund_id: ", store.selected_fund.id);

  const {
    data: industryData,
    loading: industryLoading,
    error: industryError,
    setQuery: industrySetQuery,
  } = useQuery((store) => store.queryFetchIndustries({ fundId: store.selected_fund.id }, (qb) => qb.id.name));

  const { data: trendData, loading: trendLoading, error: trendError, setQuery: trendSetQuery } = useQuery((store) =>
    store.queryFetchTrends({ fundId: store.selected_fund.id }, (qb) => qb.id.name),
  );
  const selectedFund = useQuery().store.selected_fund;

  const selectedFundIsBase10PartnersFundIIOrIII = selectedFund?.id === 13 || selectedFund?.id === 19;

  useEffect(() => {
    console.log("fund_id change... refetching: ", props.fund_id);
    const query = store.queryFetchIndustries({ fundId: store.selected_fund.id }, (qb) => qb.id.name);
    industrySetQuery(query);
    const query2 = store.queryFetchTrends({ fundId: store.selected_fund.id }, (qb) => qb.id.name);
    trendSetQuery(query2);
  }, [props.fund_id]);

  const { data: stageData, loading: stageLoading, error: stageError } = useQuery((store) =>
    store.queryStages({}, (qb) => qb.id.name),
  );

  if (!props.showModal) {
    return null;
  }
  if (industryLoading || stageLoading || trendLoading) {
    return <Loading />;
  }
  if (industryError || stageError || trendError) {
    return <p>Error fetching sectors for company</p>;
  }
  console.log("industryData: ", industryData.fetchIndustries);
  console.log("fund_id: ", store.selected_fund.id);

  const industry_options = industryData.fetchIndustries.map((s) => ({
    label: s.name,
    value: s.id,
  }));

  const trend_options = trendData.fetchTrends.map((s) => ({
    label: s.name,
    value: s.id,
  }));

  return (
    <Modal width="fit-content" showModal={props.showModal} toggleModal={props.toggleModal}>
      <CloseModal onClick={props.toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <AddCompanyContainer>
        <ModalWrapper>
          <MultiStepFormContainer
            initialValues={{
              name: "",
              role: "",
              logo_url: "",
              bw_logo_url: "",
              gross_moic: undefined,
              initial_moic: undefined,
              show_initial_moic: true,
              base10_fmv: undefined,
              rounds: [],
              coinvestors: [],
              company_coinvestors: [],
              company_competitors: [],
              about: "",
              founded: undefined,
              invested: undefined,
              website: "",
              headquarters: "",
              real_economy_sector: 0,
              primary_industry_id: undefined,
              primary_trend_id: undefined,
              founders_name: "",
              founders_photo: "",
              founders_quote: "",
              founders_video: "",
              founders_position: "",
              key_team_members: [],
              related_videos: [],
              related_news: [],
              trend: [],
              industry: [],
              governance_maturity_index: undefined,
            }}
            onSubmit={async ({
              rounds,
              trend,
              industry,
              primary_industry_id: pi,
              primary_trend_id: pt,
              governance_maturity_index,
              ...first_round_values
            }: MyFormikProps) => {
              console.log("First page submission values: ", first_round_values);
              console.log("trend: ", trend);
              console.log("industry: ", industry);
              console.log("primary_industry_id: ", pi);
              console.log("primary_trend_id: ", pt);
              // @ts-ignore
              const npi = !!industry.length ? industry[parseInt(pi)].id : 0;
              // @ts-ignore
              const npt = !!trend.length ? trend[parseInt(pt)].id : 0;
              const mutation = store.mutateCreateOneCompany(
                {
                  data: {
                    founded_date: first_round_values.founded,
                    founder_name: first_round_values.founders_name,
                    role: first_round_values.role,
                    founder_photo_url: first_round_values.founders_photo,
                    about: first_round_values.about,
                    founder_position: first_round_values.founders_position,
                    founder_quote: first_round_values.founders_quote,
                    founder_video_url: first_round_values.founders_video,
                    gross_moic: first_round_values.gross_moic,
                    initial_moic: first_round_values.initial_moic,
                    show_initial_moic: first_round_values.show_initial_moic,
                    base10_fmv: first_round_values.base10_fmv,
                    headquarters: first_round_values.headquarters,
                    invested_date: first_round_values.invested,
                    name: first_round_values.name,
                    logo_url: first_round_values.logo_url,
                    bw_logo_url: first_round_values.bw_logo_url,
                    website: first_round_values.website,
                    // @ts-ignore
                    primary_industry_id: parseInt(npi),
                    // @ts-ignore
                    primary_trend_id: parseInt(npt),
                    governance_maturity_index: governance_maturity_index,
                    coinvestors: {
                      create: first_round_values.company_coinvestors.filter((c) => !!c.name),
                    },
                    competitors: {
                      create: first_round_values.company_competitors.filter((c) => !!c.name),
                    },
                    key_team_members: {
                      create: first_round_values.key_team_members
                        .filter((c) => !!c.name)
                        .map((c) => ({
                          name: c.name,
                          role: c.role,
                          url: c.url,
                        })),
                    },
                    related_videos: {
                      create: first_round_values.related_videos
                        .filter((c) => !!c.url)
                        .map((c) => ({
                          url: c.url,
                          title: c.title,
                          image_url: c.image_url,
                        })),
                    },
                    related_news: {
                      create: first_round_values.related_news
                        .filter((c) => !!c.url)
                        .map((c) => ({ url: c.url, title: c.title })),
                    },
                    industry: {
                      connect: industry
                        // @ts-ignore
                        .filter((i) => !!i.id)
                        // @ts-ignore
                        .map((i) => ({ id: parseInt(i.id) })),
                    },
                    trend: {
                      connect: trend
                        // @ts-ignore
                        .filter((i) => !!i.id)
                        // @ts-ignore
                        .map((i) => ({ id: parseInt(i.id) })),
                    },
                    ordinal: store.company_max_ordinal + 1,
                    fund: { connect: { id: store.selected_fund.id } },
                    funding_rounds: {
                      create: rounds.map((r) => ({
                        stage: r.stage,
                        round_number: r.round_number,
                        funding_date: r.date,
                        total_investment: r.total_investment,
                        base10_invest: r.base_10_investment,
                        ownership: r.ownership,
                        footnote: r.footnote,
                        valuation: r.valuation,
                        coinvestors: {
                          create: r.coinvestors.filter((c) => !!c.name).map((c) => ({ name: c.name, url: c.url })),
                        },
                      })),
                    },
                  },
                },
                (qb) =>
                  qb.about.base10_fmv.base10_total_investment.bw_logo_url.color.company_total_investment.company_total_investment.founded_date.industry_string.name.ordinal.logo_url.num_funding_rounds.funding_rounds(
                    (b) =>
                      b.base10_invest.base10_involved.coinvestors((co) => co.id.name.url).footnote.funding_date.id
                        .month_year.ownership.round_number.stage.total_investment.valuation,
                  ),
              );
              mutation.then(
                (v) => {
                  if (!!props.onFinish) {
                    props.onFinish();
                    toast("Company added!");
                    props.toggleModal();
                    return;
                  }
                  /**
                   * check if company already exists in local storage
                   * if it does, remove it
                   * if it doesn't, add it
                   */
                  const company = store.companies.find((c) => c.id === v.createOneCompany.id);

                  if (company) {
                    store.localRemoveCompany(v.createOneCompany.id);
                  }

                  store.localAddCompany({
                    id: v.createOneCompany.id,
                    industry_string: v.createOneCompany.industry_string,
                    name: v.createOneCompany.name,
                    ordinal: v.createOneCompany.ordinal,
                    logo_url: v.createOneCompany.logo_url,
                    num_funding_rounds: v.createOneCompany.num_funding_rounds,
                    funding_rounds: v.createOneCompany.funding_rounds,
                  });
                  toast("Company added!");
                  props.toggleModal();
                },
                (e) => {
                  toast("Could not add sector!");
                },
              );
              setQuery(mutation);
            }}
            loading={loading}
            error={error}
          >
            <FormPage
              onSubmit={({ trend, industry }: MyFormikProps) => {
                console.log("First Page Submissions");
                // if (
                //   trend.length &&
                //   trend.filter((t) => t.primary).length !== 1
                // ) {
                //   toast("Please choose exactly one primary trend", {
                //     style: { backgroundColor: "red" },
                //   });
                //   return;
                // }
                // if (
                //   industry.length &&
                //   industry.filter((t) => t.primary).length !== 1
                // ) {
                //   toast("Please choose exactly one primary industry", {
                //     style: { backgroundColor: "red" },
                //   });
                //   return;
                // }
              }}
              validationSchema={FirstPageFormSchema}
            >
              <FormContainer>
                <TitleContainer>
                  <Title>Add Company</Title>
                </TitleContainer>
                <CompanySection>
                  <FormInputField
                    name={"name"}
                    title="NAME"
                    placeholder="Company Name"
                    style={{
                      marginRight: 30,
                    }}
                  />
                  <FormInputField
                    name={"gross_moic"}
                    title="GROSS MOIC"
                    placeholder="0.0"
                    style={{ marginRight: 30 }}
                    type="number"
                  />
                  <FormInputField
                    style={{}}
                    width={160}
                    title="BASE10 FMV. ($M)"
                    name={`base10_fmv`}
                    placeholder="0.0"
                    noTopMargin
                    type="number"
                  />
                </CompanySection>
                <CompanySection>
                  <FormInputField
                    width={260}
                    title="INITIAL MOIC"
                    name={`initial_moic`}
                    placeholder="Initial MOIC"
                    type="number"
                    style={{ marginRight: 30 }}
                  />
                  <CheckboxField name="show_initial_moic" title="SHOW INITIAL MOIC" style={{ marginRight: 40 }} />
                </CompanySection>
                <CompanySection style={{ marginTop: 10 }}>
                  <FormInputField
                    width={260}
                    title="INVESTMENT ROLE"
                    name={`role`}
                    placeholder="Base10 Role"
                    noTopMargin
                  />
                  <ImageUploadField width={160} name="bw_logo_url" title="B&W COMPANY LOGO" />
                  <ImageUploadField width={160} name="logo_url" title="COMPANY LOGO" />
                </CompanySection>
                <AboutSection>
                  <AboutGrid>
                    <FormAreaInputField
                      style={{
                        gridColumn: "1 / span 4",
                        gridRow: "1 / span 1",
                      }}
                      name={"about"}
                      placeholder="About the company..."
                      title="ABOUT"
                      fontSize={20}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "2 / span 1",
                        marginRight: 30,
                      }}
                      name={"founded"}
                      placeholder="Funding Year"
                      title="FOUNDED"
                      fontSize={20}
                      type="number"
                    />
                    <FormInputField
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "2 / span 1",
                        marginRight: 30,
                      }}
                      name={"invested"}
                      placeholder="Invested Year"
                      title="INVESTED"
                      fontSize={20}
                      type="number"
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 2",
                        gridRow: "2 / span 1",
                      }}
                      name={"website"}
                      placeholder="http://"
                      title="WEBSITE"
                      fontSize={20}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "3 / span 1",
                        marginRight: 30,
                      }}
                      name={"headquarters"}
                      placeholder="City"
                      title="HEADQUARTERS"
                      fontSize={20}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "4 / span 1",
                        marginRight: 30,
                      }}
                      name={"founders_name"}
                      placeholder="Full Name"
                      title="FOUNDER'S NAME"
                      fontSize={20}
                    />
                    <InputTitle
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "4 / span 1",
                      }}
                    >
                      {`FOUNDER'S PHOTO`}
                    </InputTitle>
                    <ImageUploadField
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "5 / span 1",
                        fontFamily: `${PRIMARY_FONT}`,
                        alignSelf: "flex-end",
                      }}
                      name="founders_photo"
                      width={165}
                    />
                    <FormikConsumer>
                      {({ values }: { values: MyFormikProps }) => {
                        console.log("values: ", values);
                        return (
                          <div
                            style={{
                              gridColumn: "4 / span 1",
                              gridRow: "4 / span 2",
                              height: "90%",
                              width: "100%",
                            }}
                          >
                            <ProfileImage src={values.founders_photo} />
                          </div>
                        );
                      }}
                    </FormikConsumer>
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "5 / span 1",
                        marginRight: 30,
                      }}
                      name={"founders_position"}
                      placeholder="Job Position"
                      title="FOUNDER'S POSITION"
                      fontSize={20}
                    />
                    <FormAreaInputField
                      style={{
                        gridColumn: "1 / span 4",
                        gridRow: "6 / span 1",
                      }}
                      name={"founders_quote"}
                      placeholder="Description..."
                      title="FOUNDER'S QUOTE"
                      fontSize={20}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 4",
                        gridRow: "7 / span 1",
                      }}
                      name={"founders_video"}
                      placeholder="YouTube or Vimeo link..."
                      title="FOUNDER'S VIDEO"
                      fontSize={20}
                    />

                    <FormInputField
                      name="governance_maturity_index"
                      fontSize={20}
                      placeholder="100%"
                      style={{
                        gridColumn: "1 / span 4",
                        gridRow: "8 / span 1",
                      }}
                      title="GOVERNANCE MATURITY INDEX"
                      type="number"
                    />
                  </AboutGrid>
                </AboutSection>

                <FormikConsumer>
                  {({ values, ...rest }: { values: MyFormikProps }) => {
                    return (
                      <div>
                        <div>
                          <InputTitle>CO-INVESTORS</InputTitle>
                          <FieldArray name="company_coinvestors">
                            {({ insert, remove, name }) => (
                              <FieldArrayContainer>
                                {values.company_coinvestors.map((co, i) => {
                                  return (
                                    <Split key={co.id}>
                                      <MembersContainer>
                                        <Split>
                                          <FormInputField
                                            width={550}
                                            name={`company_coinvestors[${i}].name`}
                                            fontSize={20}
                                            placeholder="Co-Investor Name"
                                            noTopMargin
                                          />
                                        </Split>
                                      </MembersContainer>
                                      <TrashIcon onClick={() => remove(i)} />
                                    </Split>
                                  );
                                })}
                                <AddTextContainer>
                                  <AnimatedText
                                    onClick={() => {
                                      insert(values.company_coinvestors.length, {
                                        name: "",
                                      });
                                    }}
                                  >
                                    Add co-investor
                                  </AnimatedText>
                                </AddTextContainer>
                              </FieldArrayContainer>
                            )}
                          </FieldArray>
                        </div>

                        <InputTitle>COMPETITOR</InputTitle>
                        <FieldArray name="company_competitors">
                          {({ insert, remove, name }) => (
                            <FieldArrayContainer>
                              {values.company_competitors.map((co_co, i) => {
                                return (
                                  <Split key={co_co.id}>
                                    <MembersContainer>
                                      <Split>
                                        <FormInputField
                                          width={550}
                                          name={`company_competitors[${i}].name`}
                                          fontSize={20}
                                          placeholder="Competitor Name"
                                          noTopMargin
                                        />
                                      </Split>
                                    </MembersContainer>
                                    <TrashIcon onClick={() => remove(i)} />
                                  </Split>
                                );
                              })}
                              <AddTextContainer>
                                <AnimatedText
                                  onClick={() => {
                                    insert(values.company_competitors.length, {
                                      name: "",
                                    });
                                  }}
                                >
                                  Add competitor
                                </AnimatedText>
                              </AddTextContainer>
                            </FieldArrayContainer>
                          )}
                        </FieldArray>

                        <InputTitle>KEY TEAM MEMBERS</InputTitle>
                        <FieldArray name="key_team_members">
                          {({ insert, remove, name }) => (
                            <FieldArrayContainer>
                              {values.key_team_members.map((ktm, i) => {
                                return (
                                  <Split key={ktm.id}>
                                    <MembersContainer>
                                      <Split>
                                        <FormInputField
                                          width={240}
                                          name={`key_team_members[${i}].name`}
                                          fontSize={20}
                                          placeholder="Member Name"
                                          noTopMargin
                                        />
                                        <FormInputField
                                          width={240}
                                          name={`key_team_members[${i}].role`}
                                          fontSize={20}
                                          placeholder="Member Position"
                                          noTopMargin
                                        />
                                      </Split>
                                      <FormInputField
                                        name={`key_team_members[${i}].url`}
                                        fontSize={20}
                                        placeholder="Linkedin Profile url"
                                        noTopMargin
                                      />
                                    </MembersContainer>
                                    <TrashIcon onClick={() => remove(i)} />
                                  </Split>
                                );
                              })}
                              <AddTextContainer>
                                <AnimatedText
                                  onClick={() => {
                                    insert(values.key_team_members.length, {
                                      name: "",
                                      role: "",
                                      url: "",
                                    });
                                  }}
                                >
                                  Add member
                                </AnimatedText>
                              </AddTextContainer>
                            </FieldArrayContainer>
                          )}
                        </FieldArray>
                        <InputTitle>RELATED VIDEOS</InputTitle>
                        <FieldArray name="related_videos">
                          {({ insert, remove, name }) => (
                            <FieldArrayContainer>
                              {values.related_videos.map((rv, i) => {
                                return (
                                  <Split key={rv.id}>
                                    <MembersContainer>
                                      <Split>
                                        <FormInputField
                                          width={240}
                                          name={`related_videos[${i}].url`}
                                          fontSize={20}
                                          placeholder="Video url"
                                          noTopMargin
                                        />
                                        <FormInputField
                                          width={240}
                                          name={`related_videos[${i}].title`}
                                          fontSize={20}
                                          placeholder="Video title"
                                          noTopMargin
                                        />
                                      </Split>
                                      <ImageUploadField
                                        uploadButtonText="Upload Thumbnail image"
                                        replaceButtonText="Replace Thumbnail image"
                                        name={`related_videos[${i}].image_url`}
                                      />
                                    </MembersContainer>
                                    <TrashIcon onClick={() => remove(i)} />
                                  </Split>
                                );
                              })}
                              <AddTextContainer>
                                <AnimatedText
                                  onClick={() => {
                                    insert(values.related_videos.length, {
                                      url: "",
                                      title: "",
                                      image_url: "",
                                    });
                                  }}
                                >
                                  Add video
                                </AnimatedText>
                              </AddTextContainer>
                            </FieldArrayContainer>
                          )}
                        </FieldArray>
                        <InputTitle>RELATED NEWS</InputTitle>
                        <FieldArray name="related_news">
                          {({ insert, remove, name }) => (
                            <FieldArrayContainer>
                              {values.related_news.map((rn, i) => {
                                return (
                                  <Split key={rn.id}>
                                    <MembersContainer>
                                      <Split>
                                        <FormInputField
                                          width={240}
                                          name={`related_news[${i}].url`}
                                          fontSize={20}
                                          placeholder="Article url"
                                          noTopMargin
                                        />
                                        <FormInputField
                                          width={240}
                                          name={`related_news[${i}].title`}
                                          fontSize={20}
                                          placeholder="Article title"
                                          noTopMargin
                                        />
                                      </Split>
                                    </MembersContainer>
                                    <TrashIcon onClick={() => remove(i)} />
                                  </Split>
                                );
                              })}
                              <AddTextContainer>
                                <AnimatedText
                                  onClick={() => {
                                    insert(values.related_news.length, {
                                      url: "",
                                      title: "",
                                    });
                                  }}
                                >
                                  Add News
                                </AnimatedText>
                              </AddTextContainer>
                            </FieldArrayContainer>
                          )}
                        </FieldArray>
                        <InputTitle>INDUSTRY</InputTitle>
                        <FieldArray name="industry">
                          {({ insert, remove, name }) => {
                            return (
                              <FieldArrayContainer>
                                {values.industry.map((ind, i) => {
                                  return (
                                    <Split key={ind.id}>
                                      <FormSelectField
                                        width={340}
                                        name={`industry[${i}].id`}
                                        fontSize={20}
                                        placeholder="Choose Industry"
                                        options={industry_options}
                                        type="number"
                                      />
                                      <RadioButtonField title="PRIMARY" name="primary_industry_id" id={`${i}`} />
                                      <TrashIcon onClick={() => remove(i)} />
                                    </Split>
                                  );
                                })}
                                <AddTextContainer>
                                  <AnimatedText
                                    onClick={() => {
                                      insert(values.industry.length, {
                                        id: "0",
                                      });
                                    }}
                                  >
                                    Add Industry
                                  </AnimatedText>
                                </AddTextContainer>
                              </FieldArrayContainer>
                            );
                          }}
                        </FieldArray>
                        <InputTitle>TREND</InputTitle>
                        <FieldArray name="trend">
                          {({ insert, remove, name }) => {
                            return (
                              <FieldArrayContainer>
                                {values.trend.map((t, i) => {
                                  return (
                                    <Split key={t.id}>
                                      <FormSelectField
                                        width={340}
                                        name={`trend[${i}].id`}
                                        fontSize={20}
                                        placeholder="Choose Trend"
                                        options={trend_options}
                                        type="number"
                                      />
                                      <RadioButtonField title="PRIMARY" name="primary_trend_id" id={`${i}`} />
                                      <TrashIcon onClick={() => remove(i)} />
                                    </Split>
                                  );
                                })}
                                <AddTextContainer>
                                  <AnimatedText
                                    onClick={() => {
                                      insert(values.trend.length, {
                                        id: "0",
                                      });
                                    }}
                                  >
                                    Add Trend
                                  </AnimatedText>
                                </AddTextContainer>
                              </FieldArrayContainer>
                            );
                          }}
                        </FieldArray>
                      </div>
                    );
                  }}
                </FormikConsumer>
              </FormContainer>
            </FormPage>
            <FormPage
              onSubmit={(values: MyFormikProps) => {
                console.log("Second Page Submissions", values);
              }}
              validationSchema={SecondPageFormSchema}
            >
              <FormikConsumer>
                {({ values, ...rest }: { values: MyFormikProps }) => (
                  <div>
                    <FormContainer>
                      <TitleContainer>
                        <Title>
                          Add rounds
                          <SecondaryTitleText>{` ${values.name}`}</SecondaryTitleText>
                        </Title>
                      </TitleContainer>
                      <FieldArray name="rounds">
                        {({ insert, remove, name }) => {
                          if (stageLoading) {
                            return <Loading />;
                          }
                          if (stageError) {
                            return <p>Error fetching sectors for company</p>;
                          }
                          const stage_list = stageData.stages.map((s) => ({
                            label: s.name,
                            value: s.name,
                          }));
                          return (
                            <FieldArrayContainer>
                              {values.rounds.map((round, i) => {
                                return (
                                  <div key={round.id}>
                                    <Split>
                                      <FormSelectField
                                        name={`rounds[${i}].stage`}
                                        title="STAGE"
                                        placeholder="Choose stage"
                                        width={330}
                                        fontSize={20}
                                        options={stage_list}
                                      />
                                      <FormInputField
                                        width={170}
                                        title="TOTAL INVEST. ($M)"
                                        name={`rounds[${i}].total_investment`}
                                        fontSize={20}
                                        placeholder="0.0"
                                        type="number"
                                        noTopMargin
                                      />
                                      <FormInputField
                                        width={170}
                                        title="BASE10 INVEST. ($M)"
                                        name={`rounds[${i}].base_10_investment`}
                                        fontSize={20}
                                        placeholder="0.0"
                                        noTopMargin
                                        type="number"
                                      />
                                    </Split>
                                    <Split
                                      style={{
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <FormDatePicker name={`rounds[${i}].date`} title="FUNDING DATE" width={210} />
                                      <FormInputField
                                        width={80}
                                        title="ROUND #"
                                        name={`rounds[${i}].round_number`}
                                        fontSize={20}
                                        style={{ marginRight: 30 }}
                                        placeholder="0"
                                        noTopMargin
                                        type="number"
                                      />
                                      <Split>
                                        <FormInputField
                                          style={{
                                            gridColumn: "1 / span 4",
                                            gridRow: "3 / span 1",
                                            marginRight: 30,
                                          }}
                                          width={160}
                                          title="OWNERSHIP (%)"
                                          name={`rounds[${i}].ownership`}
                                          value={values?.rounds[i]?.ownership?.toFixed(1)}
                                          step={0.1}
                                          fontSize={20}
                                          placeholder="0"
                                          noTopMargin
                                          type="number"
                                        />
                                        <FormInputField
                                          style={{
                                            gridColumn: "2 / span 1",
                                            gridRow: "1 / span 1",
                                            margin: 0,
                                          }}
                                          width={170}
                                          title="VALUATION ($M)"
                                          name={`rounds[${i}].valuation`}
                                          fontSize={20}
                                          placeholder="0.0"
                                          type="number"
                                          noTopMargin
                                        />
                                      </Split>
                                    </Split>
                                    <InputTitle>CO-INVESTORS</InputTitle>
                                    <FieldArray name={`rounds[${i}].coinvestors`}>
                                      {({ insert, remove, name }) => (
                                        <FieldArrayContainer>
                                          {values.rounds[i].coinvestors.map((coinvestor, index) => {
                                            return (
                                              <Split key={coinvestor.id}>
                                                <SplitContainer>
                                                  <Split>
                                                    <ImageUploadField
                                                      name={`rounds[${i}].coinvestors[${index}].url`}
                                                      style={{
                                                        marginRight: 30,
                                                      }}
                                                    />
                                                    <FormInputField
                                                      width={330}
                                                      name={`rounds[${i}].coinvestors[${index}].name`}
                                                      fontSize={20}
                                                      placeholder="Co-Investor Name"
                                                      noTopMargin
                                                    />
                                                  </Split>
                                                </SplitContainer>
                                                <TrashIcon onClick={() => remove(index)} />
                                              </Split>
                                            );
                                          })}
                                          <AddTextContainer>
                                            <AnimatedText
                                              onClick={() =>
                                                insert(values.rounds[i].coinvestors.length, {
                                                  name: "",
                                                  url: "",
                                                })
                                              }
                                            >
                                              Add co-investor
                                            </AnimatedText>
                                          </AddTextContainer>
                                        </FieldArrayContainer>
                                      )}
                                    </FieldArray>
                                    <FormAreaInputField
                                      name={`rounds[${i}].footnote`}
                                      title="FOOTNOTE"
                                      fontSize={20}
                                      placeholder="Description"
                                    />
                                  </div>
                                );
                              })}
                              <SubmitContainer>
                                <AddroundButton
                                  secondary
                                  onClick={() =>
                                    insert(values.rounds.length, {
                                      round_number: undefined,
                                      stage: "0",
                                      total_investment: undefined,
                                      base_10_investment: undefined,
                                      date: new Date(),
                                      ownership: undefined,
                                      valuation: undefined,
                                      coinvestors: [{ name: "", url: "" }],
                                      footnote: "",
                                    })
                                  }
                                >
                                  + Add another round
                                </AddroundButton>
                              </SubmitContainer>
                            </FieldArrayContainer>
                          );
                        }}
                      </FieldArray>
                    </FormContainer>
                  </div>
                )}
              </FormikConsumer>
            </FormPage>
          </MultiStepFormContainer>
        </ModalWrapper>
      </AddCompanyContainer>
    </Modal>
  );
};

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CompanySection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

//** About Section */

const AboutSection = styled.div`
  margin-top: 5px;
  margin-bottom: 30px;
`;

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: 185px 185px 185px 185px;
  grid-template-rows: 180px 90px 90px 90px 90px 180px 90px;
`;

const AddroundButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SecondaryTitleText = styled.span`
  color: rgba(26, 26, 26, 0.4);
`;

const InputTitle = styled(AppFormTitle)`
  margin: 0;
  margin-bottom: 5px;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
`;

const AddCompanyContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
`;

const FieldArrayContainer = styled.div`
  width: 100%;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
`;

const AddCompanyModal = observer(AddCompanyModalComponent);

export { AddCompanyModal };
