/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from "mst-gql"

import { FundModel, FundModelType } from "./FundModel"
import { fundModelPrimitives, FundModelSelector } from "./FundModel.base"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { companyModelPrimitives, CompanyModelSelector } from "./CompanyModel.base"
import { FundingRoundModel, FundingRoundModelType } from "./FundingRoundModel"
import { fundingRoundModelPrimitives, FundingRoundModelSelector } from "./FundingRoundModel.base"
import { FundingRoundCoinvestorModel, FundingRoundCoinvestorModelType } from "./FundingRoundCoinvestorModel"
import { fundingRoundCoinvestorModelPrimitives, FundingRoundCoinvestorModelSelector } from "./FundingRoundCoinvestorModel.base"
import { IndustryModel, IndustryModelType } from "./IndustryModel"
import { industryModelPrimitives, IndustryModelSelector } from "./IndustryModel.base"
import { FinalDilligenceCompanyModel, FinalDilligenceCompanyModelType } from "./FinalDilligenceCompanyModel"
import { finalDilligenceCompanyModelPrimitives, FinalDilligenceCompanyModelSelector } from "./FinalDilligenceCompanyModel.base"
import { TrendModel, TrendModelType } from "./TrendModel"
import { trendModelPrimitives, TrendModelSelector } from "./TrendModel.base"
import { CoinvestorModel, CoinvestorModelType } from "./CoinvestorModel"
import { coinvestorModelPrimitives, CoinvestorModelSelector } from "./CoinvestorModel.base"
import { CompetitorModel, CompetitorModelType } from "./CompetitorModel"
import { competitorModelPrimitives, CompetitorModelSelector } from "./CompetitorModel.base"
import { CompanyMemberModel, CompanyMemberModelType } from "./CompanyMemberModel"
import { companyMemberModelPrimitives, CompanyMemberModelSelector } from "./CompanyMemberModel.base"
import { CompanyVideoModel, CompanyVideoModelType } from "./CompanyVideoModel"
import { companyVideoModelPrimitives, CompanyVideoModelSelector } from "./CompanyVideoModel.base"
import { CompanyNewModel, CompanyNewModelType } from "./CompanyNewModel"
import { companyNewModelPrimitives, CompanyNewModelSelector } from "./CompanyNewModel.base"
import { DataRoomTopicModel, DataRoomTopicModelType } from "./DataRoomTopicModel"
import { dataRoomTopicModelPrimitives, DataRoomTopicModelSelector } from "./DataRoomTopicModel.base"
import { DataRoomItemModel, DataRoomItemModelType } from "./DataRoomItemModel"
import { dataRoomItemModelPrimitives, DataRoomItemModelSelector } from "./DataRoomItemModel.base"
import { StageModel, StageModelType } from "./StageModel"
import { stageModelPrimitives, StageModelSelector } from "./StageModel.base"
import { UserModel, UserModelType } from "./UserModel"
import { userModelPrimitives, UserModelSelector } from "./UserModel.base"
import { UserSessionModel, UserSessionModelType } from "./UserSessionModel"
import { userSessionModelPrimitives, UserSessionModelSelector } from "./UserSessionModel.base"
import { RealEconomySectorModel, RealEconomySectorModelType } from "./RealEconomySectorModel"
import { realEconomySectorModelPrimitives, RealEconomySectorModelSelector } from "./RealEconomySectorModel.base"
import { MarketingEmailModel, MarketingEmailModelType } from "./MarketingEmailModel"
import { marketingEmailModelPrimitives, MarketingEmailModelSelector } from "./MarketingEmailModel.base"
import { CapitalDeployedByIndustryModel, CapitalDeployedByIndustryModelType } from "./CapitalDeployedByIndustryModel"
import { capitalDeployedByIndustryModelPrimitives, CapitalDeployedByIndustryModelSelector } from "./CapitalDeployedByIndustryModel.base"
import { CapitalDeployedByIndustryItemModel, CapitalDeployedByIndustryItemModelType } from "./CapitalDeployedByIndustryItemModel"
import { capitalDeployedByIndustryItemModelPrimitives, CapitalDeployedByIndustryItemModelSelector } from "./CapitalDeployedByIndustryItemModel.base"
import { FundraisingAndValuationHistoryGraphModel, FundraisingAndValuationHistoryGraphModelType } from "./FundraisingAndValuationHistoryGraphModel"
import { fundraisingAndValuationHistoryGraphModelPrimitives, FundraisingAndValuationHistoryGraphModelSelector } from "./FundraisingAndValuationHistoryGraphModel.base"
import { FundraisingAndValuationHistoryGraphRoundModel, FundraisingAndValuationHistoryGraphRoundModelType } from "./FundraisingAndValuationHistoryGraphRoundModel"
import { fundraisingAndValuationHistoryGraphRoundModelPrimitives, FundraisingAndValuationHistoryGraphRoundModelSelector } from "./FundraisingAndValuationHistoryGraphRoundModel.base"
import { CapitalDeployedPerQuarterModel, CapitalDeployedPerQuarterModelType } from "./CapitalDeployedPerQuarterModel"
import { capitalDeployedPerQuarterModelPrimitives, CapitalDeployedPerQuarterModelSelector } from "./CapitalDeployedPerQuarterModel.base"
import { CapitalDeployedPerQuarterCompanyModel, CapitalDeployedPerQuarterCompanyModelType } from "./CapitalDeployedPerQuarterCompanyModel"
import { capitalDeployedPerQuarterCompanyModelPrimitives, CapitalDeployedPerQuarterCompanyModelSelector } from "./CapitalDeployedPerQuarterCompanyModel.base"

import { Growth } from "./GrowthEnum"
import { UrlType } from "./UrlTypeEnum"
import { Role } from "./RoleEnum"
import { QueryMode } from "./QueryModeEnum"
import { SortOrder } from "./SortOrderEnum"

export type FundWhereUniqueInput = {
  id?: number
}
export type CompanyWhereUniqueInput = {
  id?: number
}
export type FundingRoundWhereUniqueInput = {
  id?: number
}
export type FundingRoundCoinvestorWhereUniqueInput = {
  id?: number
}
export type IndustryWhereUniqueInput = {
  id?: number
}
export type FinalDilligenceCompanyWhereUniqueInput = {
  id?: number
}
export type TrendWhereUniqueInput = {
  id?: number
}
export type CoinvestorWhereUniqueInput = {
  id?: number
}
export type CompetitorWhereUniqueInput = {
  id?: number
}
export type CompanyMemberWhereUniqueInput = {
  id?: number
}
export type CompanyVideoWhereUniqueInput = {
  id?: number
}
export type CompanyNewWhereUniqueInput = {
  id?: number
}
export type DataRoomTopicWhereUniqueInput = {
  id?: number
}
export type DataRoomItemWhereUniqueInput = {
  id?: number
}
export type StageWhereUniqueInput = {
  id?: number
}
export type QueryFundsWhereInput = {
  id?: IntFilter
}
export type IntFilter = {
  equals?: number
  in?: number[]
  notIn?: number[]
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  not?: NestedIntFilter
}
export type NestedIntFilter = {
  equals?: number
  in?: number[]
  notIn?: number[]
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  not?: NestedIntFilter
}
export type UserWhereUniqueInput = {
  id?: number
  email?: string
  username?: string
}
export type UserSessionWhereUniqueInput = {
  id?: number
}
export type QueryUsersWhereInput = {
  email?: StringFilter
  username?: StringFilter
  role?: EnumRoleFilter
}
export type StringFilter = {
  equals?: string
  in?: string[]
  notIn?: string[]
  lt?: string
  lte?: string
  gt?: string
  gte?: string
  contains?: string
  startsWith?: string
  endsWith?: string
  mode?: QueryMode
  not?: NestedStringFilter
}
export type NestedStringFilter = {
  equals?: string
  in?: string[]
  notIn?: string[]
  lt?: string
  lte?: string
  gt?: string
  gte?: string
  contains?: string
  startsWith?: string
  endsWith?: string
  not?: NestedStringFilter
}
export type EnumRoleFilter = {
  equals?: Role
  in?: Role[]
  notIn?: Role[]
  not?: NestedEnumRoleFilter
}
export type NestedEnumRoleFilter = {
  equals?: Role
  in?: Role[]
  notIn?: Role[]
  not?: NestedEnumRoleFilter
}
export type QueryUsersOrderByInput = {
  created_date?: SortOrder
}
export type RealEconomySectorWhereUniqueInput = {
  id?: number
}
export type QueryDataRoomItemsWhereInput = {
  topic_id?: IntNullableFilter
}
export type IntNullableFilter = {
  equals?: number
  in?: number[]
  notIn?: number[]
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  not?: NestedIntNullableFilter
}
export type NestedIntNullableFilter = {
  equals?: number
  in?: number[]
  notIn?: number[]
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  not?: NestedIntNullableFilter
}
export type QueryDataRoomItemsOrderByInput = {
  ordinal?: SortOrder
}
export type DataRoomTopicWhereInput = {
  AND?: DataRoomTopicWhereInput[]
  OR?: DataRoomTopicWhereInput[]
  NOT?: DataRoomTopicWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  topic_name?: StringFilter
  items?: DataRoomItemListRelationFilter
  hbcu?: BoolFilter
  fund_id?: IntNullableFilter
  fund?: FundWhereInput
}
export type DataRoomItemListRelationFilter = {
  every?: DataRoomItemWhereInput
  some?: DataRoomItemWhereInput
  none?: DataRoomItemWhereInput
}
export type DataRoomItemWhereInput = {
  AND?: DataRoomItemWhereInput[]
  OR?: DataRoomItemWhereInput[]
  NOT?: DataRoomItemWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  label?: StringFilter
  url?: StringFilter
  type?: EnumUrlTypeFilter
  topic_id?: IntNullableFilter
  topic?: DataRoomTopicWhereInput
}
export type EnumUrlTypeFilter = {
  equals?: UrlType
  in?: UrlType[]
  notIn?: UrlType[]
  not?: NestedEnumUrlTypeFilter
}
export type NestedEnumUrlTypeFilter = {
  equals?: UrlType
  in?: UrlType[]
  notIn?: UrlType[]
  not?: NestedEnumUrlTypeFilter
}
export type BoolFilter = {
  equals?: boolean
  not?: NestedBoolFilter
}
export type NestedBoolFilter = {
  equals?: boolean
  not?: NestedBoolFilter
}
export type FundWhereInput = {
  AND?: FundWhereInput[]
  OR?: FundWhereInput[]
  NOT?: FundWhereInput[]
  id?: IntFilter
  name?: StringFilter
  companies?: CompanyListRelationFilter
  industries?: IndustryListRelationFilter
  trend?: TrendListRelationFilter
  capital_deployed_perc_deployed_and_reserved?: FloatNullableFilter
  capital_deployed_perc_unallocated?: FloatNullableFilter
  moic?: FloatNullableFilter
  tvpi?: FloatNullableFilter
  gross_moic_on_initial_checks?: FloatNullableFilter
  moic_excl_6_months?: FloatNullableFilter
  capital_deployed?: FloatNullableFilter
  capital_deployed_change?: FloatNullableFilter
  fmv?: FloatNullableFilter
  fmv_change?: FloatNullableFilter
  portfolio_companies?: FloatNullableFilter
  new_portfolio_companies?: FloatNullableFilter
  months_since_first_close?: FloatNullableFilter
  show_moic?: BoolNullableFilter
  show_tvpi?: BoolNullableFilter
  show_gross_moic_on_initial_checks?: BoolNullableFilter
  show_moic_excl_6_months?: BoolNullableFilter
  show_capital_deployed?: BoolNullableFilter
  show_capital_deployed_change?: BoolNullableFilter
  show_fmv?: BoolNullableFilter
  show_fmv_change?: BoolNullableFilter
  show_portfolio_companies?: BoolNullableFilter
  show_new_portfolio_companies?: BoolNullableFilter
  show_months_since_first_close?: BoolNullableFilter
  last_update?: DateTimeNullableFilter
  seed_primary?: FloatNullableFilter
  seed_follow_on?: FloatNullableFilter
  series_a_primary?: FloatNullableFilter
  series_a_follow_on?: FloatNullableFilter
  series_b_primary?: FloatNullableFilter
  series_b_follow_on?: FloatNullableFilter
  reserved_but_not_deployed?: FloatNullableFilter
  unallocated?: FloatNullableFilter
  graph_early_growth?: FloatNullableFilter
  graph_growth?: FloatNullableFilter
  graph_late_growth?: FloatNullableFilter
  total_investable_capital?: FloatNullableFilter
  captial_deployed_footnote?: StringNullableFilter
  fund_overview_footnote?: StringNullableFilter
  capital_deployed_by_stage_footnote?: StringNullableFilter
  capital_deployed_by_industry_footnote?: StringNullableFilter
  advancement_initiative_portfolio_revenue_growth_footnote?: StringNullableFilter
  portfolio_footnote?: StringNullableFilter
  fmv_contribution_by_company_footnote?: StringNullableFilter
  research_process_pipeline_footnote?: StringNullableFilter
  capital_deployed_by_quarter_footnote?: StringNullableFilter
  footnotes_and_disclosesures?: StringNullableFilter
  investment_by_stages?: StageListRelationFilter
  dataroom_topics?: DataRoomTopicListRelationFilter
}
export type CompanyListRelationFilter = {
  every?: CompanyWhereInput
  some?: CompanyWhereInput
  none?: CompanyWhereInput
}
export type CompanyWhereInput = {
  AND?: CompanyWhereInput[]
  OR?: CompanyWhereInput[]
  NOT?: CompanyWhereInput[]
  id?: IntFilter
  ordinal?: IntNullableFilter
  role?: StringNullableFilter
  fund_id?: IntNullableFilter
  fund?: FundWhereInput
  name?: StringNullableFilter
  growth_category?: EnumGrowthNullableFilter
  gross_moic?: FloatNullableFilter
  initial_moic?: FloatNullableFilter
  show_initial_moic?: BoolNullableFilter
  total_fmv?: FloatNullableFilter
  base10_fmv?: FloatNullableFilter
  logo_url?: StringNullableFilter
  bw_logo_url?: StringNullableFilter
  funding_rounds?: FundingRoundListRelationFilter
  about?: StringNullableFilter
  founded_date?: IntNullableFilter
  invested_date?: IntNullableFilter
  website?: StringNullableFilter
  headquarters?: StringNullableFilter
  founder_name?: StringNullableFilter
  founder_position?: StringNullableFilter
  founder_photo_url?: StringNullableFilter
  founder_quote?: StringNullableFilter
  founder_video_url?: StringNullableFilter
  governance_maturity_index?: FloatNullableFilter
  coinvestors?: CoinvestorListRelationFilter
  competitors?: CompetitorListRelationFilter
  key_team_members?: CompanyMemberListRelationFilter
  related_videos?: CompanyVideoListRelationFilter
  related_news?: CompanyNewListRelationFilter
  trend?: TrendListRelationFilter
  industry?: IndustryListRelationFilter
  primary_trend_id?: IntNullableFilter
  primary_industry_id?: IntNullableFilter
  show_ownership?: BoolNullableFilter
  show_valuation?: BoolNullableFilter
}
export type StringNullableFilter = {
  equals?: string
  in?: string[]
  notIn?: string[]
  lt?: string
  lte?: string
  gt?: string
  gte?: string
  contains?: string
  startsWith?: string
  endsWith?: string
  mode?: QueryMode
  not?: NestedStringNullableFilter
}
export type NestedStringNullableFilter = {
  equals?: string
  in?: string[]
  notIn?: string[]
  lt?: string
  lte?: string
  gt?: string
  gte?: string
  contains?: string
  startsWith?: string
  endsWith?: string
  not?: NestedStringNullableFilter
}
export type EnumGrowthNullableFilter = {
  equals?: Growth
  in?: Growth[]
  notIn?: Growth[]
  not?: NestedEnumGrowthNullableFilter
}
export type NestedEnumGrowthNullableFilter = {
  equals?: Growth
  in?: Growth[]
  notIn?: Growth[]
  not?: NestedEnumGrowthNullableFilter
}
export type FloatNullableFilter = {
  equals?: number
  in?: number[]
  notIn?: number[]
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  not?: NestedFloatNullableFilter
}
export type NestedFloatNullableFilter = {
  equals?: number
  in?: number[]
  notIn?: number[]
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  not?: NestedFloatNullableFilter
}
export type BoolNullableFilter = {
  equals?: boolean
  not?: NestedBoolNullableFilter
}
export type NestedBoolNullableFilter = {
  equals?: boolean
  not?: NestedBoolNullableFilter
}
export type FundingRoundListRelationFilter = {
  every?: FundingRoundWhereInput
  some?: FundingRoundWhereInput
  none?: FundingRoundWhereInput
}
export type FundingRoundWhereInput = {
  AND?: FundingRoundWhereInput[]
  OR?: FundingRoundWhereInput[]
  NOT?: FundingRoundWhereInput[]
  id?: IntFilter
  round_number?: IntNullableFilter
  stage?: StringNullableFilter
  funding_date?: DateTimeNullableFilter
  total_investment?: FloatNullableFilter
  base10_invest?: FloatNullableFilter
  valuation?: FloatNullableFilter
  ownership?: FloatNullableFilter
  coinvestors?: FundingRoundCoinvestorListRelationFilter
  footnote?: StringNullableFilter
  company?: CompanyWhereInput
  company_id?: IntNullableFilter
}
export type DateTimeNullableFilter = {
  equals?: any
  in?: any[]
  notIn?: any[]
  lt?: any
  lte?: any
  gt?: any
  gte?: any
  not?: NestedDateTimeNullableFilter
}
export type NestedDateTimeNullableFilter = {
  equals?: any
  in?: any[]
  notIn?: any[]
  lt?: any
  lte?: any
  gt?: any
  gte?: any
  not?: NestedDateTimeNullableFilter
}
export type FundingRoundCoinvestorListRelationFilter = {
  every?: FundingRoundCoinvestorWhereInput
  some?: FundingRoundCoinvestorWhereInput
  none?: FundingRoundCoinvestorWhereInput
}
export type FundingRoundCoinvestorWhereInput = {
  AND?: FundingRoundCoinvestorWhereInput[]
  OR?: FundingRoundCoinvestorWhereInput[]
  NOT?: FundingRoundCoinvestorWhereInput[]
  id?: IntFilter
  funding_round_id?: IntNullableFilter
  funding_round?: FundingRoundWhereInput
  name?: StringNullableFilter
  url?: StringNullableFilter
}
export type CoinvestorListRelationFilter = {
  every?: CoinvestorWhereInput
  some?: CoinvestorWhereInput
  none?: CoinvestorWhereInput
}
export type CoinvestorWhereInput = {
  AND?: CoinvestorWhereInput[]
  OR?: CoinvestorWhereInput[]
  NOT?: CoinvestorWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  company?: CompanyWhereInput
  name?: StringNullableFilter
}
export type CompetitorListRelationFilter = {
  every?: CompetitorWhereInput
  some?: CompetitorWhereInput
  none?: CompetitorWhereInput
}
export type CompetitorWhereInput = {
  AND?: CompetitorWhereInput[]
  OR?: CompetitorWhereInput[]
  NOT?: CompetitorWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  company?: CompanyWhereInput
  name?: StringFilter
}
export type CompanyMemberListRelationFilter = {
  every?: CompanyMemberWhereInput
  some?: CompanyMemberWhereInput
  none?: CompanyMemberWhereInput
}
export type CompanyMemberWhereInput = {
  AND?: CompanyMemberWhereInput[]
  OR?: CompanyMemberWhereInput[]
  NOT?: CompanyMemberWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  company?: CompanyWhereInput
  name?: StringFilter
  role?: StringNullableFilter
  url?: StringNullableFilter
}
export type CompanyVideoListRelationFilter = {
  every?: CompanyVideoWhereInput
  some?: CompanyVideoWhereInput
  none?: CompanyVideoWhereInput
}
export type CompanyVideoWhereInput = {
  AND?: CompanyVideoWhereInput[]
  OR?: CompanyVideoWhereInput[]
  NOT?: CompanyVideoWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  company?: CompanyWhereInput
  title?: StringNullableFilter
  url?: StringFilter
  image_url?: StringNullableFilter
}
export type CompanyNewListRelationFilter = {
  every?: CompanyNewWhereInput
  some?: CompanyNewWhereInput
  none?: CompanyNewWhereInput
}
export type CompanyNewWhereInput = {
  AND?: CompanyNewWhereInput[]
  OR?: CompanyNewWhereInput[]
  NOT?: CompanyNewWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  company?: CompanyWhereInput
  title?: StringFilter
  url?: StringNullableFilter
}
export type TrendListRelationFilter = {
  every?: TrendWhereInput
  some?: TrendWhereInput
  none?: TrendWhereInput
}
export type TrendWhereInput = {
  AND?: TrendWhereInput[]
  OR?: TrendWhereInput[]
  NOT?: TrendWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  fund_id?: IntFilter
  fund?: FundWhereInput
  name?: StringNullableFilter
  icon?: StringNullableFilter
  companies_identified_count?: IntNullableFilter
  initial_calls_count?: IntNullableFilter
  secondary_dilligence_count?: IntNullableFilter
  final_dilligence_count?: IntNullableFilter
  final_dilligence_companies?: FinalDilligenceCompanyListRelationFilter
  portfolio_companies?: CompanyListRelationFilter
  selectable?: BoolNullableFilter
}
export type FinalDilligenceCompanyListRelationFilter = {
  every?: FinalDilligenceCompanyWhereInput
  some?: FinalDilligenceCompanyWhereInput
  none?: FinalDilligenceCompanyWhereInput
}
export type FinalDilligenceCompanyWhereInput = {
  AND?: FinalDilligenceCompanyWhereInput[]
  OR?: FinalDilligenceCompanyWhereInput[]
  NOT?: FinalDilligenceCompanyWhereInput[]
  id?: IntFilter
  industry_id?: IntNullableFilter
  industry?: IndustryWhereInput
  name?: StringFilter
  url?: StringNullableFilter
  trend?: TrendWhereInput
  trend_id?: IntNullableFilter
}
export type IndustryWhereInput = {
  AND?: IndustryWhereInput[]
  OR?: IndustryWhereInput[]
  NOT?: IndustryWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  fund_id?: IntFilter
  fund?: FundWhereInput
  name?: StringNullableFilter
  icon?: StringNullableFilter
  companies_identified_count?: IntNullableFilter
  initial_calls_count?: IntNullableFilter
  secondary_dilligence_count?: IntNullableFilter
  final_dilligence_count?: IntNullableFilter
  final_dilligence_companies?: FinalDilligenceCompanyListRelationFilter
  portfolio_companies?: CompanyListRelationFilter
  selectable?: BoolNullableFilter
}
export type IndustryListRelationFilter = {
  every?: IndustryWhereInput
  some?: IndustryWhereInput
  none?: IndustryWhereInput
}
export type StageListRelationFilter = {
  every?: StageWhereInput
  some?: StageWhereInput
  none?: StageWhereInput
}
export type StageWhereInput = {
  AND?: StageWhereInput[]
  OR?: StageWhereInput[]
  NOT?: StageWhereInput[]
  id?: IntFilter
  name?: StringFilter
  base10_investment?: FloatNullableFilter
  fund_id?: IntNullableFilter
  fund?: FundWhereInput
}
export type DataRoomTopicListRelationFilter = {
  every?: DataRoomTopicWhereInput
  some?: DataRoomTopicWhereInput
  none?: DataRoomTopicWhereInput
}
export type QueryDataRoomTopicsOrderByInput = {
  ordinal?: SortOrder
}
export type MarketingEmailWhereUniqueInput = {
  id?: number
}
export type FundCreateInput = {
  name: string
  capital_deployed_perc_deployed_and_reserved?: number
  capital_deployed_perc_unallocated?: number
  moic?: number
  tvpi?: number
  gross_moic_on_initial_checks?: number
  moic_excl_6_months?: number
  capital_deployed?: number
  capital_deployed_change?: number
  fmv?: number
  fmv_change?: number
  portfolio_companies?: number
  new_portfolio_companies?: number
  months_since_first_close?: number
  show_moic?: boolean
  show_tvpi?: boolean
  show_gross_moic_on_initial_checks?: boolean
  show_moic_excl_6_months?: boolean
  show_capital_deployed?: boolean
  show_capital_deployed_change?: boolean
  show_fmv?: boolean
  show_fmv_change?: boolean
  show_portfolio_companies?: boolean
  show_new_portfolio_companies?: boolean
  show_months_since_first_close?: boolean
  last_update?: any
  seed_primary?: number
  seed_follow_on?: number
  series_a_primary?: number
  series_a_follow_on?: number
  series_b_primary?: number
  series_b_follow_on?: number
  reserved_but_not_deployed?: number
  unallocated?: number
  graph_early_growth?: number
  graph_growth?: number
  graph_late_growth?: number
  total_investable_capital?: number
  captial_deployed_footnote?: string
  fund_overview_footnote?: string
  capital_deployed_by_stage_footnote?: string
  capital_deployed_by_industry_footnote?: string
  advancement_initiative_portfolio_revenue_growth_footnote?: string
  portfolio_footnote?: string
  fmv_contribution_by_company_footnote?: string
  research_process_pipeline_footnote?: string
  capital_deployed_by_quarter_footnote?: string
  footnotes_and_disclosesures?: string
  companies?: CompanyCreateNestedManyWithoutFundInput
  industries?: IndustryCreateNestedManyWithoutFundInput
  trend?: TrendCreateNestedManyWithoutFundInput
  investment_by_stages?: StageCreateNestedManyWithoutFundInput
  dataroom_topics?: DataRoomTopicCreateNestedManyWithoutFundInput
}
export type CompanyCreateNestedManyWithoutFundInput = {
  create?: CompanyCreateWithoutFundInput[]
  connectOrCreate?: CompanyCreateOrConnectWithoutFundInput[]
  createMany?: CompanyCreateManyFundInputEnvelope
  connect?: CompanyWhereUniqueInput[]
}
export type CompanyCreateWithoutFundInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type FundingRoundCreateNestedManyWithoutCompanyInput = {
  create?: FundingRoundCreateWithoutCompanyInput[]
  connectOrCreate?: FundingRoundCreateOrConnectWithoutCompanyInput[]
  createMany?: FundingRoundCreateManyCompanyInputEnvelope
  connect?: FundingRoundWhereUniqueInput[]
}
export type FundingRoundCreateWithoutCompanyInput = {
  round_number?: number
  stage?: string
  funding_date?: any
  total_investment?: number
  base10_invest?: number
  valuation?: number
  ownership?: number
  footnote?: string
  coinvestors?: FundingRoundCoinvestorCreateNestedManyWithoutFundingRoundInput
}
export type FundingRoundCoinvestorCreateNestedManyWithoutFundingRoundInput = {
  create?: FundingRoundCoinvestorCreateWithoutFundingRoundInput[]
  connectOrCreate?: FundingRoundCoinvestorCreateOrConnectWithoutFundingRoundInput[]
  createMany?: FundingRoundCoinvestorCreateManyFundingRoundInputEnvelope
  connect?: FundingRoundCoinvestorWhereUniqueInput[]
}
export type FundingRoundCoinvestorCreateWithoutFundingRoundInput = {
  name?: string
  url?: string
}
export type FundingRoundCoinvestorCreateOrConnectWithoutFundingRoundInput = {
  where: FundingRoundCoinvestorWhereUniqueInput
  create: FundingRoundCoinvestorCreateWithoutFundingRoundInput
}
export type FundingRoundCoinvestorCreateManyFundingRoundInputEnvelope = {
  data?: FundingRoundCoinvestorCreateManyFundingRoundInput[]
  skipDuplicates?: boolean
}
export type FundingRoundCoinvestorCreateManyFundingRoundInput = {
  id?: number
  name?: string
  url?: string
}
export type FundingRoundCreateOrConnectWithoutCompanyInput = {
  where: FundingRoundWhereUniqueInput
  create: FundingRoundCreateWithoutCompanyInput
}
export type FundingRoundCreateManyCompanyInputEnvelope = {
  data?: FundingRoundCreateManyCompanyInput[]
  skipDuplicates?: boolean
}
export type FundingRoundCreateManyCompanyInput = {
  id?: number
  round_number?: number
  stage?: string
  funding_date?: any
  total_investment?: number
  base10_invest?: number
  valuation?: number
  ownership?: number
  footnote?: string
}
export type CoinvestorCreateNestedManyWithoutCompanyInput = {
  create?: CoinvestorCreateWithoutCompanyInput[]
  connectOrCreate?: CoinvestorCreateOrConnectWithoutCompanyInput[]
  createMany?: CoinvestorCreateManyCompanyInputEnvelope
  connect?: CoinvestorWhereUniqueInput[]
}
export type CoinvestorCreateWithoutCompanyInput = {
  name?: string
}
export type CoinvestorCreateOrConnectWithoutCompanyInput = {
  where: CoinvestorWhereUniqueInput
  create: CoinvestorCreateWithoutCompanyInput
}
export type CoinvestorCreateManyCompanyInputEnvelope = {
  data?: CoinvestorCreateManyCompanyInput[]
  skipDuplicates?: boolean
}
export type CoinvestorCreateManyCompanyInput = {
  id?: number
  name?: string
}
export type CompetitorCreateNestedManyWithoutCompanyInput = {
  create?: CompetitorCreateWithoutCompanyInput[]
  connectOrCreate?: CompetitorCreateOrConnectWithoutCompanyInput[]
  createMany?: CompetitorCreateManyCompanyInputEnvelope
  connect?: CompetitorWhereUniqueInput[]
}
export type CompetitorCreateWithoutCompanyInput = {
  name: string
}
export type CompetitorCreateOrConnectWithoutCompanyInput = {
  where: CompetitorWhereUniqueInput
  create: CompetitorCreateWithoutCompanyInput
}
export type CompetitorCreateManyCompanyInputEnvelope = {
  data?: CompetitorCreateManyCompanyInput[]
  skipDuplicates?: boolean
}
export type CompetitorCreateManyCompanyInput = {
  id?: number
  name: string
}
export type CompanyMemberCreateNestedManyWithoutCompanyInput = {
  create?: CompanyMemberCreateWithoutCompanyInput[]
  connectOrCreate?: CompanyMemberCreateOrConnectWithoutCompanyInput[]
  createMany?: CompanyMemberCreateManyCompanyInputEnvelope
  connect?: CompanyMemberWhereUniqueInput[]
}
export type CompanyMemberCreateWithoutCompanyInput = {
  name: string
  role?: string
  url?: string
}
export type CompanyMemberCreateOrConnectWithoutCompanyInput = {
  where: CompanyMemberWhereUniqueInput
  create: CompanyMemberCreateWithoutCompanyInput
}
export type CompanyMemberCreateManyCompanyInputEnvelope = {
  data?: CompanyMemberCreateManyCompanyInput[]
  skipDuplicates?: boolean
}
export type CompanyMemberCreateManyCompanyInput = {
  id?: number
  name: string
  role?: string
  url?: string
}
export type CompanyVideoCreateNestedManyWithoutCompanyInput = {
  create?: CompanyVideoCreateWithoutCompanyInput[]
  connectOrCreate?: CompanyVideoCreateOrConnectWithoutCompanyInput[]
  createMany?: CompanyVideoCreateManyCompanyInputEnvelope
  connect?: CompanyVideoWhereUniqueInput[]
}
export type CompanyVideoCreateWithoutCompanyInput = {
  title?: string
  url: string
  image_url?: string
}
export type CompanyVideoCreateOrConnectWithoutCompanyInput = {
  where: CompanyVideoWhereUniqueInput
  create: CompanyVideoCreateWithoutCompanyInput
}
export type CompanyVideoCreateManyCompanyInputEnvelope = {
  data?: CompanyVideoCreateManyCompanyInput[]
  skipDuplicates?: boolean
}
export type CompanyVideoCreateManyCompanyInput = {
  id?: number
  title?: string
  url: string
  image_url?: string
}
export type CompanyNewCreateNestedManyWithoutCompanyInput = {
  create?: CompanyNewCreateWithoutCompanyInput[]
  connectOrCreate?: CompanyNewCreateOrConnectWithoutCompanyInput[]
  createMany?: CompanyNewCreateManyCompanyInputEnvelope
  connect?: CompanyNewWhereUniqueInput[]
}
export type CompanyNewCreateWithoutCompanyInput = {
  title: string
  url?: string
}
export type CompanyNewCreateOrConnectWithoutCompanyInput = {
  where: CompanyNewWhereUniqueInput
  create: CompanyNewCreateWithoutCompanyInput
}
export type CompanyNewCreateManyCompanyInputEnvelope = {
  data?: CompanyNewCreateManyCompanyInput[]
  skipDuplicates?: boolean
}
export type CompanyNewCreateManyCompanyInput = {
  id?: number
  title: string
  url?: string
}
export type TrendCreateNestedManyWithoutPortfolioCompaniesInput = {
  create?: TrendCreateWithoutPortfolioCompaniesInput[]
  connectOrCreate?: TrendCreateOrConnectWithoutPortfolioCompaniesInput[]
  connect?: TrendWhereUniqueInput[]
}
export type TrendCreateWithoutPortfolioCompaniesInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  fund: FundCreateNestedOneWithoutTrendInput
  final_dilligence_companies?: FinalDilligenceCompanyCreateNestedManyWithoutTrendInput
}
export type FundCreateNestedOneWithoutTrendInput = {
  create?: FundCreateWithoutTrendInput
  connectOrCreate?: FundCreateOrConnectWithoutTrendInput
  connect?: FundWhereUniqueInput
}
export type FundCreateWithoutTrendInput = {
  name: string
  capital_deployed_perc_deployed_and_reserved?: number
  capital_deployed_perc_unallocated?: number
  moic?: number
  tvpi?: number
  gross_moic_on_initial_checks?: number
  moic_excl_6_months?: number
  capital_deployed?: number
  capital_deployed_change?: number
  fmv?: number
  fmv_change?: number
  portfolio_companies?: number
  new_portfolio_companies?: number
  months_since_first_close?: number
  show_moic?: boolean
  show_tvpi?: boolean
  show_gross_moic_on_initial_checks?: boolean
  show_moic_excl_6_months?: boolean
  show_capital_deployed?: boolean
  show_capital_deployed_change?: boolean
  show_fmv?: boolean
  show_fmv_change?: boolean
  show_portfolio_companies?: boolean
  show_new_portfolio_companies?: boolean
  show_months_since_first_close?: boolean
  last_update?: any
  seed_primary?: number
  seed_follow_on?: number
  series_a_primary?: number
  series_a_follow_on?: number
  series_b_primary?: number
  series_b_follow_on?: number
  reserved_but_not_deployed?: number
  unallocated?: number
  graph_early_growth?: number
  graph_growth?: number
  graph_late_growth?: number
  total_investable_capital?: number
  captial_deployed_footnote?: string
  fund_overview_footnote?: string
  capital_deployed_by_stage_footnote?: string
  capital_deployed_by_industry_footnote?: string
  advancement_initiative_portfolio_revenue_growth_footnote?: string
  portfolio_footnote?: string
  fmv_contribution_by_company_footnote?: string
  research_process_pipeline_footnote?: string
  capital_deployed_by_quarter_footnote?: string
  footnotes_and_disclosesures?: string
  companies?: CompanyCreateNestedManyWithoutFundInput
  industries?: IndustryCreateNestedManyWithoutFundInput
  investment_by_stages?: StageCreateNestedManyWithoutFundInput
  dataroom_topics?: DataRoomTopicCreateNestedManyWithoutFundInput
}
export type IndustryCreateNestedManyWithoutFundInput = {
  create?: IndustryCreateWithoutFundInput[]
  connectOrCreate?: IndustryCreateOrConnectWithoutFundInput[]
  createMany?: IndustryCreateManyFundInputEnvelope
  connect?: IndustryWhereUniqueInput[]
}
export type IndustryCreateWithoutFundInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  final_dilligence_companies?: FinalDilligenceCompanyCreateNestedManyWithoutIndustryInput
  portfolio_companies?: CompanyCreateNestedManyWithoutIndustryInput
}
export type FinalDilligenceCompanyCreateNestedManyWithoutIndustryInput = {
  create?: FinalDilligenceCompanyCreateWithoutIndustryInput[]
  connectOrCreate?: FinalDilligenceCompanyCreateOrConnectWithoutIndustryInput[]
  createMany?: FinalDilligenceCompanyCreateManyIndustryInputEnvelope
  connect?: FinalDilligenceCompanyWhereUniqueInput[]
}
export type FinalDilligenceCompanyCreateWithoutIndustryInput = {
  name: string
  url?: string
  trend?: TrendCreateNestedOneWithoutFinalDilligenceCompaniesInput
}
export type TrendCreateNestedOneWithoutFinalDilligenceCompaniesInput = {
  create?: TrendCreateWithoutFinalDilligenceCompaniesInput
  connectOrCreate?: TrendCreateOrConnectWithoutFinalDilligenceCompaniesInput
  connect?: TrendWhereUniqueInput
}
export type TrendCreateWithoutFinalDilligenceCompaniesInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  fund: FundCreateNestedOneWithoutTrendInput
  portfolio_companies?: CompanyCreateNestedManyWithoutTrendInput
}
export type CompanyCreateNestedManyWithoutTrendInput = {
  create?: CompanyCreateWithoutTrendInput[]
  connectOrCreate?: CompanyCreateOrConnectWithoutTrendInput[]
  connect?: CompanyWhereUniqueInput[]
}
export type CompanyCreateWithoutTrendInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type FundCreateNestedOneWithoutCompaniesInput = {
  create?: FundCreateWithoutCompaniesInput
  connectOrCreate?: FundCreateOrConnectWithoutCompaniesInput
  connect?: FundWhereUniqueInput
}
export type FundCreateWithoutCompaniesInput = {
  name: string
  capital_deployed_perc_deployed_and_reserved?: number
  capital_deployed_perc_unallocated?: number
  moic?: number
  tvpi?: number
  gross_moic_on_initial_checks?: number
  moic_excl_6_months?: number
  capital_deployed?: number
  capital_deployed_change?: number
  fmv?: number
  fmv_change?: number
  portfolio_companies?: number
  new_portfolio_companies?: number
  months_since_first_close?: number
  show_moic?: boolean
  show_tvpi?: boolean
  show_gross_moic_on_initial_checks?: boolean
  show_moic_excl_6_months?: boolean
  show_capital_deployed?: boolean
  show_capital_deployed_change?: boolean
  show_fmv?: boolean
  show_fmv_change?: boolean
  show_portfolio_companies?: boolean
  show_new_portfolio_companies?: boolean
  show_months_since_first_close?: boolean
  last_update?: any
  seed_primary?: number
  seed_follow_on?: number
  series_a_primary?: number
  series_a_follow_on?: number
  series_b_primary?: number
  series_b_follow_on?: number
  reserved_but_not_deployed?: number
  unallocated?: number
  graph_early_growth?: number
  graph_growth?: number
  graph_late_growth?: number
  total_investable_capital?: number
  captial_deployed_footnote?: string
  fund_overview_footnote?: string
  capital_deployed_by_stage_footnote?: string
  capital_deployed_by_industry_footnote?: string
  advancement_initiative_portfolio_revenue_growth_footnote?: string
  portfolio_footnote?: string
  fmv_contribution_by_company_footnote?: string
  research_process_pipeline_footnote?: string
  capital_deployed_by_quarter_footnote?: string
  footnotes_and_disclosesures?: string
  industries?: IndustryCreateNestedManyWithoutFundInput
  trend?: TrendCreateNestedManyWithoutFundInput
  investment_by_stages?: StageCreateNestedManyWithoutFundInput
  dataroom_topics?: DataRoomTopicCreateNestedManyWithoutFundInput
}
export type TrendCreateNestedManyWithoutFundInput = {
  create?: TrendCreateWithoutFundInput[]
  connectOrCreate?: TrendCreateOrConnectWithoutFundInput[]
  createMany?: TrendCreateManyFundInputEnvelope
  connect?: TrendWhereUniqueInput[]
}
export type TrendCreateWithoutFundInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  final_dilligence_companies?: FinalDilligenceCompanyCreateNestedManyWithoutTrendInput
  portfolio_companies?: CompanyCreateNestedManyWithoutTrendInput
}
export type FinalDilligenceCompanyCreateNestedManyWithoutTrendInput = {
  create?: FinalDilligenceCompanyCreateWithoutTrendInput[]
  connectOrCreate?: FinalDilligenceCompanyCreateOrConnectWithoutTrendInput[]
  createMany?: FinalDilligenceCompanyCreateManyTrendInputEnvelope
  connect?: FinalDilligenceCompanyWhereUniqueInput[]
}
export type FinalDilligenceCompanyCreateWithoutTrendInput = {
  name: string
  url?: string
  industry?: IndustryCreateNestedOneWithoutFinalDilligenceCompaniesInput
}
export type IndustryCreateNestedOneWithoutFinalDilligenceCompaniesInput = {
  create?: IndustryCreateWithoutFinalDilligenceCompaniesInput
  connectOrCreate?: IndustryCreateOrConnectWithoutFinalDilligenceCompaniesInput
  connect?: IndustryWhereUniqueInput
}
export type IndustryCreateWithoutFinalDilligenceCompaniesInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  fund: FundCreateNestedOneWithoutIndustriesInput
  portfolio_companies?: CompanyCreateNestedManyWithoutIndustryInput
}
export type FundCreateNestedOneWithoutIndustriesInput = {
  create?: FundCreateWithoutIndustriesInput
  connectOrCreate?: FundCreateOrConnectWithoutIndustriesInput
  connect?: FundWhereUniqueInput
}
export type FundCreateWithoutIndustriesInput = {
  name: string
  capital_deployed_perc_deployed_and_reserved?: number
  capital_deployed_perc_unallocated?: number
  moic?: number
  tvpi?: number
  gross_moic_on_initial_checks?: number
  moic_excl_6_months?: number
  capital_deployed?: number
  capital_deployed_change?: number
  fmv?: number
  fmv_change?: number
  portfolio_companies?: number
  new_portfolio_companies?: number
  months_since_first_close?: number
  show_moic?: boolean
  show_tvpi?: boolean
  show_gross_moic_on_initial_checks?: boolean
  show_moic_excl_6_months?: boolean
  show_capital_deployed?: boolean
  show_capital_deployed_change?: boolean
  show_fmv?: boolean
  show_fmv_change?: boolean
  show_portfolio_companies?: boolean
  show_new_portfolio_companies?: boolean
  show_months_since_first_close?: boolean
  last_update?: any
  seed_primary?: number
  seed_follow_on?: number
  series_a_primary?: number
  series_a_follow_on?: number
  series_b_primary?: number
  series_b_follow_on?: number
  reserved_but_not_deployed?: number
  unallocated?: number
  graph_early_growth?: number
  graph_growth?: number
  graph_late_growth?: number
  total_investable_capital?: number
  captial_deployed_footnote?: string
  fund_overview_footnote?: string
  capital_deployed_by_stage_footnote?: string
  capital_deployed_by_industry_footnote?: string
  advancement_initiative_portfolio_revenue_growth_footnote?: string
  portfolio_footnote?: string
  fmv_contribution_by_company_footnote?: string
  research_process_pipeline_footnote?: string
  capital_deployed_by_quarter_footnote?: string
  footnotes_and_disclosesures?: string
  companies?: CompanyCreateNestedManyWithoutFundInput
  trend?: TrendCreateNestedManyWithoutFundInput
  investment_by_stages?: StageCreateNestedManyWithoutFundInput
  dataroom_topics?: DataRoomTopicCreateNestedManyWithoutFundInput
}
export type StageCreateNestedManyWithoutFundInput = {
  create?: StageCreateWithoutFundInput[]
  connectOrCreate?: StageCreateOrConnectWithoutFundInput[]
  createMany?: StageCreateManyFundInputEnvelope
  connect?: StageWhereUniqueInput[]
}
export type StageCreateWithoutFundInput = {
  name: string
  base10_investment?: number
}
export type StageCreateOrConnectWithoutFundInput = {
  where: StageWhereUniqueInput
  create: StageCreateWithoutFundInput
}
export type StageCreateManyFundInputEnvelope = {
  data?: StageCreateManyFundInput[]
  skipDuplicates?: boolean
}
export type StageCreateManyFundInput = {
  id?: number
  name: string
  base10_investment?: number
}
export type DataRoomTopicCreateNestedManyWithoutFundInput = {
  create?: DataRoomTopicCreateWithoutFundInput[]
  connectOrCreate?: DataRoomTopicCreateOrConnectWithoutFundInput[]
  createMany?: DataRoomTopicCreateManyFundInputEnvelope
  connect?: DataRoomTopicWhereUniqueInput[]
}
export type DataRoomTopicCreateWithoutFundInput = {
  ordinal?: number
  topic_name: string
  hbcu?: boolean
  items?: DataRoomItemCreateNestedManyWithoutTopicInput
}
export type DataRoomItemCreateNestedManyWithoutTopicInput = {
  create?: DataRoomItemCreateWithoutTopicInput[]
  connectOrCreate?: DataRoomItemCreateOrConnectWithoutTopicInput[]
  createMany?: DataRoomItemCreateManyTopicInputEnvelope
  connect?: DataRoomItemWhereUniqueInput[]
}
export type DataRoomItemCreateWithoutTopicInput = {
  ordinal?: number
  label: string
  url: string
  type?: UrlType
}
export type DataRoomItemCreateOrConnectWithoutTopicInput = {
  where: DataRoomItemWhereUniqueInput
  create: DataRoomItemCreateWithoutTopicInput
}
export type DataRoomItemCreateManyTopicInputEnvelope = {
  data?: DataRoomItemCreateManyTopicInput[]
  skipDuplicates?: boolean
}
export type DataRoomItemCreateManyTopicInput = {
  id?: number
  ordinal?: number
  label: string
  url: string
  type?: UrlType
}
export type DataRoomTopicCreateOrConnectWithoutFundInput = {
  where: DataRoomTopicWhereUniqueInput
  create: DataRoomTopicCreateWithoutFundInput
}
export type DataRoomTopicCreateManyFundInputEnvelope = {
  data?: DataRoomTopicCreateManyFundInput[]
  skipDuplicates?: boolean
}
export type DataRoomTopicCreateManyFundInput = {
  id?: number
  ordinal?: number
  topic_name: string
  hbcu?: boolean
}
export type FundCreateOrConnectWithoutIndustriesInput = {
  where: FundWhereUniqueInput
  create: FundCreateWithoutIndustriesInput
}
export type CompanyCreateNestedManyWithoutIndustryInput = {
  create?: CompanyCreateWithoutIndustryInput[]
  connectOrCreate?: CompanyCreateOrConnectWithoutIndustryInput[]
  connect?: CompanyWhereUniqueInput[]
}
export type CompanyCreateWithoutIndustryInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutIndustryInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutIndustryInput
}
export type IndustryCreateOrConnectWithoutFinalDilligenceCompaniesInput = {
  where: IndustryWhereUniqueInput
  create: IndustryCreateWithoutFinalDilligenceCompaniesInput
}
export type FinalDilligenceCompanyCreateOrConnectWithoutTrendInput = {
  where: FinalDilligenceCompanyWhereUniqueInput
  create: FinalDilligenceCompanyCreateWithoutTrendInput
}
export type FinalDilligenceCompanyCreateManyTrendInputEnvelope = {
  data?: FinalDilligenceCompanyCreateManyTrendInput[]
  skipDuplicates?: boolean
}
export type FinalDilligenceCompanyCreateManyTrendInput = {
  id?: number
  industry_id?: number
  name: string
  url?: string
}
export type TrendCreateOrConnectWithoutFundInput = {
  where: TrendWhereUniqueInput
  create: TrendCreateWithoutFundInput
}
export type TrendCreateManyFundInputEnvelope = {
  data?: TrendCreateManyFundInput[]
  skipDuplicates?: boolean
}
export type TrendCreateManyFundInput = {
  id?: number
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
}
export type FundCreateOrConnectWithoutCompaniesInput = {
  where: FundWhereUniqueInput
  create: FundCreateWithoutCompaniesInput
}
export type IndustryCreateNestedManyWithoutPortfolioCompaniesInput = {
  create?: IndustryCreateWithoutPortfolioCompaniesInput[]
  connectOrCreate?: IndustryCreateOrConnectWithoutPortfolioCompaniesInput[]
  connect?: IndustryWhereUniqueInput[]
}
export type IndustryCreateWithoutPortfolioCompaniesInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  fund: FundCreateNestedOneWithoutIndustriesInput
  final_dilligence_companies?: FinalDilligenceCompanyCreateNestedManyWithoutIndustryInput
}
export type IndustryCreateOrConnectWithoutPortfolioCompaniesInput = {
  where: IndustryWhereUniqueInput
  create: IndustryCreateWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutTrendInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutTrendInput
}
export type TrendCreateOrConnectWithoutFinalDilligenceCompaniesInput = {
  where: TrendWhereUniqueInput
  create: TrendCreateWithoutFinalDilligenceCompaniesInput
}
export type FinalDilligenceCompanyCreateOrConnectWithoutIndustryInput = {
  where: FinalDilligenceCompanyWhereUniqueInput
  create: FinalDilligenceCompanyCreateWithoutIndustryInput
}
export type FinalDilligenceCompanyCreateManyIndustryInputEnvelope = {
  data?: FinalDilligenceCompanyCreateManyIndustryInput[]
  skipDuplicates?: boolean
}
export type FinalDilligenceCompanyCreateManyIndustryInput = {
  id?: number
  name: string
  url?: string
  trend_id?: number
}
export type IndustryCreateOrConnectWithoutFundInput = {
  where: IndustryWhereUniqueInput
  create: IndustryCreateWithoutFundInput
}
export type IndustryCreateManyFundInputEnvelope = {
  data?: IndustryCreateManyFundInput[]
  skipDuplicates?: boolean
}
export type IndustryCreateManyFundInput = {
  id?: number
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
}
export type FundCreateOrConnectWithoutTrendInput = {
  where: FundWhereUniqueInput
  create: FundCreateWithoutTrendInput
}
export type TrendCreateOrConnectWithoutPortfolioCompaniesInput = {
  where: TrendWhereUniqueInput
  create: TrendCreateWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutFundInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutFundInput
}
export type CompanyCreateManyFundInputEnvelope = {
  data?: CompanyCreateManyFundInput[]
  skipDuplicates?: boolean
}
export type CompanyCreateManyFundInput = {
  id?: number
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
}
export type FundUpdateInput = {
  name?: StringFieldUpdateOperationsInput
  capital_deployed_perc_deployed_and_reserved?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_perc_unallocated?: NullableFloatFieldUpdateOperationsInput
  moic?: NullableFloatFieldUpdateOperationsInput
  tvpi?: NullableFloatFieldUpdateOperationsInput
  gross_moic_on_initial_checks?: NullableFloatFieldUpdateOperationsInput
  moic_excl_6_months?: NullableFloatFieldUpdateOperationsInput
  capital_deployed?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_change?: NullableFloatFieldUpdateOperationsInput
  fmv?: NullableFloatFieldUpdateOperationsInput
  fmv_change?: NullableFloatFieldUpdateOperationsInput
  portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  new_portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  months_since_first_close?: NullableFloatFieldUpdateOperationsInput
  show_moic?: NullableBoolFieldUpdateOperationsInput
  show_tvpi?: NullableBoolFieldUpdateOperationsInput
  show_gross_moic_on_initial_checks?: NullableBoolFieldUpdateOperationsInput
  show_moic_excl_6_months?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed_change?: NullableBoolFieldUpdateOperationsInput
  show_fmv?: NullableBoolFieldUpdateOperationsInput
  show_fmv_change?: NullableBoolFieldUpdateOperationsInput
  show_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_new_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_months_since_first_close?: NullableBoolFieldUpdateOperationsInput
  last_update?: NullableDateTimeFieldUpdateOperationsInput
  seed_primary?: NullableFloatFieldUpdateOperationsInput
  seed_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_a_primary?: NullableFloatFieldUpdateOperationsInput
  series_a_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_b_primary?: NullableFloatFieldUpdateOperationsInput
  series_b_follow_on?: NullableFloatFieldUpdateOperationsInput
  reserved_but_not_deployed?: NullableFloatFieldUpdateOperationsInput
  unallocated?: NullableFloatFieldUpdateOperationsInput
  graph_early_growth?: NullableFloatFieldUpdateOperationsInput
  graph_growth?: NullableFloatFieldUpdateOperationsInput
  graph_late_growth?: NullableFloatFieldUpdateOperationsInput
  total_investable_capital?: NullableFloatFieldUpdateOperationsInput
  captial_deployed_footnote?: NullableStringFieldUpdateOperationsInput
  fund_overview_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_stage_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_industry_footnote?: NullableStringFieldUpdateOperationsInput
  advancement_initiative_portfolio_revenue_growth_footnote?: NullableStringFieldUpdateOperationsInput
  portfolio_footnote?: NullableStringFieldUpdateOperationsInput
  fmv_contribution_by_company_footnote?: NullableStringFieldUpdateOperationsInput
  research_process_pipeline_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_quarter_footnote?: NullableStringFieldUpdateOperationsInput
  footnotes_and_disclosesures?: NullableStringFieldUpdateOperationsInput
  companies?: CompanyUpdateManyWithoutFundInput
  industries?: IndustryUpdateManyWithoutFundInput
  trend?: TrendUpdateManyWithoutFundInput
  investment_by_stages?: StageUpdateManyWithoutFundInput
  dataroom_topics?: DataRoomTopicUpdateManyWithoutFundInput
}
export type StringFieldUpdateOperationsInput = {
  set?: string
}
export type NullableFloatFieldUpdateOperationsInput = {
  set?: number
  increment?: number
  decrement?: number
  multiply?: number
  divide?: number
}
export type NullableBoolFieldUpdateOperationsInput = {
  set?: boolean
}
export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: any
}
export type NullableStringFieldUpdateOperationsInput = {
  set?: string
}
export type CompanyUpdateManyWithoutFundInput = {
  create?: CompanyCreateWithoutFundInput[]
  connectOrCreate?: CompanyCreateOrConnectWithoutFundInput[]
  upsert?: CompanyUpsertWithWhereUniqueWithoutFundInput[]
  createMany?: CompanyCreateManyFundInputEnvelope
  connect?: CompanyWhereUniqueInput[]
  set?: CompanyWhereUniqueInput[]
  disconnect?: CompanyWhereUniqueInput[]
  delete?: CompanyWhereUniqueInput[]
  update?: CompanyUpdateWithWhereUniqueWithoutFundInput[]
  updateMany?: CompanyUpdateManyWithWhereWithoutFundInput[]
  deleteMany?: CompanyScalarWhereInput[]
}
export type CompanyUpsertWithWhereUniqueWithoutFundInput = {
  where: CompanyWhereUniqueInput
  update: CompanyUpdateWithoutFundInput
  create: CompanyCreateWithoutFundInput
}
export type CompanyUpdateWithoutFundInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type NullableIntFieldUpdateOperationsInput = {
  set?: number
  increment?: number
  decrement?: number
  multiply?: number
  divide?: number
}
export type NullableEnumGrowthFieldUpdateOperationsInput = {
  set?: Growth
}
export type FundingRoundUpdateManyWithoutCompanyInput = {
  create?: FundingRoundCreateWithoutCompanyInput[]
  connectOrCreate?: FundingRoundCreateOrConnectWithoutCompanyInput[]
  upsert?: FundingRoundUpsertWithWhereUniqueWithoutCompanyInput[]
  createMany?: FundingRoundCreateManyCompanyInputEnvelope
  connect?: FundingRoundWhereUniqueInput[]
  set?: FundingRoundWhereUniqueInput[]
  disconnect?: FundingRoundWhereUniqueInput[]
  delete?: FundingRoundWhereUniqueInput[]
  update?: FundingRoundUpdateWithWhereUniqueWithoutCompanyInput[]
  updateMany?: FundingRoundUpdateManyWithWhereWithoutCompanyInput[]
  deleteMany?: FundingRoundScalarWhereInput[]
}
export type FundingRoundUpsertWithWhereUniqueWithoutCompanyInput = {
  where: FundingRoundWhereUniqueInput
  update: FundingRoundUpdateWithoutCompanyInput
  create: FundingRoundCreateWithoutCompanyInput
}
export type FundingRoundUpdateWithoutCompanyInput = {
  round_number?: NullableIntFieldUpdateOperationsInput
  stage?: NullableStringFieldUpdateOperationsInput
  funding_date?: NullableDateTimeFieldUpdateOperationsInput
  total_investment?: NullableFloatFieldUpdateOperationsInput
  base10_invest?: NullableFloatFieldUpdateOperationsInput
  valuation?: NullableFloatFieldUpdateOperationsInput
  ownership?: NullableFloatFieldUpdateOperationsInput
  footnote?: NullableStringFieldUpdateOperationsInput
  coinvestors?: FundingRoundCoinvestorUpdateManyWithoutFundingRoundInput
}
export type FundingRoundCoinvestorUpdateManyWithoutFundingRoundInput = {
  create?: FundingRoundCoinvestorCreateWithoutFundingRoundInput[]
  connectOrCreate?: FundingRoundCoinvestorCreateOrConnectWithoutFundingRoundInput[]
  upsert?: FundingRoundCoinvestorUpsertWithWhereUniqueWithoutFundingRoundInput[]
  createMany?: FundingRoundCoinvestorCreateManyFundingRoundInputEnvelope
  connect?: FundingRoundCoinvestorWhereUniqueInput[]
  set?: FundingRoundCoinvestorWhereUniqueInput[]
  disconnect?: FundingRoundCoinvestorWhereUniqueInput[]
  delete?: FundingRoundCoinvestorWhereUniqueInput[]
  update?: FundingRoundCoinvestorUpdateWithWhereUniqueWithoutFundingRoundInput[]
  updateMany?: FundingRoundCoinvestorUpdateManyWithWhereWithoutFundingRoundInput[]
  deleteMany?: FundingRoundCoinvestorScalarWhereInput[]
}
export type FundingRoundCoinvestorUpsertWithWhereUniqueWithoutFundingRoundInput = {
  where: FundingRoundCoinvestorWhereUniqueInput
  update: FundingRoundCoinvestorUpdateWithoutFundingRoundInput
  create: FundingRoundCoinvestorCreateWithoutFundingRoundInput
}
export type FundingRoundCoinvestorUpdateWithoutFundingRoundInput = {
  name?: NullableStringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
}
export type FundingRoundCoinvestorUpdateWithWhereUniqueWithoutFundingRoundInput = {
  where: FundingRoundCoinvestorWhereUniqueInput
  data: FundingRoundCoinvestorUpdateWithoutFundingRoundInput
}
export type FundingRoundCoinvestorUpdateManyWithWhereWithoutFundingRoundInput = {
  where: FundingRoundCoinvestorScalarWhereInput
  data: FundingRoundCoinvestorUpdateManyMutationInput
}
export type FundingRoundCoinvestorScalarWhereInput = {
  AND?: FundingRoundCoinvestorScalarWhereInput[]
  OR?: FundingRoundCoinvestorScalarWhereInput[]
  NOT?: FundingRoundCoinvestorScalarWhereInput[]
  id?: IntFilter
  funding_round_id?: IntNullableFilter
  name?: StringNullableFilter
  url?: StringNullableFilter
}
export type FundingRoundCoinvestorUpdateManyMutationInput = {
  name?: NullableStringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
}
export type FundingRoundUpdateWithWhereUniqueWithoutCompanyInput = {
  where: FundingRoundWhereUniqueInput
  data: FundingRoundUpdateWithoutCompanyInput
}
export type FundingRoundUpdateManyWithWhereWithoutCompanyInput = {
  where: FundingRoundScalarWhereInput
  data: FundingRoundUpdateManyMutationInput
}
export type FundingRoundScalarWhereInput = {
  AND?: FundingRoundScalarWhereInput[]
  OR?: FundingRoundScalarWhereInput[]
  NOT?: FundingRoundScalarWhereInput[]
  id?: IntFilter
  round_number?: IntNullableFilter
  stage?: StringNullableFilter
  funding_date?: DateTimeNullableFilter
  total_investment?: FloatNullableFilter
  base10_invest?: FloatNullableFilter
  valuation?: FloatNullableFilter
  ownership?: FloatNullableFilter
  footnote?: StringNullableFilter
  company_id?: IntNullableFilter
}
export type FundingRoundUpdateManyMutationInput = {
  round_number?: NullableIntFieldUpdateOperationsInput
  stage?: NullableStringFieldUpdateOperationsInput
  funding_date?: NullableDateTimeFieldUpdateOperationsInput
  total_investment?: NullableFloatFieldUpdateOperationsInput
  base10_invest?: NullableFloatFieldUpdateOperationsInput
  valuation?: NullableFloatFieldUpdateOperationsInput
  ownership?: NullableFloatFieldUpdateOperationsInput
  footnote?: NullableStringFieldUpdateOperationsInput
}
export type CoinvestorUpdateManyWithoutCompanyInput = {
  create?: CoinvestorCreateWithoutCompanyInput[]
  connectOrCreate?: CoinvestorCreateOrConnectWithoutCompanyInput[]
  upsert?: CoinvestorUpsertWithWhereUniqueWithoutCompanyInput[]
  createMany?: CoinvestorCreateManyCompanyInputEnvelope
  connect?: CoinvestorWhereUniqueInput[]
  set?: CoinvestorWhereUniqueInput[]
  disconnect?: CoinvestorWhereUniqueInput[]
  delete?: CoinvestorWhereUniqueInput[]
  update?: CoinvestorUpdateWithWhereUniqueWithoutCompanyInput[]
  updateMany?: CoinvestorUpdateManyWithWhereWithoutCompanyInput[]
  deleteMany?: CoinvestorScalarWhereInput[]
}
export type CoinvestorUpsertWithWhereUniqueWithoutCompanyInput = {
  where: CoinvestorWhereUniqueInput
  update: CoinvestorUpdateWithoutCompanyInput
  create: CoinvestorCreateWithoutCompanyInput
}
export type CoinvestorUpdateWithoutCompanyInput = {
  name?: NullableStringFieldUpdateOperationsInput
}
export type CoinvestorUpdateWithWhereUniqueWithoutCompanyInput = {
  where: CoinvestorWhereUniqueInput
  data: CoinvestorUpdateWithoutCompanyInput
}
export type CoinvestorUpdateManyWithWhereWithoutCompanyInput = {
  where: CoinvestorScalarWhereInput
  data: CoinvestorUpdateManyMutationInput
}
export type CoinvestorScalarWhereInput = {
  AND?: CoinvestorScalarWhereInput[]
  OR?: CoinvestorScalarWhereInput[]
  NOT?: CoinvestorScalarWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  name?: StringNullableFilter
}
export type CoinvestorUpdateManyMutationInput = {
  name?: NullableStringFieldUpdateOperationsInput
}
export type CompetitorUpdateManyWithoutCompanyInput = {
  create?: CompetitorCreateWithoutCompanyInput[]
  connectOrCreate?: CompetitorCreateOrConnectWithoutCompanyInput[]
  upsert?: CompetitorUpsertWithWhereUniqueWithoutCompanyInput[]
  createMany?: CompetitorCreateManyCompanyInputEnvelope
  connect?: CompetitorWhereUniqueInput[]
  set?: CompetitorWhereUniqueInput[]
  disconnect?: CompetitorWhereUniqueInput[]
  delete?: CompetitorWhereUniqueInput[]
  update?: CompetitorUpdateWithWhereUniqueWithoutCompanyInput[]
  updateMany?: CompetitorUpdateManyWithWhereWithoutCompanyInput[]
  deleteMany?: CompetitorScalarWhereInput[]
}
export type CompetitorUpsertWithWhereUniqueWithoutCompanyInput = {
  where: CompetitorWhereUniqueInput
  update: CompetitorUpdateWithoutCompanyInput
  create: CompetitorCreateWithoutCompanyInput
}
export type CompetitorUpdateWithoutCompanyInput = {
  name?: StringFieldUpdateOperationsInput
}
export type CompetitorUpdateWithWhereUniqueWithoutCompanyInput = {
  where: CompetitorWhereUniqueInput
  data: CompetitorUpdateWithoutCompanyInput
}
export type CompetitorUpdateManyWithWhereWithoutCompanyInput = {
  where: CompetitorScalarWhereInput
  data: CompetitorUpdateManyMutationInput
}
export type CompetitorScalarWhereInput = {
  AND?: CompetitorScalarWhereInput[]
  OR?: CompetitorScalarWhereInput[]
  NOT?: CompetitorScalarWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  name?: StringFilter
}
export type CompetitorUpdateManyMutationInput = {
  name?: StringFieldUpdateOperationsInput
}
export type CompanyMemberUpdateManyWithoutCompanyInput = {
  create?: CompanyMemberCreateWithoutCompanyInput[]
  connectOrCreate?: CompanyMemberCreateOrConnectWithoutCompanyInput[]
  upsert?: CompanyMemberUpsertWithWhereUniqueWithoutCompanyInput[]
  createMany?: CompanyMemberCreateManyCompanyInputEnvelope
  connect?: CompanyMemberWhereUniqueInput[]
  set?: CompanyMemberWhereUniqueInput[]
  disconnect?: CompanyMemberWhereUniqueInput[]
  delete?: CompanyMemberWhereUniqueInput[]
  update?: CompanyMemberUpdateWithWhereUniqueWithoutCompanyInput[]
  updateMany?: CompanyMemberUpdateManyWithWhereWithoutCompanyInput[]
  deleteMany?: CompanyMemberScalarWhereInput[]
}
export type CompanyMemberUpsertWithWhereUniqueWithoutCompanyInput = {
  where: CompanyMemberWhereUniqueInput
  update: CompanyMemberUpdateWithoutCompanyInput
  create: CompanyMemberCreateWithoutCompanyInput
}
export type CompanyMemberUpdateWithoutCompanyInput = {
  name?: StringFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
}
export type CompanyMemberUpdateWithWhereUniqueWithoutCompanyInput = {
  where: CompanyMemberWhereUniqueInput
  data: CompanyMemberUpdateWithoutCompanyInput
}
export type CompanyMemberUpdateManyWithWhereWithoutCompanyInput = {
  where: CompanyMemberScalarWhereInput
  data: CompanyMemberUpdateManyMutationInput
}
export type CompanyMemberScalarWhereInput = {
  AND?: CompanyMemberScalarWhereInput[]
  OR?: CompanyMemberScalarWhereInput[]
  NOT?: CompanyMemberScalarWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  name?: StringFilter
  role?: StringNullableFilter
  url?: StringNullableFilter
}
export type CompanyMemberUpdateManyMutationInput = {
  name?: StringFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
}
export type CompanyVideoUpdateManyWithoutCompanyInput = {
  create?: CompanyVideoCreateWithoutCompanyInput[]
  connectOrCreate?: CompanyVideoCreateOrConnectWithoutCompanyInput[]
  upsert?: CompanyVideoUpsertWithWhereUniqueWithoutCompanyInput[]
  createMany?: CompanyVideoCreateManyCompanyInputEnvelope
  connect?: CompanyVideoWhereUniqueInput[]
  set?: CompanyVideoWhereUniqueInput[]
  disconnect?: CompanyVideoWhereUniqueInput[]
  delete?: CompanyVideoWhereUniqueInput[]
  update?: CompanyVideoUpdateWithWhereUniqueWithoutCompanyInput[]
  updateMany?: CompanyVideoUpdateManyWithWhereWithoutCompanyInput[]
  deleteMany?: CompanyVideoScalarWhereInput[]
}
export type CompanyVideoUpsertWithWhereUniqueWithoutCompanyInput = {
  where: CompanyVideoWhereUniqueInput
  update: CompanyVideoUpdateWithoutCompanyInput
  create: CompanyVideoCreateWithoutCompanyInput
}
export type CompanyVideoUpdateWithoutCompanyInput = {
  title?: NullableStringFieldUpdateOperationsInput
  url?: StringFieldUpdateOperationsInput
  image_url?: NullableStringFieldUpdateOperationsInput
}
export type CompanyVideoUpdateWithWhereUniqueWithoutCompanyInput = {
  where: CompanyVideoWhereUniqueInput
  data: CompanyVideoUpdateWithoutCompanyInput
}
export type CompanyVideoUpdateManyWithWhereWithoutCompanyInput = {
  where: CompanyVideoScalarWhereInput
  data: CompanyVideoUpdateManyMutationInput
}
export type CompanyVideoScalarWhereInput = {
  AND?: CompanyVideoScalarWhereInput[]
  OR?: CompanyVideoScalarWhereInput[]
  NOT?: CompanyVideoScalarWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  title?: StringNullableFilter
  url?: StringFilter
  image_url?: StringNullableFilter
}
export type CompanyVideoUpdateManyMutationInput = {
  title?: NullableStringFieldUpdateOperationsInput
  url?: StringFieldUpdateOperationsInput
  image_url?: NullableStringFieldUpdateOperationsInput
}
export type CompanyNewUpdateManyWithoutCompanyInput = {
  create?: CompanyNewCreateWithoutCompanyInput[]
  connectOrCreate?: CompanyNewCreateOrConnectWithoutCompanyInput[]
  upsert?: CompanyNewUpsertWithWhereUniqueWithoutCompanyInput[]
  createMany?: CompanyNewCreateManyCompanyInputEnvelope
  connect?: CompanyNewWhereUniqueInput[]
  set?: CompanyNewWhereUniqueInput[]
  disconnect?: CompanyNewWhereUniqueInput[]
  delete?: CompanyNewWhereUniqueInput[]
  update?: CompanyNewUpdateWithWhereUniqueWithoutCompanyInput[]
  updateMany?: CompanyNewUpdateManyWithWhereWithoutCompanyInput[]
  deleteMany?: CompanyNewScalarWhereInput[]
}
export type CompanyNewUpsertWithWhereUniqueWithoutCompanyInput = {
  where: CompanyNewWhereUniqueInput
  update: CompanyNewUpdateWithoutCompanyInput
  create: CompanyNewCreateWithoutCompanyInput
}
export type CompanyNewUpdateWithoutCompanyInput = {
  title?: StringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
}
export type CompanyNewUpdateWithWhereUniqueWithoutCompanyInput = {
  where: CompanyNewWhereUniqueInput
  data: CompanyNewUpdateWithoutCompanyInput
}
export type CompanyNewUpdateManyWithWhereWithoutCompanyInput = {
  where: CompanyNewScalarWhereInput
  data: CompanyNewUpdateManyMutationInput
}
export type CompanyNewScalarWhereInput = {
  AND?: CompanyNewScalarWhereInput[]
  OR?: CompanyNewScalarWhereInput[]
  NOT?: CompanyNewScalarWhereInput[]
  id?: IntFilter
  company_id?: IntNullableFilter
  title?: StringFilter
  url?: StringNullableFilter
}
export type CompanyNewUpdateManyMutationInput = {
  title?: StringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
}
export type TrendUpdateManyWithoutPortfolioCompaniesInput = {
  create?: TrendCreateWithoutPortfolioCompaniesInput[]
  connectOrCreate?: TrendCreateOrConnectWithoutPortfolioCompaniesInput[]
  upsert?: TrendUpsertWithWhereUniqueWithoutPortfolioCompaniesInput[]
  connect?: TrendWhereUniqueInput[]
  set?: TrendWhereUniqueInput[]
  disconnect?: TrendWhereUniqueInput[]
  delete?: TrendWhereUniqueInput[]
  update?: TrendUpdateWithWhereUniqueWithoutPortfolioCompaniesInput[]
  updateMany?: TrendUpdateManyWithWhereWithoutPortfolioCompaniesInput[]
  deleteMany?: TrendScalarWhereInput[]
}
export type TrendUpsertWithWhereUniqueWithoutPortfolioCompaniesInput = {
  where: TrendWhereUniqueInput
  update: TrendUpdateWithoutPortfolioCompaniesInput
  create: TrendCreateWithoutPortfolioCompaniesInput
}
export type TrendUpdateWithoutPortfolioCompaniesInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneRequiredWithoutTrendInput
  final_dilligence_companies?: FinalDilligenceCompanyUpdateManyWithoutTrendInput
}
export type IntFieldUpdateOperationsInput = {
  set?: number
  increment?: number
  decrement?: number
  multiply?: number
  divide?: number
}
export type FundUpdateOneRequiredWithoutTrendInput = {
  create?: FundCreateWithoutTrendInput
  connectOrCreate?: FundCreateOrConnectWithoutTrendInput
  upsert?: FundUpsertWithoutTrendInput
  connect?: FundWhereUniqueInput
  update?: FundUpdateWithoutTrendInput
}
export type FundUpsertWithoutTrendInput = {
  update: FundUpdateWithoutTrendInput
  create: FundCreateWithoutTrendInput
}
export type FundUpdateWithoutTrendInput = {
  name?: StringFieldUpdateOperationsInput
  capital_deployed_perc_deployed_and_reserved?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_perc_unallocated?: NullableFloatFieldUpdateOperationsInput
  moic?: NullableFloatFieldUpdateOperationsInput
  tvpi?: NullableFloatFieldUpdateOperationsInput
  gross_moic_on_initial_checks?: NullableFloatFieldUpdateOperationsInput
  moic_excl_6_months?: NullableFloatFieldUpdateOperationsInput
  capital_deployed?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_change?: NullableFloatFieldUpdateOperationsInput
  fmv?: NullableFloatFieldUpdateOperationsInput
  fmv_change?: NullableFloatFieldUpdateOperationsInput
  portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  new_portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  months_since_first_close?: NullableFloatFieldUpdateOperationsInput
  show_moic?: NullableBoolFieldUpdateOperationsInput
  show_tvpi?: NullableBoolFieldUpdateOperationsInput
  show_gross_moic_on_initial_checks?: NullableBoolFieldUpdateOperationsInput
  show_moic_excl_6_months?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed_change?: NullableBoolFieldUpdateOperationsInput
  show_fmv?: NullableBoolFieldUpdateOperationsInput
  show_fmv_change?: NullableBoolFieldUpdateOperationsInput
  show_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_new_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_months_since_first_close?: NullableBoolFieldUpdateOperationsInput
  last_update?: NullableDateTimeFieldUpdateOperationsInput
  seed_primary?: NullableFloatFieldUpdateOperationsInput
  seed_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_a_primary?: NullableFloatFieldUpdateOperationsInput
  series_a_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_b_primary?: NullableFloatFieldUpdateOperationsInput
  series_b_follow_on?: NullableFloatFieldUpdateOperationsInput
  reserved_but_not_deployed?: NullableFloatFieldUpdateOperationsInput
  unallocated?: NullableFloatFieldUpdateOperationsInput
  graph_early_growth?: NullableFloatFieldUpdateOperationsInput
  graph_growth?: NullableFloatFieldUpdateOperationsInput
  graph_late_growth?: NullableFloatFieldUpdateOperationsInput
  total_investable_capital?: NullableFloatFieldUpdateOperationsInput
  captial_deployed_footnote?: NullableStringFieldUpdateOperationsInput
  fund_overview_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_stage_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_industry_footnote?: NullableStringFieldUpdateOperationsInput
  advancement_initiative_portfolio_revenue_growth_footnote?: NullableStringFieldUpdateOperationsInput
  portfolio_footnote?: NullableStringFieldUpdateOperationsInput
  fmv_contribution_by_company_footnote?: NullableStringFieldUpdateOperationsInput
  research_process_pipeline_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_quarter_footnote?: NullableStringFieldUpdateOperationsInput
  footnotes_and_disclosesures?: NullableStringFieldUpdateOperationsInput
  companies?: CompanyUpdateManyWithoutFundInput
  industries?: IndustryUpdateManyWithoutFundInput
  investment_by_stages?: StageUpdateManyWithoutFundInput
  dataroom_topics?: DataRoomTopicUpdateManyWithoutFundInput
}
export type IndustryUpdateManyWithoutFundInput = {
  create?: IndustryCreateWithoutFundInput[]
  connectOrCreate?: IndustryCreateOrConnectWithoutFundInput[]
  upsert?: IndustryUpsertWithWhereUniqueWithoutFundInput[]
  createMany?: IndustryCreateManyFundInputEnvelope
  connect?: IndustryWhereUniqueInput[]
  set?: IndustryWhereUniqueInput[]
  disconnect?: IndustryWhereUniqueInput[]
  delete?: IndustryWhereUniqueInput[]
  update?: IndustryUpdateWithWhereUniqueWithoutFundInput[]
  updateMany?: IndustryUpdateManyWithWhereWithoutFundInput[]
  deleteMany?: IndustryScalarWhereInput[]
}
export type IndustryUpsertWithWhereUniqueWithoutFundInput = {
  where: IndustryWhereUniqueInput
  update: IndustryUpdateWithoutFundInput
  create: IndustryCreateWithoutFundInput
}
export type IndustryUpdateWithoutFundInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
  final_dilligence_companies?: FinalDilligenceCompanyUpdateManyWithoutIndustryInput
  portfolio_companies?: CompanyUpdateManyWithoutIndustryInput
}
export type FinalDilligenceCompanyUpdateManyWithoutIndustryInput = {
  create?: FinalDilligenceCompanyCreateWithoutIndustryInput[]
  connectOrCreate?: FinalDilligenceCompanyCreateOrConnectWithoutIndustryInput[]
  upsert?: FinalDilligenceCompanyUpsertWithWhereUniqueWithoutIndustryInput[]
  createMany?: FinalDilligenceCompanyCreateManyIndustryInputEnvelope
  connect?: FinalDilligenceCompanyWhereUniqueInput[]
  set?: FinalDilligenceCompanyWhereUniqueInput[]
  disconnect?: FinalDilligenceCompanyWhereUniqueInput[]
  delete?: FinalDilligenceCompanyWhereUniqueInput[]
  update?: FinalDilligenceCompanyUpdateWithWhereUniqueWithoutIndustryInput[]
  updateMany?: FinalDilligenceCompanyUpdateManyWithWhereWithoutIndustryInput[]
  deleteMany?: FinalDilligenceCompanyScalarWhereInput[]
}
export type FinalDilligenceCompanyUpsertWithWhereUniqueWithoutIndustryInput = {
  where: FinalDilligenceCompanyWhereUniqueInput
  update: FinalDilligenceCompanyUpdateWithoutIndustryInput
  create: FinalDilligenceCompanyCreateWithoutIndustryInput
}
export type FinalDilligenceCompanyUpdateWithoutIndustryInput = {
  name?: StringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
  trend?: TrendUpdateOneWithoutFinalDilligenceCompaniesInput
}
export type TrendUpdateOneWithoutFinalDilligenceCompaniesInput = {
  create?: TrendCreateWithoutFinalDilligenceCompaniesInput
  connectOrCreate?: TrendCreateOrConnectWithoutFinalDilligenceCompaniesInput
  upsert?: TrendUpsertWithoutFinalDilligenceCompaniesInput
  connect?: TrendWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: TrendUpdateWithoutFinalDilligenceCompaniesInput
}
export type TrendUpsertWithoutFinalDilligenceCompaniesInput = {
  update: TrendUpdateWithoutFinalDilligenceCompaniesInput
  create: TrendCreateWithoutFinalDilligenceCompaniesInput
}
export type TrendUpdateWithoutFinalDilligenceCompaniesInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneRequiredWithoutTrendInput
  portfolio_companies?: CompanyUpdateManyWithoutTrendInput
}
export type CompanyUpdateManyWithoutTrendInput = {
  create?: CompanyCreateWithoutTrendInput[]
  connectOrCreate?: CompanyCreateOrConnectWithoutTrendInput[]
  upsert?: CompanyUpsertWithWhereUniqueWithoutTrendInput[]
  connect?: CompanyWhereUniqueInput[]
  set?: CompanyWhereUniqueInput[]
  disconnect?: CompanyWhereUniqueInput[]
  delete?: CompanyWhereUniqueInput[]
  update?: CompanyUpdateWithWhereUniqueWithoutTrendInput[]
  updateMany?: CompanyUpdateManyWithWhereWithoutTrendInput[]
  deleteMany?: CompanyScalarWhereInput[]
}
export type CompanyUpsertWithWhereUniqueWithoutTrendInput = {
  where: CompanyWhereUniqueInput
  update: CompanyUpdateWithoutTrendInput
  create: CompanyCreateWithoutTrendInput
}
export type CompanyUpdateWithoutTrendInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type FundUpdateOneWithoutCompaniesInput = {
  create?: FundCreateWithoutCompaniesInput
  connectOrCreate?: FundCreateOrConnectWithoutCompaniesInput
  upsert?: FundUpsertWithoutCompaniesInput
  connect?: FundWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: FundUpdateWithoutCompaniesInput
}
export type FundUpsertWithoutCompaniesInput = {
  update: FundUpdateWithoutCompaniesInput
  create: FundCreateWithoutCompaniesInput
}
export type FundUpdateWithoutCompaniesInput = {
  name?: StringFieldUpdateOperationsInput
  capital_deployed_perc_deployed_and_reserved?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_perc_unallocated?: NullableFloatFieldUpdateOperationsInput
  moic?: NullableFloatFieldUpdateOperationsInput
  tvpi?: NullableFloatFieldUpdateOperationsInput
  gross_moic_on_initial_checks?: NullableFloatFieldUpdateOperationsInput
  moic_excl_6_months?: NullableFloatFieldUpdateOperationsInput
  capital_deployed?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_change?: NullableFloatFieldUpdateOperationsInput
  fmv?: NullableFloatFieldUpdateOperationsInput
  fmv_change?: NullableFloatFieldUpdateOperationsInput
  portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  new_portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  months_since_first_close?: NullableFloatFieldUpdateOperationsInput
  show_moic?: NullableBoolFieldUpdateOperationsInput
  show_tvpi?: NullableBoolFieldUpdateOperationsInput
  show_gross_moic_on_initial_checks?: NullableBoolFieldUpdateOperationsInput
  show_moic_excl_6_months?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed_change?: NullableBoolFieldUpdateOperationsInput
  show_fmv?: NullableBoolFieldUpdateOperationsInput
  show_fmv_change?: NullableBoolFieldUpdateOperationsInput
  show_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_new_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_months_since_first_close?: NullableBoolFieldUpdateOperationsInput
  last_update?: NullableDateTimeFieldUpdateOperationsInput
  seed_primary?: NullableFloatFieldUpdateOperationsInput
  seed_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_a_primary?: NullableFloatFieldUpdateOperationsInput
  series_a_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_b_primary?: NullableFloatFieldUpdateOperationsInput
  series_b_follow_on?: NullableFloatFieldUpdateOperationsInput
  reserved_but_not_deployed?: NullableFloatFieldUpdateOperationsInput
  unallocated?: NullableFloatFieldUpdateOperationsInput
  graph_early_growth?: NullableFloatFieldUpdateOperationsInput
  graph_growth?: NullableFloatFieldUpdateOperationsInput
  graph_late_growth?: NullableFloatFieldUpdateOperationsInput
  total_investable_capital?: NullableFloatFieldUpdateOperationsInput
  captial_deployed_footnote?: NullableStringFieldUpdateOperationsInput
  fund_overview_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_stage_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_industry_footnote?: NullableStringFieldUpdateOperationsInput
  advancement_initiative_portfolio_revenue_growth_footnote?: NullableStringFieldUpdateOperationsInput
  portfolio_footnote?: NullableStringFieldUpdateOperationsInput
  fmv_contribution_by_company_footnote?: NullableStringFieldUpdateOperationsInput
  research_process_pipeline_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_quarter_footnote?: NullableStringFieldUpdateOperationsInput
  footnotes_and_disclosesures?: NullableStringFieldUpdateOperationsInput
  industries?: IndustryUpdateManyWithoutFundInput
  trend?: TrendUpdateManyWithoutFundInput
  investment_by_stages?: StageUpdateManyWithoutFundInput
  dataroom_topics?: DataRoomTopicUpdateManyWithoutFundInput
}
export type TrendUpdateManyWithoutFundInput = {
  create?: TrendCreateWithoutFundInput[]
  connectOrCreate?: TrendCreateOrConnectWithoutFundInput[]
  upsert?: TrendUpsertWithWhereUniqueWithoutFundInput[]
  createMany?: TrendCreateManyFundInputEnvelope
  connect?: TrendWhereUniqueInput[]
  set?: TrendWhereUniqueInput[]
  disconnect?: TrendWhereUniqueInput[]
  delete?: TrendWhereUniqueInput[]
  update?: TrendUpdateWithWhereUniqueWithoutFundInput[]
  updateMany?: TrendUpdateManyWithWhereWithoutFundInput[]
  deleteMany?: TrendScalarWhereInput[]
}
export type TrendUpsertWithWhereUniqueWithoutFundInput = {
  where: TrendWhereUniqueInput
  update: TrendUpdateWithoutFundInput
  create: TrendCreateWithoutFundInput
}
export type TrendUpdateWithoutFundInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
  final_dilligence_companies?: FinalDilligenceCompanyUpdateManyWithoutTrendInput
  portfolio_companies?: CompanyUpdateManyWithoutTrendInput
}
export type FinalDilligenceCompanyUpdateManyWithoutTrendInput = {
  create?: FinalDilligenceCompanyCreateWithoutTrendInput[]
  connectOrCreate?: FinalDilligenceCompanyCreateOrConnectWithoutTrendInput[]
  upsert?: FinalDilligenceCompanyUpsertWithWhereUniqueWithoutTrendInput[]
  createMany?: FinalDilligenceCompanyCreateManyTrendInputEnvelope
  connect?: FinalDilligenceCompanyWhereUniqueInput[]
  set?: FinalDilligenceCompanyWhereUniqueInput[]
  disconnect?: FinalDilligenceCompanyWhereUniqueInput[]
  delete?: FinalDilligenceCompanyWhereUniqueInput[]
  update?: FinalDilligenceCompanyUpdateWithWhereUniqueWithoutTrendInput[]
  updateMany?: FinalDilligenceCompanyUpdateManyWithWhereWithoutTrendInput[]
  deleteMany?: FinalDilligenceCompanyScalarWhereInput[]
}
export type FinalDilligenceCompanyUpsertWithWhereUniqueWithoutTrendInput = {
  where: FinalDilligenceCompanyWhereUniqueInput
  update: FinalDilligenceCompanyUpdateWithoutTrendInput
  create: FinalDilligenceCompanyCreateWithoutTrendInput
}
export type FinalDilligenceCompanyUpdateWithoutTrendInput = {
  name?: StringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
  industry?: IndustryUpdateOneWithoutFinalDilligenceCompaniesInput
}
export type IndustryUpdateOneWithoutFinalDilligenceCompaniesInput = {
  create?: IndustryCreateWithoutFinalDilligenceCompaniesInput
  connectOrCreate?: IndustryCreateOrConnectWithoutFinalDilligenceCompaniesInput
  upsert?: IndustryUpsertWithoutFinalDilligenceCompaniesInput
  connect?: IndustryWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: IndustryUpdateWithoutFinalDilligenceCompaniesInput
}
export type IndustryUpsertWithoutFinalDilligenceCompaniesInput = {
  update: IndustryUpdateWithoutFinalDilligenceCompaniesInput
  create: IndustryCreateWithoutFinalDilligenceCompaniesInput
}
export type IndustryUpdateWithoutFinalDilligenceCompaniesInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneRequiredWithoutIndustriesInput
  portfolio_companies?: CompanyUpdateManyWithoutIndustryInput
}
export type FundUpdateOneRequiredWithoutIndustriesInput = {
  create?: FundCreateWithoutIndustriesInput
  connectOrCreate?: FundCreateOrConnectWithoutIndustriesInput
  upsert?: FundUpsertWithoutIndustriesInput
  connect?: FundWhereUniqueInput
  update?: FundUpdateWithoutIndustriesInput
}
export type FundUpsertWithoutIndustriesInput = {
  update: FundUpdateWithoutIndustriesInput
  create: FundCreateWithoutIndustriesInput
}
export type FundUpdateWithoutIndustriesInput = {
  name?: StringFieldUpdateOperationsInput
  capital_deployed_perc_deployed_and_reserved?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_perc_unallocated?: NullableFloatFieldUpdateOperationsInput
  moic?: NullableFloatFieldUpdateOperationsInput
  tvpi?: NullableFloatFieldUpdateOperationsInput
  gross_moic_on_initial_checks?: NullableFloatFieldUpdateOperationsInput
  moic_excl_6_months?: NullableFloatFieldUpdateOperationsInput
  capital_deployed?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_change?: NullableFloatFieldUpdateOperationsInput
  fmv?: NullableFloatFieldUpdateOperationsInput
  fmv_change?: NullableFloatFieldUpdateOperationsInput
  portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  new_portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  months_since_first_close?: NullableFloatFieldUpdateOperationsInput
  show_moic?: NullableBoolFieldUpdateOperationsInput
  show_tvpi?: NullableBoolFieldUpdateOperationsInput
  show_gross_moic_on_initial_checks?: NullableBoolFieldUpdateOperationsInput
  show_moic_excl_6_months?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed_change?: NullableBoolFieldUpdateOperationsInput
  show_fmv?: NullableBoolFieldUpdateOperationsInput
  show_fmv_change?: NullableBoolFieldUpdateOperationsInput
  show_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_new_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_months_since_first_close?: NullableBoolFieldUpdateOperationsInput
  last_update?: NullableDateTimeFieldUpdateOperationsInput
  seed_primary?: NullableFloatFieldUpdateOperationsInput
  seed_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_a_primary?: NullableFloatFieldUpdateOperationsInput
  series_a_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_b_primary?: NullableFloatFieldUpdateOperationsInput
  series_b_follow_on?: NullableFloatFieldUpdateOperationsInput
  reserved_but_not_deployed?: NullableFloatFieldUpdateOperationsInput
  unallocated?: NullableFloatFieldUpdateOperationsInput
  graph_early_growth?: NullableFloatFieldUpdateOperationsInput
  graph_growth?: NullableFloatFieldUpdateOperationsInput
  graph_late_growth?: NullableFloatFieldUpdateOperationsInput
  total_investable_capital?: NullableFloatFieldUpdateOperationsInput
  captial_deployed_footnote?: NullableStringFieldUpdateOperationsInput
  fund_overview_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_stage_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_industry_footnote?: NullableStringFieldUpdateOperationsInput
  advancement_initiative_portfolio_revenue_growth_footnote?: NullableStringFieldUpdateOperationsInput
  portfolio_footnote?: NullableStringFieldUpdateOperationsInput
  fmv_contribution_by_company_footnote?: NullableStringFieldUpdateOperationsInput
  research_process_pipeline_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_quarter_footnote?: NullableStringFieldUpdateOperationsInput
  footnotes_and_disclosesures?: NullableStringFieldUpdateOperationsInput
  companies?: CompanyUpdateManyWithoutFundInput
  trend?: TrendUpdateManyWithoutFundInput
  investment_by_stages?: StageUpdateManyWithoutFundInput
  dataroom_topics?: DataRoomTopicUpdateManyWithoutFundInput
}
export type StageUpdateManyWithoutFundInput = {
  create?: StageCreateWithoutFundInput[]
  connectOrCreate?: StageCreateOrConnectWithoutFundInput[]
  upsert?: StageUpsertWithWhereUniqueWithoutFundInput[]
  createMany?: StageCreateManyFundInputEnvelope
  connect?: StageWhereUniqueInput[]
  set?: StageWhereUniqueInput[]
  disconnect?: StageWhereUniqueInput[]
  delete?: StageWhereUniqueInput[]
  update?: StageUpdateWithWhereUniqueWithoutFundInput[]
  updateMany?: StageUpdateManyWithWhereWithoutFundInput[]
  deleteMany?: StageScalarWhereInput[]
}
export type StageUpsertWithWhereUniqueWithoutFundInput = {
  where: StageWhereUniqueInput
  update: StageUpdateWithoutFundInput
  create: StageCreateWithoutFundInput
}
export type StageUpdateWithoutFundInput = {
  name?: StringFieldUpdateOperationsInput
  base10_investment?: NullableFloatFieldUpdateOperationsInput
}
export type StageUpdateWithWhereUniqueWithoutFundInput = {
  where: StageWhereUniqueInput
  data: StageUpdateWithoutFundInput
}
export type StageUpdateManyWithWhereWithoutFundInput = {
  where: StageScalarWhereInput
  data: StageUpdateManyMutationInput
}
export type StageScalarWhereInput = {
  AND?: StageScalarWhereInput[]
  OR?: StageScalarWhereInput[]
  NOT?: StageScalarWhereInput[]
  id?: IntFilter
  name?: StringFilter
  base10_investment?: FloatNullableFilter
  fund_id?: IntNullableFilter
}
export type StageUpdateManyMutationInput = {
  name?: StringFieldUpdateOperationsInput
  base10_investment?: NullableFloatFieldUpdateOperationsInput
}
export type DataRoomTopicUpdateManyWithoutFundInput = {
  create?: DataRoomTopicCreateWithoutFundInput[]
  connectOrCreate?: DataRoomTopicCreateOrConnectWithoutFundInput[]
  upsert?: DataRoomTopicUpsertWithWhereUniqueWithoutFundInput[]
  createMany?: DataRoomTopicCreateManyFundInputEnvelope
  connect?: DataRoomTopicWhereUniqueInput[]
  set?: DataRoomTopicWhereUniqueInput[]
  disconnect?: DataRoomTopicWhereUniqueInput[]
  delete?: DataRoomTopicWhereUniqueInput[]
  update?: DataRoomTopicUpdateWithWhereUniqueWithoutFundInput[]
  updateMany?: DataRoomTopicUpdateManyWithWhereWithoutFundInput[]
  deleteMany?: DataRoomTopicScalarWhereInput[]
}
export type DataRoomTopicUpsertWithWhereUniqueWithoutFundInput = {
  where: DataRoomTopicWhereUniqueInput
  update: DataRoomTopicUpdateWithoutFundInput
  create: DataRoomTopicCreateWithoutFundInput
}
export type DataRoomTopicUpdateWithoutFundInput = {
  ordinal?: IntFieldUpdateOperationsInput
  topic_name?: StringFieldUpdateOperationsInput
  hbcu?: BoolFieldUpdateOperationsInput
  items?: DataRoomItemUpdateManyWithoutTopicInput
}
export type BoolFieldUpdateOperationsInput = {
  set?: boolean
}
export type DataRoomItemUpdateManyWithoutTopicInput = {
  create?: DataRoomItemCreateWithoutTopicInput[]
  connectOrCreate?: DataRoomItemCreateOrConnectWithoutTopicInput[]
  upsert?: DataRoomItemUpsertWithWhereUniqueWithoutTopicInput[]
  createMany?: DataRoomItemCreateManyTopicInputEnvelope
  connect?: DataRoomItemWhereUniqueInput[]
  set?: DataRoomItemWhereUniqueInput[]
  disconnect?: DataRoomItemWhereUniqueInput[]
  delete?: DataRoomItemWhereUniqueInput[]
  update?: DataRoomItemUpdateWithWhereUniqueWithoutTopicInput[]
  updateMany?: DataRoomItemUpdateManyWithWhereWithoutTopicInput[]
  deleteMany?: DataRoomItemScalarWhereInput[]
}
export type DataRoomItemUpsertWithWhereUniqueWithoutTopicInput = {
  where: DataRoomItemWhereUniqueInput
  update: DataRoomItemUpdateWithoutTopicInput
  create: DataRoomItemCreateWithoutTopicInput
}
export type DataRoomItemUpdateWithoutTopicInput = {
  ordinal?: IntFieldUpdateOperationsInput
  label?: StringFieldUpdateOperationsInput
  url?: StringFieldUpdateOperationsInput
  type?: EnumUrlTypeFieldUpdateOperationsInput
}
export type EnumUrlTypeFieldUpdateOperationsInput = {
  set?: UrlType
}
export type DataRoomItemUpdateWithWhereUniqueWithoutTopicInput = {
  where: DataRoomItemWhereUniqueInput
  data: DataRoomItemUpdateWithoutTopicInput
}
export type DataRoomItemUpdateManyWithWhereWithoutTopicInput = {
  where: DataRoomItemScalarWhereInput
  data: DataRoomItemUpdateManyMutationInput
}
export type DataRoomItemScalarWhereInput = {
  AND?: DataRoomItemScalarWhereInput[]
  OR?: DataRoomItemScalarWhereInput[]
  NOT?: DataRoomItemScalarWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  label?: StringFilter
  url?: StringFilter
  type?: EnumUrlTypeFilter
  topic_id?: IntNullableFilter
}
export type DataRoomItemUpdateManyMutationInput = {
  ordinal?: IntFieldUpdateOperationsInput
  label?: StringFieldUpdateOperationsInput
  url?: StringFieldUpdateOperationsInput
  type?: EnumUrlTypeFieldUpdateOperationsInput
}
export type DataRoomTopicUpdateWithWhereUniqueWithoutFundInput = {
  where: DataRoomTopicWhereUniqueInput
  data: DataRoomTopicUpdateWithoutFundInput
}
export type DataRoomTopicUpdateManyWithWhereWithoutFundInput = {
  where: DataRoomTopicScalarWhereInput
  data: DataRoomTopicUpdateManyMutationInput
}
export type DataRoomTopicScalarWhereInput = {
  AND?: DataRoomTopicScalarWhereInput[]
  OR?: DataRoomTopicScalarWhereInput[]
  NOT?: DataRoomTopicScalarWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  topic_name?: StringFilter
  hbcu?: BoolFilter
  fund_id?: IntNullableFilter
}
export type DataRoomTopicUpdateManyMutationInput = {
  ordinal?: IntFieldUpdateOperationsInput
  topic_name?: StringFieldUpdateOperationsInput
  hbcu?: BoolFieldUpdateOperationsInput
}
export type CompanyUpdateManyWithoutIndustryInput = {
  create?: CompanyCreateWithoutIndustryInput[]
  connectOrCreate?: CompanyCreateOrConnectWithoutIndustryInput[]
  upsert?: CompanyUpsertWithWhereUniqueWithoutIndustryInput[]
  connect?: CompanyWhereUniqueInput[]
  set?: CompanyWhereUniqueInput[]
  disconnect?: CompanyWhereUniqueInput[]
  delete?: CompanyWhereUniqueInput[]
  update?: CompanyUpdateWithWhereUniqueWithoutIndustryInput[]
  updateMany?: CompanyUpdateManyWithWhereWithoutIndustryInput[]
  deleteMany?: CompanyScalarWhereInput[]
}
export type CompanyUpsertWithWhereUniqueWithoutIndustryInput = {
  where: CompanyWhereUniqueInput
  update: CompanyUpdateWithoutIndustryInput
  create: CompanyCreateWithoutIndustryInput
}
export type CompanyUpdateWithoutIndustryInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
}
export type CompanyUpdateWithWhereUniqueWithoutIndustryInput = {
  where: CompanyWhereUniqueInput
  data: CompanyUpdateWithoutIndustryInput
}
export type CompanyUpdateManyWithWhereWithoutIndustryInput = {
  where: CompanyScalarWhereInput
  data: CompanyUpdateManyMutationInput
}
export type CompanyScalarWhereInput = {
  AND?: CompanyScalarWhereInput[]
  OR?: CompanyScalarWhereInput[]
  NOT?: CompanyScalarWhereInput[]
  id?: IntFilter
  ordinal?: IntNullableFilter
  role?: StringNullableFilter
  fund_id?: IntNullableFilter
  name?: StringNullableFilter
  growth_category?: EnumGrowthNullableFilter
  gross_moic?: FloatNullableFilter
  initial_moic?: FloatNullableFilter
  show_initial_moic?: BoolNullableFilter
  total_fmv?: FloatNullableFilter
  base10_fmv?: FloatNullableFilter
  logo_url?: StringNullableFilter
  bw_logo_url?: StringNullableFilter
  about?: StringNullableFilter
  founded_date?: IntNullableFilter
  invested_date?: IntNullableFilter
  website?: StringNullableFilter
  headquarters?: StringNullableFilter
  founder_name?: StringNullableFilter
  founder_position?: StringNullableFilter
  founder_photo_url?: StringNullableFilter
  founder_quote?: StringNullableFilter
  founder_video_url?: StringNullableFilter
  governance_maturity_index?: FloatNullableFilter
  primary_trend_id?: IntNullableFilter
  primary_industry_id?: IntNullableFilter
  show_ownership?: BoolNullableFilter
  show_valuation?: BoolNullableFilter
}
export type CompanyUpdateManyMutationInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
}
export type FinalDilligenceCompanyUpdateWithWhereUniqueWithoutTrendInput = {
  where: FinalDilligenceCompanyWhereUniqueInput
  data: FinalDilligenceCompanyUpdateWithoutTrendInput
}
export type FinalDilligenceCompanyUpdateManyWithWhereWithoutTrendInput = {
  where: FinalDilligenceCompanyScalarWhereInput
  data: FinalDilligenceCompanyUpdateManyMutationInput
}
export type FinalDilligenceCompanyScalarWhereInput = {
  AND?: FinalDilligenceCompanyScalarWhereInput[]
  OR?: FinalDilligenceCompanyScalarWhereInput[]
  NOT?: FinalDilligenceCompanyScalarWhereInput[]
  id?: IntFilter
  industry_id?: IntNullableFilter
  name?: StringFilter
  url?: StringNullableFilter
  trend_id?: IntNullableFilter
}
export type FinalDilligenceCompanyUpdateManyMutationInput = {
  name?: StringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
}
export type TrendUpdateWithWhereUniqueWithoutFundInput = {
  where: TrendWhereUniqueInput
  data: TrendUpdateWithoutFundInput
}
export type TrendUpdateManyWithWhereWithoutFundInput = {
  where: TrendScalarWhereInput
  data: TrendUpdateManyMutationInput
}
export type TrendScalarWhereInput = {
  AND?: TrendScalarWhereInput[]
  OR?: TrendScalarWhereInput[]
  NOT?: TrendScalarWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  fund_id?: IntFilter
  name?: StringNullableFilter
  icon?: StringNullableFilter
  companies_identified_count?: IntNullableFilter
  initial_calls_count?: IntNullableFilter
  secondary_dilligence_count?: IntNullableFilter
  final_dilligence_count?: IntNullableFilter
  selectable?: BoolNullableFilter
}
export type TrendUpdateManyMutationInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
}
export type IndustryUpdateManyWithoutPortfolioCompaniesInput = {
  create?: IndustryCreateWithoutPortfolioCompaniesInput[]
  connectOrCreate?: IndustryCreateOrConnectWithoutPortfolioCompaniesInput[]
  upsert?: IndustryUpsertWithWhereUniqueWithoutPortfolioCompaniesInput[]
  connect?: IndustryWhereUniqueInput[]
  set?: IndustryWhereUniqueInput[]
  disconnect?: IndustryWhereUniqueInput[]
  delete?: IndustryWhereUniqueInput[]
  update?: IndustryUpdateWithWhereUniqueWithoutPortfolioCompaniesInput[]
  updateMany?: IndustryUpdateManyWithWhereWithoutPortfolioCompaniesInput[]
  deleteMany?: IndustryScalarWhereInput[]
}
export type IndustryUpsertWithWhereUniqueWithoutPortfolioCompaniesInput = {
  where: IndustryWhereUniqueInput
  update: IndustryUpdateWithoutPortfolioCompaniesInput
  create: IndustryCreateWithoutPortfolioCompaniesInput
}
export type IndustryUpdateWithoutPortfolioCompaniesInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneRequiredWithoutIndustriesInput
  final_dilligence_companies?: FinalDilligenceCompanyUpdateManyWithoutIndustryInput
}
export type IndustryUpdateWithWhereUniqueWithoutPortfolioCompaniesInput = {
  where: IndustryWhereUniqueInput
  data: IndustryUpdateWithoutPortfolioCompaniesInput
}
export type IndustryUpdateManyWithWhereWithoutPortfolioCompaniesInput = {
  where: IndustryScalarWhereInput
  data: IndustryUpdateManyMutationInput
}
export type IndustryScalarWhereInput = {
  AND?: IndustryScalarWhereInput[]
  OR?: IndustryScalarWhereInput[]
  NOT?: IndustryScalarWhereInput[]
  id?: IntFilter
  ordinal?: IntFilter
  fund_id?: IntFilter
  name?: StringNullableFilter
  icon?: StringNullableFilter
  companies_identified_count?: IntNullableFilter
  initial_calls_count?: IntNullableFilter
  secondary_dilligence_count?: IntNullableFilter
  final_dilligence_count?: IntNullableFilter
  selectable?: BoolNullableFilter
}
export type IndustryUpdateManyMutationInput = {
  ordinal?: IntFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  icon?: NullableStringFieldUpdateOperationsInput
  companies_identified_count?: NullableIntFieldUpdateOperationsInput
  initial_calls_count?: NullableIntFieldUpdateOperationsInput
  secondary_dilligence_count?: NullableIntFieldUpdateOperationsInput
  final_dilligence_count?: NullableIntFieldUpdateOperationsInput
  selectable?: NullableBoolFieldUpdateOperationsInput
}
export type CompanyUpdateWithWhereUniqueWithoutTrendInput = {
  where: CompanyWhereUniqueInput
  data: CompanyUpdateWithoutTrendInput
}
export type CompanyUpdateManyWithWhereWithoutTrendInput = {
  where: CompanyScalarWhereInput
  data: CompanyUpdateManyMutationInput
}
export type FinalDilligenceCompanyUpdateWithWhereUniqueWithoutIndustryInput = {
  where: FinalDilligenceCompanyWhereUniqueInput
  data: FinalDilligenceCompanyUpdateWithoutIndustryInput
}
export type FinalDilligenceCompanyUpdateManyWithWhereWithoutIndustryInput = {
  where: FinalDilligenceCompanyScalarWhereInput
  data: FinalDilligenceCompanyUpdateManyMutationInput
}
export type IndustryUpdateWithWhereUniqueWithoutFundInput = {
  where: IndustryWhereUniqueInput
  data: IndustryUpdateWithoutFundInput
}
export type IndustryUpdateManyWithWhereWithoutFundInput = {
  where: IndustryScalarWhereInput
  data: IndustryUpdateManyMutationInput
}
export type TrendUpdateWithWhereUniqueWithoutPortfolioCompaniesInput = {
  where: TrendWhereUniqueInput
  data: TrendUpdateWithoutPortfolioCompaniesInput
}
export type TrendUpdateManyWithWhereWithoutPortfolioCompaniesInput = {
  where: TrendScalarWhereInput
  data: TrendUpdateManyMutationInput
}
export type CompanyUpdateWithWhereUniqueWithoutFundInput = {
  where: CompanyWhereUniqueInput
  data: CompanyUpdateWithoutFundInput
}
export type CompanyUpdateManyWithWhereWithoutFundInput = {
  where: CompanyScalarWhereInput
  data: CompanyUpdateManyMutationInput
}
export type CompanyCreateInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type IndustryCreateInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  fund: FundCreateNestedOneWithoutIndustriesInput
  final_dilligence_companies?: FinalDilligenceCompanyCreateNestedManyWithoutIndustryInput
  portfolio_companies?: CompanyCreateNestedManyWithoutIndustryInput
}
export type TrendCreateInput = {
  ordinal: number
  name?: string
  icon?: string
  companies_identified_count?: number
  initial_calls_count?: number
  secondary_dilligence_count?: number
  final_dilligence_count?: number
  selectable?: boolean
  fund: FundCreateNestedOneWithoutTrendInput
  final_dilligence_companies?: FinalDilligenceCompanyCreateNestedManyWithoutTrendInput
  portfolio_companies?: CompanyCreateNestedManyWithoutTrendInput
}
export type CompanyUpdateInput = {
  id?: number
  image_url?: string
  title?: string
  url?: string
  about?: string
  base10_fmv?: number
  bw_logo_url?: string
  founded_date?: number
  founder_name?: string
  founder_photo_url?: string
  founder_position?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  gross_moic?: number
  headquarters?: string
  initial_moic?: number
  invested_date?: number
  logo_url?: string
  name?: string
  ordinal?: number
  primary_industry_id?: number
  primary_trend_id?: number
  role?: string
  show_initial_moic?: boolean
  show_ownership?: boolean
  show_valuation?: boolean
  total_fmv?: number
  website?: string
}
export type CoinvestorUpdateInput = {
  name?: NullableStringFieldUpdateOperationsInput
  company?: CompanyUpdateOneWithoutCoinvestorsInput
}
export type CompanyUpdateOneWithoutCoinvestorsInput = {
  create?: CompanyCreateWithoutCoinvestorsInput
  connectOrCreate?: CompanyCreateOrConnectWithoutCoinvestorsInput
  upsert?: CompanyUpsertWithoutCoinvestorsInput
  connect?: CompanyWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: CompanyUpdateWithoutCoinvestorsInput
}
export type CompanyCreateWithoutCoinvestorsInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutCoinvestorsInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutCoinvestorsInput
}
export type CompanyUpsertWithoutCoinvestorsInput = {
  update: CompanyUpdateWithoutCoinvestorsInput
  create: CompanyCreateWithoutCoinvestorsInput
}
export type CompanyUpdateWithoutCoinvestorsInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type CompanyMemberUpdateInput = {
  name?: StringFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
  company?: CompanyUpdateOneWithoutKeyTeamMembersInput
}
export type CompanyUpdateOneWithoutKeyTeamMembersInput = {
  create?: CompanyCreateWithoutKeyTeamMembersInput
  connectOrCreate?: CompanyCreateOrConnectWithoutKeyTeamMembersInput
  upsert?: CompanyUpsertWithoutKeyTeamMembersInput
  connect?: CompanyWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: CompanyUpdateWithoutKeyTeamMembersInput
}
export type CompanyCreateWithoutKeyTeamMembersInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutKeyTeamMembersInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutKeyTeamMembersInput
}
export type CompanyUpsertWithoutKeyTeamMembersInput = {
  update: CompanyUpdateWithoutKeyTeamMembersInput
  create: CompanyCreateWithoutKeyTeamMembersInput
}
export type CompanyUpdateWithoutKeyTeamMembersInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type CompanyNewUpdateInput = {
  title?: StringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
  company?: CompanyUpdateOneWithoutRelatedNewsInput
}
export type CompanyUpdateOneWithoutRelatedNewsInput = {
  create?: CompanyCreateWithoutRelatedNewsInput
  connectOrCreate?: CompanyCreateOrConnectWithoutRelatedNewsInput
  upsert?: CompanyUpsertWithoutRelatedNewsInput
  connect?: CompanyWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: CompanyUpdateWithoutRelatedNewsInput
}
export type CompanyCreateWithoutRelatedNewsInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutRelatedNewsInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutRelatedNewsInput
}
export type CompanyUpsertWithoutRelatedNewsInput = {
  update: CompanyUpdateWithoutRelatedNewsInput
  create: CompanyCreateWithoutRelatedNewsInput
}
export type CompanyUpdateWithoutRelatedNewsInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type CompanyVideoUpdateInput = {
  title?: NullableStringFieldUpdateOperationsInput
  url?: StringFieldUpdateOperationsInput
  image_url?: NullableStringFieldUpdateOperationsInput
  company?: CompanyUpdateOneWithoutRelatedVideosInput
}
export type CompanyUpdateOneWithoutRelatedVideosInput = {
  create?: CompanyCreateWithoutRelatedVideosInput
  connectOrCreate?: CompanyCreateOrConnectWithoutRelatedVideosInput
  upsert?: CompanyUpsertWithoutRelatedVideosInput
  connect?: CompanyWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: CompanyUpdateWithoutRelatedVideosInput
}
export type CompanyCreateWithoutRelatedVideosInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutRelatedVideosInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutRelatedVideosInput
}
export type CompanyUpsertWithoutRelatedVideosInput = {
  update: CompanyUpdateWithoutRelatedVideosInput
  create: CompanyCreateWithoutRelatedVideosInput
}
export type CompanyUpdateWithoutRelatedVideosInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type CompetitorUpdateInput = {
  name?: StringFieldUpdateOperationsInput
  company?: CompanyUpdateOneWithoutCompetitorsInput
}
export type CompanyUpdateOneWithoutCompetitorsInput = {
  create?: CompanyCreateWithoutCompetitorsInput
  connectOrCreate?: CompanyCreateOrConnectWithoutCompetitorsInput
  upsert?: CompanyUpsertWithoutCompetitorsInput
  connect?: CompanyWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: CompanyUpdateWithoutCompetitorsInput
}
export type CompanyCreateWithoutCompetitorsInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  funding_rounds?: FundingRoundCreateNestedManyWithoutCompanyInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutCompetitorsInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutCompetitorsInput
}
export type CompanyUpsertWithoutCompetitorsInput = {
  update: CompanyUpdateWithoutCompetitorsInput
  create: CompanyCreateWithoutCompetitorsInput
}
export type CompanyUpdateWithoutCompetitorsInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  funding_rounds?: FundingRoundUpdateManyWithoutCompanyInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type FinalDilligenceCompanyUpdateInput = {
  id?: number
  name?: string
  url?: string
}
export type FundingRoundUpdateInput = {
  base10_invest?: number
  footnote?: string
  funding_date?: any
  id?: number
  ownership?: number
  round_number?: number
  stage?: string
  total_investment?: number
  valuation?: number
}
export type FundingRoundCoinvestorUpdateInput = {
  name?: NullableStringFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
  funding_round?: FundingRoundUpdateOneWithoutCoinvestorsInput
}
export type FundingRoundUpdateOneWithoutCoinvestorsInput = {
  create?: FundingRoundCreateWithoutCoinvestorsInput
  connectOrCreate?: FundingRoundCreateOrConnectWithoutCoinvestorsInput
  upsert?: FundingRoundUpsertWithoutCoinvestorsInput
  connect?: FundingRoundWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: FundingRoundUpdateWithoutCoinvestorsInput
}
export type FundingRoundCreateWithoutCoinvestorsInput = {
  round_number?: number
  stage?: string
  funding_date?: any
  total_investment?: number
  base10_invest?: number
  valuation?: number
  ownership?: number
  footnote?: string
  company?: CompanyCreateNestedOneWithoutFundingRoundsInput
}
export type CompanyCreateNestedOneWithoutFundingRoundsInput = {
  create?: CompanyCreateWithoutFundingRoundsInput
  connectOrCreate?: CompanyCreateOrConnectWithoutFundingRoundsInput
  connect?: CompanyWhereUniqueInput
}
export type CompanyCreateWithoutFundingRoundsInput = {
  ordinal?: number
  role?: string
  name?: string
  growth_category?: Growth
  gross_moic?: number
  initial_moic?: number
  show_initial_moic?: boolean
  total_fmv?: number
  base10_fmv?: number
  logo_url?: string
  bw_logo_url?: string
  about?: string
  founded_date?: number
  invested_date?: number
  website?: string
  headquarters?: string
  founder_name?: string
  founder_position?: string
  founder_photo_url?: string
  founder_quote?: string
  founder_video_url?: string
  governance_maturity_index?: number
  primary_trend_id?: number
  primary_industry_id?: number
  show_ownership?: boolean
  show_valuation?: boolean
  fund?: FundCreateNestedOneWithoutCompaniesInput
  coinvestors?: CoinvestorCreateNestedManyWithoutCompanyInput
  competitors?: CompetitorCreateNestedManyWithoutCompanyInput
  key_team_members?: CompanyMemberCreateNestedManyWithoutCompanyInput
  related_videos?: CompanyVideoCreateNestedManyWithoutCompanyInput
  related_news?: CompanyNewCreateNestedManyWithoutCompanyInput
  trend?: TrendCreateNestedManyWithoutPortfolioCompaniesInput
  industry?: IndustryCreateNestedManyWithoutPortfolioCompaniesInput
}
export type CompanyCreateOrConnectWithoutFundingRoundsInput = {
  where: CompanyWhereUniqueInput
  create: CompanyCreateWithoutFundingRoundsInput
}
export type FundingRoundCreateOrConnectWithoutCoinvestorsInput = {
  where: FundingRoundWhereUniqueInput
  create: FundingRoundCreateWithoutCoinvestorsInput
}
export type FundingRoundUpsertWithoutCoinvestorsInput = {
  update: FundingRoundUpdateWithoutCoinvestorsInput
  create: FundingRoundCreateWithoutCoinvestorsInput
}
export type FundingRoundUpdateWithoutCoinvestorsInput = {
  round_number?: NullableIntFieldUpdateOperationsInput
  stage?: NullableStringFieldUpdateOperationsInput
  funding_date?: NullableDateTimeFieldUpdateOperationsInput
  total_investment?: NullableFloatFieldUpdateOperationsInput
  base10_invest?: NullableFloatFieldUpdateOperationsInput
  valuation?: NullableFloatFieldUpdateOperationsInput
  ownership?: NullableFloatFieldUpdateOperationsInput
  footnote?: NullableStringFieldUpdateOperationsInput
  company?: CompanyUpdateOneWithoutFundingRoundsInput
}
export type CompanyUpdateOneWithoutFundingRoundsInput = {
  create?: CompanyCreateWithoutFundingRoundsInput
  connectOrCreate?: CompanyCreateOrConnectWithoutFundingRoundsInput
  upsert?: CompanyUpsertWithoutFundingRoundsInput
  connect?: CompanyWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: CompanyUpdateWithoutFundingRoundsInput
}
export type CompanyUpsertWithoutFundingRoundsInput = {
  update: CompanyUpdateWithoutFundingRoundsInput
  create: CompanyCreateWithoutFundingRoundsInput
}
export type CompanyUpdateWithoutFundingRoundsInput = {
  ordinal?: NullableIntFieldUpdateOperationsInput
  role?: NullableStringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  growth_category?: NullableEnumGrowthFieldUpdateOperationsInput
  gross_moic?: NullableFloatFieldUpdateOperationsInput
  initial_moic?: NullableFloatFieldUpdateOperationsInput
  show_initial_moic?: NullableBoolFieldUpdateOperationsInput
  total_fmv?: NullableFloatFieldUpdateOperationsInput
  base10_fmv?: NullableFloatFieldUpdateOperationsInput
  logo_url?: NullableStringFieldUpdateOperationsInput
  bw_logo_url?: NullableStringFieldUpdateOperationsInput
  about?: NullableStringFieldUpdateOperationsInput
  founded_date?: NullableIntFieldUpdateOperationsInput
  invested_date?: NullableIntFieldUpdateOperationsInput
  website?: NullableStringFieldUpdateOperationsInput
  headquarters?: NullableStringFieldUpdateOperationsInput
  founder_name?: NullableStringFieldUpdateOperationsInput
  founder_position?: NullableStringFieldUpdateOperationsInput
  founder_photo_url?: NullableStringFieldUpdateOperationsInput
  founder_quote?: NullableStringFieldUpdateOperationsInput
  founder_video_url?: NullableStringFieldUpdateOperationsInput
  governance_maturity_index?: NullableFloatFieldUpdateOperationsInput
  primary_trend_id?: NullableIntFieldUpdateOperationsInput
  primary_industry_id?: NullableIntFieldUpdateOperationsInput
  show_ownership?: NullableBoolFieldUpdateOperationsInput
  show_valuation?: NullableBoolFieldUpdateOperationsInput
  fund?: FundUpdateOneWithoutCompaniesInput
  coinvestors?: CoinvestorUpdateManyWithoutCompanyInput
  competitors?: CompetitorUpdateManyWithoutCompanyInput
  key_team_members?: CompanyMemberUpdateManyWithoutCompanyInput
  related_videos?: CompanyVideoUpdateManyWithoutCompanyInput
  related_news?: CompanyNewUpdateManyWithoutCompanyInput
  trend?: TrendUpdateManyWithoutPortfolioCompaniesInput
  industry?: IndustryUpdateManyWithoutPortfolioCompaniesInput
}
export type RealEconomySectorUpdateInput = {
  name?: StringFieldUpdateOperationsInput
  selectable?: BoolFieldUpdateOperationsInput
}
export type IndustryUpdateInput = {
  companies_identified_count?: number
  final_dilligence_count?: number
  icon?: string
  id?: number
  initial_calls_count?: number
  name?: string
  ordinal?: number
  secondary_dilligence_count?: number
  selectable?: boolean
}
export type TrendUpdateInput = {
  companies_identified_count?: number
  final_dilligence_count?: number
  icon?: string
  id?: number
  initial_calls_count?: number
  name?: string
  ordinal?: number
  secondary_dilligence_count?: number
  selectable?: boolean
}
export type DataRoomItemCreateInput = {
  ordinal?: number
  label: string
  url: string
  type?: UrlType
  topic?: DataRoomTopicCreateNestedOneWithoutItemsInput
}
export type DataRoomTopicCreateNestedOneWithoutItemsInput = {
  create?: DataRoomTopicCreateWithoutItemsInput
  connectOrCreate?: DataRoomTopicCreateOrConnectWithoutItemsInput
  connect?: DataRoomTopicWhereUniqueInput
}
export type DataRoomTopicCreateWithoutItemsInput = {
  ordinal?: number
  topic_name: string
  hbcu?: boolean
  fund?: FundCreateNestedOneWithoutDataroomTopicsInput
}
export type FundCreateNestedOneWithoutDataroomTopicsInput = {
  create?: FundCreateWithoutDataroomTopicsInput
  connectOrCreate?: FundCreateOrConnectWithoutDataroomTopicsInput
  connect?: FundWhereUniqueInput
}
export type FundCreateWithoutDataroomTopicsInput = {
  name: string
  capital_deployed_perc_deployed_and_reserved?: number
  capital_deployed_perc_unallocated?: number
  moic?: number
  tvpi?: number
  gross_moic_on_initial_checks?: number
  moic_excl_6_months?: number
  capital_deployed?: number
  capital_deployed_change?: number
  fmv?: number
  fmv_change?: number
  portfolio_companies?: number
  new_portfolio_companies?: number
  months_since_first_close?: number
  show_moic?: boolean
  show_tvpi?: boolean
  show_gross_moic_on_initial_checks?: boolean
  show_moic_excl_6_months?: boolean
  show_capital_deployed?: boolean
  show_capital_deployed_change?: boolean
  show_fmv?: boolean
  show_fmv_change?: boolean
  show_portfolio_companies?: boolean
  show_new_portfolio_companies?: boolean
  show_months_since_first_close?: boolean
  last_update?: any
  seed_primary?: number
  seed_follow_on?: number
  series_a_primary?: number
  series_a_follow_on?: number
  series_b_primary?: number
  series_b_follow_on?: number
  reserved_but_not_deployed?: number
  unallocated?: number
  graph_early_growth?: number
  graph_growth?: number
  graph_late_growth?: number
  total_investable_capital?: number
  captial_deployed_footnote?: string
  fund_overview_footnote?: string
  capital_deployed_by_stage_footnote?: string
  capital_deployed_by_industry_footnote?: string
  advancement_initiative_portfolio_revenue_growth_footnote?: string
  portfolio_footnote?: string
  fmv_contribution_by_company_footnote?: string
  research_process_pipeline_footnote?: string
  capital_deployed_by_quarter_footnote?: string
  footnotes_and_disclosesures?: string
  companies?: CompanyCreateNestedManyWithoutFundInput
  industries?: IndustryCreateNestedManyWithoutFundInput
  trend?: TrendCreateNestedManyWithoutFundInput
  investment_by_stages?: StageCreateNestedManyWithoutFundInput
}
export type FundCreateOrConnectWithoutDataroomTopicsInput = {
  where: FundWhereUniqueInput
  create: FundCreateWithoutDataroomTopicsInput
}
export type DataRoomTopicCreateOrConnectWithoutItemsInput = {
  where: DataRoomTopicWhereUniqueInput
  create: DataRoomTopicCreateWithoutItemsInput
}
export type DataRoomTopicCreateInput = {
  ordinal?: number
  topic_name: string
  hbcu?: boolean
  items?: DataRoomItemCreateNestedManyWithoutTopicInput
  fund?: FundCreateNestedOneWithoutDataroomTopicsInput
}
export type DataRoomItemUpdateInput = {
  id?: number
  label?: string
  ordinal?: number
  type?: UrlType
  url?: string
}
export type DataRoomTopicUpdateInput = {
  ordinal?: IntFieldUpdateOperationsInput
  topic_name?: StringFieldUpdateOperationsInput
  hbcu?: BoolFieldUpdateOperationsInput
  items?: DataRoomItemUpdateManyWithoutTopicInput
  fund?: FundUpdateOneWithoutDataroomTopicsInput
}
export type FundUpdateOneWithoutDataroomTopicsInput = {
  create?: FundCreateWithoutDataroomTopicsInput
  connectOrCreate?: FundCreateOrConnectWithoutDataroomTopicsInput
  upsert?: FundUpsertWithoutDataroomTopicsInput
  connect?: FundWhereUniqueInput
  disconnect?: boolean
  delete?: boolean
  update?: FundUpdateWithoutDataroomTopicsInput
}
export type FundUpsertWithoutDataroomTopicsInput = {
  update: FundUpdateWithoutDataroomTopicsInput
  create: FundCreateWithoutDataroomTopicsInput
}
export type FundUpdateWithoutDataroomTopicsInput = {
  name?: StringFieldUpdateOperationsInput
  capital_deployed_perc_deployed_and_reserved?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_perc_unallocated?: NullableFloatFieldUpdateOperationsInput
  moic?: NullableFloatFieldUpdateOperationsInput
  tvpi?: NullableFloatFieldUpdateOperationsInput
  gross_moic_on_initial_checks?: NullableFloatFieldUpdateOperationsInput
  moic_excl_6_months?: NullableFloatFieldUpdateOperationsInput
  capital_deployed?: NullableFloatFieldUpdateOperationsInput
  capital_deployed_change?: NullableFloatFieldUpdateOperationsInput
  fmv?: NullableFloatFieldUpdateOperationsInput
  fmv_change?: NullableFloatFieldUpdateOperationsInput
  portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  new_portfolio_companies?: NullableFloatFieldUpdateOperationsInput
  months_since_first_close?: NullableFloatFieldUpdateOperationsInput
  show_moic?: NullableBoolFieldUpdateOperationsInput
  show_tvpi?: NullableBoolFieldUpdateOperationsInput
  show_gross_moic_on_initial_checks?: NullableBoolFieldUpdateOperationsInput
  show_moic_excl_6_months?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed?: NullableBoolFieldUpdateOperationsInput
  show_capital_deployed_change?: NullableBoolFieldUpdateOperationsInput
  show_fmv?: NullableBoolFieldUpdateOperationsInput
  show_fmv_change?: NullableBoolFieldUpdateOperationsInput
  show_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_new_portfolio_companies?: NullableBoolFieldUpdateOperationsInput
  show_months_since_first_close?: NullableBoolFieldUpdateOperationsInput
  last_update?: NullableDateTimeFieldUpdateOperationsInput
  seed_primary?: NullableFloatFieldUpdateOperationsInput
  seed_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_a_primary?: NullableFloatFieldUpdateOperationsInput
  series_a_follow_on?: NullableFloatFieldUpdateOperationsInput
  series_b_primary?: NullableFloatFieldUpdateOperationsInput
  series_b_follow_on?: NullableFloatFieldUpdateOperationsInput
  reserved_but_not_deployed?: NullableFloatFieldUpdateOperationsInput
  unallocated?: NullableFloatFieldUpdateOperationsInput
  graph_early_growth?: NullableFloatFieldUpdateOperationsInput
  graph_growth?: NullableFloatFieldUpdateOperationsInput
  graph_late_growth?: NullableFloatFieldUpdateOperationsInput
  total_investable_capital?: NullableFloatFieldUpdateOperationsInput
  captial_deployed_footnote?: NullableStringFieldUpdateOperationsInput
  fund_overview_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_stage_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_industry_footnote?: NullableStringFieldUpdateOperationsInput
  advancement_initiative_portfolio_revenue_growth_footnote?: NullableStringFieldUpdateOperationsInput
  portfolio_footnote?: NullableStringFieldUpdateOperationsInput
  fmv_contribution_by_company_footnote?: NullableStringFieldUpdateOperationsInput
  research_process_pipeline_footnote?: NullableStringFieldUpdateOperationsInput
  capital_deployed_by_quarter_footnote?: NullableStringFieldUpdateOperationsInput
  footnotes_and_disclosesures?: NullableStringFieldUpdateOperationsInput
  companies?: CompanyUpdateManyWithoutFundInput
  industries?: IndustryUpdateManyWithoutFundInput
  trend?: TrendUpdateManyWithoutFundInput
  investment_by_stages?: StageUpdateManyWithoutFundInput
}
export type UserUpdateManyMutationInput = {
  email?: StringFieldUpdateOperationsInput
  username?: StringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  password?: StringFieldUpdateOperationsInput
  initial_password?: NullableStringFieldUpdateOperationsInput
  session_token?: StringFieldUpdateOperationsInput
  hbcu_data_room_access?: NullableBoolFieldUpdateOperationsInput
  role?: EnumRoleFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
  updated_at?: NullableDateTimeFieldUpdateOperationsInput
  created_date?: NullableDateTimeFieldUpdateOperationsInput
  accessible_funds?: UserUpdateaccessibleFundsInput
  accessible_letters?: UserUpdateaccessibleLettersInput
}
export type EnumRoleFieldUpdateOperationsInput = {
  set?: Role
}
export type UserUpdateaccessibleFundsInput = {
  set?: number[]
  push?: number
}
export type UserUpdateaccessibleLettersInput = {
  set?: string[]
  push?: string
}
export type UserWhereInput = {
  AND?: UserWhereInput[]
  OR?: UserWhereInput[]
  NOT?: UserWhereInput[]
  id?: IntFilter
  email?: StringFilter
  username?: StringFilter
  name?: StringNullableFilter
  password?: StringFilter
  initial_password?: StringNullableFilter
  session_token?: StringFilter
  accessible_funds?: IntNullableListFilter
  accessible_letters?: StringNullableListFilter
  hbcu_data_room_access?: BoolNullableFilter
  role?: EnumRoleFilter
  url?: StringNullableFilter
  user_sessions?: UserSessionListRelationFilter
  updated_at?: DateTimeNullableFilter
  created_date?: DateTimeNullableFilter
}
export type IntNullableListFilter = {
  equals?: number[]
  has?: number
  hasEvery?: number[]
  hasSome?: number[]
  isEmpty?: boolean
}
export type StringNullableListFilter = {
  equals?: string[]
  has?: string
  hasEvery?: string[]
  hasSome?: string[]
  isEmpty?: boolean
}
export type UserSessionListRelationFilter = {
  every?: UserSessionWhereInput
  some?: UserSessionWhereInput
  none?: UserSessionWhereInput
}
export type UserSessionWhereInput = {
  AND?: UserSessionWhereInput[]
  OR?: UserSessionWhereInput[]
  NOT?: UserSessionWhereInput[]
  id?: IntFilter
  user_id?: IntNullableFilter
  user?: UserWhereInput
  created_date?: DateTimeNullableFilter
  updated_at?: DateTimeNullableFilter
  time_spent_on_page?: IntNullableFilter
}
export type UserUpdateInput = {
  email?: StringFieldUpdateOperationsInput
  username?: StringFieldUpdateOperationsInput
  name?: NullableStringFieldUpdateOperationsInput
  password?: StringFieldUpdateOperationsInput
  initial_password?: NullableStringFieldUpdateOperationsInput
  session_token?: StringFieldUpdateOperationsInput
  hbcu_data_room_access?: NullableBoolFieldUpdateOperationsInput
  role?: EnumRoleFieldUpdateOperationsInput
  url?: NullableStringFieldUpdateOperationsInput
  updated_at?: NullableDateTimeFieldUpdateOperationsInput
  created_date?: NullableDateTimeFieldUpdateOperationsInput
  accessible_funds?: UserUpdateaccessibleFundsInput
  accessible_letters?: UserUpdateaccessibleLettersInput
  user_sessions?: UserSessionUpdateManyWithoutUserInput
}
export type UserSessionUpdateManyWithoutUserInput = {
  create?: UserSessionCreateWithoutUserInput[]
  connectOrCreate?: UserSessionCreateOrConnectWithoutUserInput[]
  upsert?: UserSessionUpsertWithWhereUniqueWithoutUserInput[]
  createMany?: UserSessionCreateManyUserInputEnvelope
  connect?: UserSessionWhereUniqueInput[]
  set?: UserSessionWhereUniqueInput[]
  disconnect?: UserSessionWhereUniqueInput[]
  delete?: UserSessionWhereUniqueInput[]
  update?: UserSessionUpdateWithWhereUniqueWithoutUserInput[]
  updateMany?: UserSessionUpdateManyWithWhereWithoutUserInput[]
  deleteMany?: UserSessionScalarWhereInput[]
}
export type UserSessionCreateWithoutUserInput = {
  created_date?: any
  updated_at?: any
  time_spent_on_page?: number
}
export type UserSessionCreateOrConnectWithoutUserInput = {
  where: UserSessionWhereUniqueInput
  create: UserSessionCreateWithoutUserInput
}
export type UserSessionUpsertWithWhereUniqueWithoutUserInput = {
  where: UserSessionWhereUniqueInput
  update: UserSessionUpdateWithoutUserInput
  create: UserSessionCreateWithoutUserInput
}
export type UserSessionUpdateWithoutUserInput = {
  created_date?: NullableDateTimeFieldUpdateOperationsInput
  updated_at?: NullableDateTimeFieldUpdateOperationsInput
  time_spent_on_page?: NullableIntFieldUpdateOperationsInput
}
export type UserSessionCreateManyUserInputEnvelope = {
  data?: UserSessionCreateManyUserInput[]
  skipDuplicates?: boolean
}
export type UserSessionCreateManyUserInput = {
  id?: number
  created_date?: any
  updated_at?: any
  time_spent_on_page?: number
}
export type UserSessionUpdateWithWhereUniqueWithoutUserInput = {
  where: UserSessionWhereUniqueInput
  data: UserSessionUpdateWithoutUserInput
}
export type UserSessionUpdateManyWithWhereWithoutUserInput = {
  where: UserSessionScalarWhereInput
  data: UserSessionUpdateManyMutationInput
}
export type UserSessionScalarWhereInput = {
  AND?: UserSessionScalarWhereInput[]
  OR?: UserSessionScalarWhereInput[]
  NOT?: UserSessionScalarWhereInput[]
  id?: IntFilter
  user_id?: IntNullableFilter
  created_date?: DateTimeNullableFilter
  updated_at?: DateTimeNullableFilter
  time_spent_on_page?: IntNullableFilter
}
export type UserSessionUpdateManyMutationInput = {
  created_date?: NullableDateTimeFieldUpdateOperationsInput
  updated_at?: NullableDateTimeFieldUpdateOperationsInput
  time_spent_on_page?: NullableIntFieldUpdateOperationsInput
}
export type UserCreateInput = {
  email: string
  username: string
  name?: string
  password: string
  initial_password?: string
  session_token: string
  hbcu_data_room_access?: boolean
  role?: Role
  url?: string
  updated_at?: any
  created_date?: any
  accessible_funds?: UserCreateaccessibleFundsInput
  accessible_letters?: UserCreateaccessibleLettersInput
  user_sessions?: UserSessionCreateNestedManyWithoutUserInput
}
export type UserCreateaccessibleFundsInput = {
  set?: number[]
}
export type UserCreateaccessibleLettersInput = {
  set?: string[]
}
export type UserSessionCreateNestedManyWithoutUserInput = {
  create?: UserSessionCreateWithoutUserInput[]
  connectOrCreate?: UserSessionCreateOrConnectWithoutUserInput[]
  createMany?: UserSessionCreateManyUserInputEnvelope
  connect?: UserSessionWhereUniqueInput[]
}
export type MarketingEmailCreateInput = {
  email: string
  source: string
  created_date?: any
}
export type UserSessionCreateInput = {
  created_date?: any
  updated_at?: any
  time_spent_on_page?: number
  user?: UserCreateNestedOneWithoutUserSessionsInput
}
export type UserCreateNestedOneWithoutUserSessionsInput = {
  create?: UserCreateWithoutUserSessionsInput
  connectOrCreate?: UserCreateOrConnectWithoutUserSessionsInput
  connect?: UserWhereUniqueInput
}
export type UserCreateWithoutUserSessionsInput = {
  email: string
  username: string
  name?: string
  password: string
  initial_password?: string
  session_token: string
  hbcu_data_room_access?: boolean
  role?: Role
  url?: string
  updated_at?: any
  created_date?: any
  accessible_funds?: UserCreateaccessibleFundsInput
  accessible_letters?: UserCreateaccessibleLettersInput
}
export type UserCreateOrConnectWithoutUserSessionsInput = {
  where: UserWhereUniqueInput
  create: UserCreateWithoutUserSessionsInput
}
export type CompanyChildInput = {
  coinvestors?: CoinvestorUpdateWithoutCompanyDataInput[]
  competitors?: CompetitorUpdateWithoutCompanyDataInput[]
  key_team_members?: CompanyMemberUpdateWithoutCompanyDataInput[]
  related_new?: CompanyNewUpdateWithoutCompanyDataInput[]
  related_videos?: CompanyVideoUpdateWithoutCompanyDataInput[]
  company?: CompanyUpdateInput
  trends?: number[]
  industries?: number[]
}
export type CoinvestorUpdateWithoutCompanyDataInput = {
  id?: number
  name?: string
}
export type CompetitorUpdateWithoutCompanyDataInput = {
  id?: number
  name?: string
}
export type CompanyMemberUpdateWithoutCompanyDataInput = {
  id?: number
  name?: string
  role?: string
  url?: string
}
export type CompanyNewUpdateWithoutCompanyDataInput = {
  id?: number
  title?: string
  url?: string
}
export type CompanyVideoUpdateWithoutCompanyDataInput = {
  id?: number
  image_url?: string
  title?: string
  url?: string
}
export type IndustryInput = {
  industry?: IndustryUpdateInput
  final_dilligence_companies?: FinalDilligenceCompanyUpdateInput[]
  portfolio_companies?: number[]
}
export type TrendInput = {
  trend?: TrendUpdateInput
  final_dilligence_companies?: FinalDilligenceCompanyUpdateInput[]
  portfolio_companies?: number[]
}
export type StageInput = {
  stages?: StageUpdateInput[]
}
export type StageUpdateInput = {
  base10_investment?: number
  id?: number
  name?: string
}
export type RoundInput = {
  round?: FundingRoundUpdateInput
  coinvestors?: FundingRoundCoinvestorUpdateWithoutFundingRoundDataInput[]
}
export type FundingRoundCoinvestorUpdateWithoutFundingRoundDataInput = {
  id?: number
  name?: string
  url?: string
}
export type RegisterInput = {
  email: string
  username: string
  password?: string
  accessible_funds?: number[]
  accessible_letters?: string[]
  role?: Role
  hbcu_data_room_access?: boolean
}
export type LoginInput = {
  email?: string
  username?: string
  password: string
  token?: string
}
export type StageCreateInput = {
  name: string
  base10_investment?: number
  fund?: FundCreateNestedOneWithoutInvestmentByStagesInput
}
export type FundCreateNestedOneWithoutInvestmentByStagesInput = {
  create?: FundCreateWithoutInvestmentByStagesInput
  connectOrCreate?: FundCreateOrConnectWithoutInvestmentByStagesInput
  connect?: FundWhereUniqueInput
}
export type FundCreateWithoutInvestmentByStagesInput = {
  name: string
  capital_deployed_perc_deployed_and_reserved?: number
  capital_deployed_perc_unallocated?: number
  moic?: number
  tvpi?: number
  gross_moic_on_initial_checks?: number
  moic_excl_6_months?: number
  capital_deployed?: number
  capital_deployed_change?: number
  fmv?: number
  fmv_change?: number
  portfolio_companies?: number
  new_portfolio_companies?: number
  months_since_first_close?: number
  show_moic?: boolean
  show_tvpi?: boolean
  show_gross_moic_on_initial_checks?: boolean
  show_moic_excl_6_months?: boolean
  show_capital_deployed?: boolean
  show_capital_deployed_change?: boolean
  show_fmv?: boolean
  show_fmv_change?: boolean
  show_portfolio_companies?: boolean
  show_new_portfolio_companies?: boolean
  show_months_since_first_close?: boolean
  last_update?: any
  seed_primary?: number
  seed_follow_on?: number
  series_a_primary?: number
  series_a_follow_on?: number
  series_b_primary?: number
  series_b_follow_on?: number
  reserved_but_not_deployed?: number
  unallocated?: number
  graph_early_growth?: number
  graph_growth?: number
  graph_late_growth?: number
  total_investable_capital?: number
  captial_deployed_footnote?: string
  fund_overview_footnote?: string
  capital_deployed_by_stage_footnote?: string
  capital_deployed_by_industry_footnote?: string
  advancement_initiative_portfolio_revenue_growth_footnote?: string
  portfolio_footnote?: string
  fmv_contribution_by_company_footnote?: string
  research_process_pipeline_footnote?: string
  capital_deployed_by_quarter_footnote?: string
  footnotes_and_disclosesures?: string
  companies?: CompanyCreateNestedManyWithoutFundInput
  industries?: IndustryCreateNestedManyWithoutFundInput
  trend?: TrendCreateNestedManyWithoutFundInput
  dataroom_topics?: DataRoomTopicCreateNestedManyWithoutFundInput
}
export type FundCreateOrConnectWithoutInvestmentByStagesInput = {
  where: FundWhereUniqueInput
  create: FundCreateWithoutInvestmentByStagesInput
}
export type FundingRoundCoinvestorCreateInput = {
  name?: string
  url?: string
  funding_round?: FundingRoundCreateNestedOneWithoutCoinvestorsInput
}
export type FundingRoundCreateNestedOneWithoutCoinvestorsInput = {
  create?: FundingRoundCreateWithoutCoinvestorsInput
  connectOrCreate?: FundingRoundCreateOrConnectWithoutCoinvestorsInput
  connect?: FundingRoundWhereUniqueInput
}
export type FundingRoundCreateInput = {
  round_number?: number
  stage?: string
  funding_date?: any
  total_investment?: number
  base10_invest?: number
  valuation?: number
  ownership?: number
  footnote?: string
  coinvestors?: FundingRoundCoinvestorCreateNestedManyWithoutFundingRoundInput
  company?: CompanyCreateNestedOneWithoutFundingRoundsInput
}
export type DataRoomTopicUpdateWithoutItemsDataInput = {
  hbcu?: boolean
  id?: number
  ordinal?: number
  topic_name?: string
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {

}


/**
* Enums for the names of base graphql actions
*/
export enum RootStoreBaseQueries {
queryFund="queryFund",
queryFunds="queryFunds",
queryCoinvestor="queryCoinvestor",
queryCoinvestors="queryCoinvestors",
queryCompanies="queryCompanies",
queryCompany="queryCompany",
queryCompanyMember="queryCompanyMember",
queryCompanyMembers="queryCompanyMembers",
queryCompanyNew="queryCompanyNew",
queryCompanyVideo="queryCompanyVideo",
queryCompanyVideos="queryCompanyVideos",
queryCompetitor="queryCompetitor",
queryCompetitors="queryCompetitors",
queryUser="queryUser",
queryIndustries="queryIndustries",
queryIndustry="queryIndustry",
queryTrends="queryTrends",
queryTrend="queryTrend",
queryStages="queryStages",
queryUsers="queryUsers",
queryUserSession="queryUserSession",
queryUserSessions="queryUserSessions",
queryRealEconomySectors="queryRealEconomySectors",
queryRealEconomySector="queryRealEconomySector",
queryFundingRoundCoinvestors="queryFundingRoundCoinvestors",
queryFundingRoundCoinvestor="queryFundingRoundCoinvestor",
queryFundingRounds="queryFundingRounds",
queryFundingRound="queryFundingRound",
queryDataRoomItem="queryDataRoomItem",
queryDataRoomItems="queryDataRoomItems",
queryDataRoomTopic="queryDataRoomTopic",
queryDataRoomTopics="queryDataRoomTopics",
queryMarketingEmail="queryMarketingEmail",
queryMarketingEmails="queryMarketingEmails",
queryCustomFetchFunds="queryCustomFetchFunds",
queryFetchCapitalDeployedByIndustryGraph="queryFetchCapitalDeployedByIndustryGraph",
queryFetchFundraisingAndValuationHistoryGraph="queryFetchFundraisingAndValuationHistoryGraph",
queryFetchCapitalDeployedByQuarter="queryFetchCapitalDeployedByQuarter",
queryFetchCompanies="queryFetchCompanies",
queryFetchIndustries="queryFetchIndustries",
queryFetchTrends="queryFetchTrends",
queryCompaniesByFund="queryCompaniesByFund"
}
export enum RootStoreBaseMutations {
mutateCreateOneFund="mutateCreateOneFund",
mutateUpdateOneFund="mutateUpdateOneFund",
mutateCreateOneCompany="mutateCreateOneCompany",
mutateCreateOneIndustry="mutateCreateOneIndustry",
mutateCreateOneTrend="mutateCreateOneTrend",
mutateUpdateOneCompany="mutateUpdateOneCompany",
mutateUpdateOneCoinvestor="mutateUpdateOneCoinvestor",
mutateUpdateOneCompanyMember="mutateUpdateOneCompanyMember",
mutateUpdateOneCompanyNew="mutateUpdateOneCompanyNew",
mutateUpdateOneCompanyVideo="mutateUpdateOneCompanyVideo",
mutateUpdateOneCompetitor="mutateUpdateOneCompetitor",
mutateUpdateOneFinalDilligenceCompany="mutateUpdateOneFinalDilligenceCompany",
mutateUpdateOneFundingRound="mutateUpdateOneFundingRound",
mutateUpdateOneFundingRoundCoinvestor="mutateUpdateOneFundingRoundCoinvestor",
mutateUpdateOneRealEconomySector="mutateUpdateOneRealEconomySector",
mutateUpdateOneIndustry="mutateUpdateOneIndustry",
mutateUpdateOneTrend="mutateUpdateOneTrend",
mutateDeleteOneFund="mutateDeleteOneFund",
mutateDeleteOneIndustry="mutateDeleteOneIndustry",
mutateDeleteOneTrend="mutateDeleteOneTrend",
mutateCreateOneDataRoomItem="mutateCreateOneDataRoomItem",
mutateCreateOneDataRoomTopic="mutateCreateOneDataRoomTopic",
mutateUpdateOneDataRoomItem="mutateUpdateOneDataRoomItem",
mutateUpdateOneDataRoomTopic="mutateUpdateOneDataRoomTopic",
mutateDeleteOneDataRoomItem="mutateDeleteOneDataRoomItem",
mutateDeleteOneDataRoomTopic="mutateDeleteOneDataRoomTopic",
mutateDeleteManyDataRoomItem="mutateDeleteManyDataRoomItem",
mutateDeleteManyDataRoomTopic="mutateDeleteManyDataRoomTopic",
mutateUpdateManyDataRoomItem="mutateUpdateManyDataRoomItem",
mutateUpdateManyDataRoomTopic="mutateUpdateManyDataRoomTopic",
mutateDeleteOneCompany="mutateDeleteOneCompany",
mutateDeleteOneUser="mutateDeleteOneUser",
mutateUpdateManyUser="mutateUpdateManyUser",
mutateUpdateOneUser="mutateUpdateOneUser",
mutateCreateOneUser="mutateCreateOneUser",
mutateCreateOneMarketingEmail="mutateCreateOneMarketingEmail",
mutateDeleteOneMarketingEmail="mutateDeleteOneMarketingEmail",
mutateCreateOneUserSession="mutateCreateOneUserSession",
mutateDeleteOneUserSession="mutateDeleteOneUserSession",
mutateCreateHBCUDataroomItem="mutateCreateHBCUDataroomItem",
mutateUpdateHBCUDataroomItem="mutateUpdateHBCUDataroomItem",
mutateUpdateCompany="mutateUpdateCompany",
mutateUpdateIndustry="mutateUpdateIndustry",
mutateSortCompanyOrdinal="mutateSortCompanyOrdinal",
mutateSortTrendOrdinal="mutateSortTrendOrdinal",
mutateSortIndustryOrdinal="mutateSortIndustryOrdinal",
mutateSortDataroomTopicOrdinal="mutateSortDataroomTopicOrdinal",
mutateSortDataroomItemOrdinal="mutateSortDataroomItemOrdinal",
mutateUpdateTrend="mutateUpdateTrend",
mutateUpdateStages="mutateUpdateStages",
mutateUpdateRound="mutateUpdateRound",
mutateDeleteFundingRound="mutateDeleteFundingRound",
mutateChangePassword="mutateChangePassword",
mutateRegister="mutateRegister",
mutateLogin="mutateLogin",
mutateSendMagicEmail="mutateSendMagicEmail",
mutateRequestAccess="mutateRequestAccess",
mutateUpdateTimeOnPage="mutateUpdateTimeOnPage",
mutateWelcomeToBase10="mutateWelcomeToBase10",
mutateForgotPassword="mutateForgotPassword",
mutateResetPassword="mutateResetPassword",
mutateDeleteManyStage="mutateDeleteManyStage",
mutateCreateOneStage="mutateCreateOneStage",
mutateUpdateOneStage="mutateUpdateOneStage",
mutateCreateOneFundingRoundCoinvestor="mutateCreateOneFundingRoundCoinvestor",
mutateDeleteOneFundingRoundCoinvestor="mutateDeleteOneFundingRoundCoinvestor",
mutateCreateOneFundingRound="mutateCreateOneFundingRound",
mutateDeleteOneFundingRound="mutateDeleteOneFundingRound",
mutateCopyIndustriesToFund="mutateCopyIndustriesToFund",
mutateCopyTrendsToFund="mutateCopyTrendsToFund",
mutateUpdateCompanyOrdinal="mutateUpdateCompanyOrdinal"
}

/**
* Store, managing, among others, all the objects received through graphQL
*/
export const RootStoreBase = withTypedRefs<Refs>()(MSTGQLStore
  .named("RootStore")
  .extend(configureStoreMixin([['Fund', () => FundModel], ['Company', () => CompanyModel], ['FundingRound', () => FundingRoundModel], ['FundingRoundCoinvestor', () => FundingRoundCoinvestorModel], ['Industry', () => IndustryModel], ['FinalDilligenceCompany', () => FinalDilligenceCompanyModel], ['Trend', () => TrendModel], ['Coinvestor', () => CoinvestorModel], ['Competitor', () => CompetitorModel], ['CompanyMember', () => CompanyMemberModel], ['CompanyVideo', () => CompanyVideoModel], ['CompanyNew', () => CompanyNewModel], ['DataRoomTopic', () => DataRoomTopicModel], ['DataRoomItem', () => DataRoomItemModel], ['Stage', () => StageModel], ['User', () => UserModel], ['UserSession', () => UserSessionModel], ['RealEconomySector', () => RealEconomySectorModel], ['MarketingEmail', () => MarketingEmailModel], ['CapitalDeployedByIndustry', () => CapitalDeployedByIndustryModel], ['CapitalDeployedByIndustryItem', () => CapitalDeployedByIndustryItemModel], ['FundraisingAndValuationHistoryGraph', () => FundraisingAndValuationHistoryGraphModel], ['FundraisingAndValuationHistoryGraphRound', () => FundraisingAndValuationHistoryGraphRoundModel], ['CapitalDeployedPerQuarter', () => CapitalDeployedPerQuarterModel], ['CapitalDeployedPerQuarterCompany', () => CapitalDeployedPerQuarterCompanyModel]], [], "js"))
  .props({

  })
  .actions(self => ({
    queryFund(variables: { where: FundWhereUniqueInput }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fund: FundModelType}>(`query fund($where: FundWhereUniqueInput!) { fund(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryFunds(variables: { where?: QueryFundsWhereInput, first?: number, last?: number, before?: FundWhereUniqueInput, after?: FundWhereUniqueInput }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ funds: FundModelType[]}>(`query funds($where: QueryFundsWhereInput, $first: Int, $last: Int, $before: FundWhereUniqueInput, $after: FundWhereUniqueInput) { funds(where: $where, first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCoinvestor(variables: { where: CoinvestorWhereUniqueInput }, resultSelector: string | ((qb: CoinvestorModelSelector) => CoinvestorModelSelector) = coinvestorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ coinvestor: CoinvestorModelType}>(`query coinvestor($where: CoinvestorWhereUniqueInput!) { coinvestor(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCoinvestors(variables: { first?: number, last?: number, before?: CoinvestorWhereUniqueInput, after?: CoinvestorWhereUniqueInput }, resultSelector: string | ((qb: CoinvestorModelSelector) => CoinvestorModelSelector) = coinvestorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ coinvestors: CoinvestorModelType[]}>(`query coinvestors($first: Int, $last: Int, $before: CoinvestorWhereUniqueInput, $after: CoinvestorWhereUniqueInput) { coinvestors(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new CoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompanies(variables: { first?: number, last?: number, before?: CompanyWhereUniqueInput, after?: CompanyWhereUniqueInput }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ companies: CompanyModelType[]}>(`query companies($first: Int, $last: Int, $before: CompanyWhereUniqueInput, $after: CompanyWhereUniqueInput) { companies(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompany(variables: { where: CompanyWhereUniqueInput }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ company: CompanyModelType}>(`query company($where: CompanyWhereUniqueInput!) { company(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompanyMember(variables: { where: CompanyMemberWhereUniqueInput }, resultSelector: string | ((qb: CompanyMemberModelSelector) => CompanyMemberModelSelector) = companyMemberModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ companyMember: CompanyMemberModelType}>(`query companyMember($where: CompanyMemberWhereUniqueInput!) { companyMember(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyMemberModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompanyMembers(variables: { first?: number, last?: number, before?: CompanyMemberWhereUniqueInput, after?: CompanyMemberWhereUniqueInput }, resultSelector: string | ((qb: CompanyMemberModelSelector) => CompanyMemberModelSelector) = companyMemberModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ companyMembers: CompanyMemberModelType[]}>(`query companyMembers($first: Int, $last: Int, $before: CompanyMemberWhereUniqueInput, $after: CompanyMemberWhereUniqueInput) { companyMembers(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyMemberModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompanyNew(variables: { where: CompanyNewWhereUniqueInput }, resultSelector: string | ((qb: CompanyNewModelSelector) => CompanyNewModelSelector) = companyNewModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ companyNew: CompanyNewModelType}>(`query companyNew($where: CompanyNewWhereUniqueInput!) { companyNew(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyNewModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompanyVideo(variables: { where: CompanyVideoWhereUniqueInput }, resultSelector: string | ((qb: CompanyVideoModelSelector) => CompanyVideoModelSelector) = companyVideoModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ companyVideo: CompanyVideoModelType}>(`query companyVideo($where: CompanyVideoWhereUniqueInput!) { companyVideo(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyVideoModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompanyVideos(variables: { first?: number, last?: number, before?: CompanyVideoWhereUniqueInput, after?: CompanyVideoWhereUniqueInput }, resultSelector: string | ((qb: CompanyVideoModelSelector) => CompanyVideoModelSelector) = companyVideoModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ companyVideos: CompanyVideoModelType[]}>(`query companyVideos($first: Int, $last: Int, $before: CompanyVideoWhereUniqueInput, $after: CompanyVideoWhereUniqueInput) { companyVideos(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyVideoModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompetitor(variables: { where: CompetitorWhereUniqueInput }, resultSelector: string | ((qb: CompetitorModelSelector) => CompetitorModelSelector) = competitorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ competitor: CompetitorModelType}>(`query competitor($where: CompetitorWhereUniqueInput!) { competitor(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompetitorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryCompetitors(variables: { first?: number, last?: number, before?: CompetitorWhereUniqueInput, after?: CompetitorWhereUniqueInput }, resultSelector: string | ((qb: CompetitorModelSelector) => CompetitorModelSelector) = competitorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ competitors: CompetitorModelType[]}>(`query competitors($first: Int, $last: Int, $before: CompetitorWhereUniqueInput, $after: CompetitorWhereUniqueInput) { competitors(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new CompetitorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryUser(variables: { where: UserWhereUniqueInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ user: UserModelType}>(`query user($where: UserWhereUniqueInput!) { user(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryIndustries(variables: { first?: number, last?: number, before?: IndustryWhereUniqueInput, after?: IndustryWhereUniqueInput }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ industries: IndustryModelType[]}>(`query industries($first: Int, $last: Int, $before: IndustryWhereUniqueInput, $after: IndustryWhereUniqueInput) { industries(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryIndustry(variables: { where: IndustryWhereUniqueInput }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ industry: IndustryModelType}>(`query industry($where: IndustryWhereUniqueInput!) { industry(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryTrends(variables: { first?: number, last?: number, before?: TrendWhereUniqueInput, after?: TrendWhereUniqueInput }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ trends: TrendModelType[]}>(`query trends($first: Int, $last: Int, $before: TrendWhereUniqueInput, $after: TrendWhereUniqueInput) { trends(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryTrend(variables: { where: TrendWhereUniqueInput }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ trend: TrendModelType}>(`query trend($where: TrendWhereUniqueInput!) { trend(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryStages(variables: { first?: number, last?: number, before?: StageWhereUniqueInput, after?: StageWhereUniqueInput }, resultSelector: string | ((qb: StageModelSelector) => StageModelSelector) = stageModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ stages: StageModelType[]}>(`query stages($first: Int, $last: Int, $before: StageWhereUniqueInput, $after: StageWhereUniqueInput) { stages(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new StageModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryUsers(variables: { where?: QueryUsersWhereInput, orderBy?: QueryUsersOrderByInput[], first?: number, last?: number, before?: UserWhereUniqueInput, after?: UserWhereUniqueInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ users: UserModelType[]}>(`query users($where: QueryUsersWhereInput, $orderBy: [QueryUsersOrderByInput!], $first: Int, $last: Int, $before: UserWhereUniqueInput, $after: UserWhereUniqueInput) { users(where: $where, orderBy: $orderBy, first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryUserSession(variables: { where: UserSessionWhereUniqueInput }, resultSelector: string | ((qb: UserSessionModelSelector) => UserSessionModelSelector) = userSessionModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ userSession: UserSessionModelType}>(`query userSession($where: UserSessionWhereUniqueInput!) { userSession(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new UserSessionModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryUserSessions(variables: { first?: number, last?: number, before?: UserSessionWhereUniqueInput, after?: UserSessionWhereUniqueInput }, resultSelector: string | ((qb: UserSessionModelSelector) => UserSessionModelSelector) = userSessionModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ userSessions: UserSessionModelType[]}>(`query userSessions($first: Int, $last: Int, $before: UserSessionWhereUniqueInput, $after: UserSessionWhereUniqueInput) { userSessions(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new UserSessionModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryRealEconomySectors(variables: { first?: number, last?: number, before?: RealEconomySectorWhereUniqueInput, after?: RealEconomySectorWhereUniqueInput }, resultSelector: string | ((qb: RealEconomySectorModelSelector) => RealEconomySectorModelSelector) = realEconomySectorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ realEconomySectors: RealEconomySectorModelType[]}>(`query realEconomySectors($first: Int, $last: Int, $before: RealEconomySectorWhereUniqueInput, $after: RealEconomySectorWhereUniqueInput) { realEconomySectors(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new RealEconomySectorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryRealEconomySector(variables: { where: RealEconomySectorWhereUniqueInput }, resultSelector: string | ((qb: RealEconomySectorModelSelector) => RealEconomySectorModelSelector) = realEconomySectorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ realEconomySector: RealEconomySectorModelType}>(`query realEconomySector($where: RealEconomySectorWhereUniqueInput!) { realEconomySector(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new RealEconomySectorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryFundingRoundCoinvestors(variables: { first?: number, last?: number, before?: FundingRoundCoinvestorWhereUniqueInput, after?: FundingRoundCoinvestorWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundCoinvestorModelSelector) => FundingRoundCoinvestorModelSelector) = fundingRoundCoinvestorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fundingRoundCoinvestors: FundingRoundCoinvestorModelType[]}>(`query fundingRoundCoinvestors($first: Int, $last: Int, $before: FundingRoundCoinvestorWhereUniqueInput, $after: FundingRoundCoinvestorWhereUniqueInput) { fundingRoundCoinvestors(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundCoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryFundingRoundCoinvestor(variables: { where: FundingRoundCoinvestorWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundCoinvestorModelSelector) => FundingRoundCoinvestorModelSelector) = fundingRoundCoinvestorModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fundingRoundCoinvestor: FundingRoundCoinvestorModelType}>(`query fundingRoundCoinvestor($where: FundingRoundCoinvestorWhereUniqueInput!) { fundingRoundCoinvestor(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundCoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryFundingRounds(variables: { first?: number, last?: number, before?: FundingRoundWhereUniqueInput, after?: FundingRoundWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundModelSelector) => FundingRoundModelSelector) = fundingRoundModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fundingRounds: FundingRoundModelType[]}>(`query fundingRounds($first: Int, $last: Int, $before: FundingRoundWhereUniqueInput, $after: FundingRoundWhereUniqueInput) { fundingRounds(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryFundingRound(variables: { where: FundingRoundWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundModelSelector) => FundingRoundModelSelector) = fundingRoundModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fundingRound: FundingRoundModelType}>(`query fundingRound($where: FundingRoundWhereUniqueInput!) { fundingRound(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryDataRoomItem(variables: { where: DataRoomItemWhereUniqueInput }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ dataRoomItem: DataRoomItemModelType}>(`query dataRoomItem($where: DataRoomItemWhereUniqueInput!) { dataRoomItem(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryDataRoomItems(variables: { where?: QueryDataRoomItemsWhereInput, orderBy?: QueryDataRoomItemsOrderByInput[], first?: number, last?: number, before?: DataRoomItemWhereUniqueInput, after?: DataRoomItemWhereUniqueInput }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ dataRoomItems: DataRoomItemModelType[]}>(`query dataRoomItems($where: QueryDataRoomItemsWhereInput, $orderBy: [QueryDataRoomItemsOrderByInput!], $first: Int, $last: Int, $before: DataRoomItemWhereUniqueInput, $after: DataRoomItemWhereUniqueInput) { dataRoomItems(where: $where, orderBy: $orderBy, first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryDataRoomTopic(variables: { where: DataRoomTopicWhereUniqueInput }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ dataRoomTopic: DataRoomTopicModelType}>(`query dataRoomTopic($where: DataRoomTopicWhereUniqueInput!) { dataRoomTopic(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryDataRoomTopics(variables: { where?: DataRoomTopicWhereInput, orderBy?: QueryDataRoomTopicsOrderByInput[], first?: number, last?: number, before?: DataRoomTopicWhereUniqueInput, after?: DataRoomTopicWhereUniqueInput }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ dataRoomTopics: DataRoomTopicModelType[]}>(`query dataRoomTopics($where: DataRoomTopicWhereInput, $orderBy: [QueryDataRoomTopicsOrderByInput!], $first: Int, $last: Int, $before: DataRoomTopicWhereUniqueInput, $after: DataRoomTopicWhereUniqueInput) { dataRoomTopics(where: $where, orderBy: $orderBy, first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryMarketingEmail(variables: { where: MarketingEmailWhereUniqueInput }, resultSelector: string | ((qb: MarketingEmailModelSelector) => MarketingEmailModelSelector) = marketingEmailModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ marketingEmail: MarketingEmailModelType}>(`query marketingEmail($where: MarketingEmailWhereUniqueInput!) { marketingEmail(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new MarketingEmailModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryMarketingEmails(variables: { first?: number, last?: number, before?: MarketingEmailWhereUniqueInput, after?: MarketingEmailWhereUniqueInput }, resultSelector: string | ((qb: MarketingEmailModelSelector) => MarketingEmailModelSelector) = marketingEmailModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ marketingEmails: MarketingEmailModelType[]}>(`query marketingEmails($first: Int, $last: Int, $before: MarketingEmailWhereUniqueInput, $after: MarketingEmailWhereUniqueInput) { marketingEmails(first: $first, last: $last, before: $before, after: $after) {
        ${typeof resultSelector === "function" ? resultSelector(new MarketingEmailModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Like fetchFunds, but only returns funds that the user has access to.
    queryCustomFetchFunds(variables?: {  }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ customFetchFunds: FundModelType[]}>(`query customFetchFunds { customFetchFunds {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Data used for the Capital deployed by industry chart
    queryFetchCapitalDeployedByIndustryGraph(variables: { fundId: number }, resultSelector: string | ((qb: CapitalDeployedByIndustryModelSelector) => CapitalDeployedByIndustryModelSelector) = capitalDeployedByIndustryModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fetchCapitalDeployedByIndustryGraph: CapitalDeployedByIndustryModelType}>(`query fetchCapitalDeployedByIndustryGraph($fundId: Int!) { fetchCapitalDeployedByIndustryGraph(fund_id: $fundId) {
        ${typeof resultSelector === "function" ? resultSelector(new CapitalDeployedByIndustryModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Query used for The fundraising and valuation history graph in the company modal
    queryFetchFundraisingAndValuationHistoryGraph(variables: { companyId: number }, resultSelector: string | ((qb: FundraisingAndValuationHistoryGraphModelSelector) => FundraisingAndValuationHistoryGraphModelSelector) = fundraisingAndValuationHistoryGraphModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fetchFundraisingAndValuationHistoryGraph: FundraisingAndValuationHistoryGraphModelType}>(`query fetchFundraisingAndValuationHistoryGraph($companyId: Int!) { fetchFundraisingAndValuationHistoryGraph(company_id: $companyId) {
        ${typeof resultSelector === "function" ? resultSelector(new FundraisingAndValuationHistoryGraphModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Used on the LP Portal Dashboard Capital Deployed Per Quarter chart
    queryFetchCapitalDeployedByQuarter(variables: { fundId: number }, resultSelector: string | ((qb: CapitalDeployedPerQuarterModelSelector) => CapitalDeployedPerQuarterModelSelector) = capitalDeployedPerQuarterModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fetchCapitalDeployedByQuarter: CapitalDeployedPerQuarterModelType[]}>(`query fetchCapitalDeployedByQuarter($fundId: Int!) { fetchCapitalDeployedByQuarter(fund_id: $fundId) {
        ${typeof resultSelector === "function" ? resultSelector(new CapitalDeployedPerQuarterModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Returns list of companies for a given fund
    queryFetchCompanies(variables: { fundId?: number }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fetchCompanies: CompanyModelType[]}>(`query fetchCompanies($fundId: Int) { fetchCompanies(fund_id: $fundId) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Returns a list of Industries for a given fund
    queryFetchIndustries(variables: { fundId: number }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fetchIndustries: IndustryModelType[]}>(`query fetchIndustries($fundId: Int!) { fetchIndustries(fund_id: $fundId) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Returns a list of Trends for a given fund
    queryFetchTrends(variables: { fundId: number }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ fetchTrends: TrendModelType[]}>(`query fetchTrends($fundId: Int!) { fetchTrends(fund_id: $fundId) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // returns companies that are selectable for the given fund.
    queryCompaniesByFund(variables: { fundId: number }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ companiesByFund: CompanyModelType[]}>(`query companiesByFund($fundId: Int!) { companiesByFund(fund_id: $fundId) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    mutateCreateOneFund(variables: { data: FundCreateInput }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneFund: FundModelType}>(`mutation createOneFund($data: FundCreateInput!) { createOneFund(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneFund(variables: { data: FundUpdateInput, where: FundWhereUniqueInput }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneFund: FundModelType}>(`mutation updateOneFund($data: FundUpdateInput!, $where: FundWhereUniqueInput!) { updateOneFund(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneCompany(variables: { data: CompanyCreateInput }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneCompany: CompanyModelType}>(`mutation createOneCompany($data: CompanyCreateInput!) { createOneCompany(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneIndustry(variables: { data: IndustryCreateInput }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneIndustry: IndustryModelType}>(`mutation createOneIndustry($data: IndustryCreateInput!) { createOneIndustry(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneTrend(variables: { data: TrendCreateInput }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneTrend: TrendModelType}>(`mutation createOneTrend($data: TrendCreateInput!) { createOneTrend(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneCompany(variables: { data: CompanyUpdateInput, where: CompanyWhereUniqueInput }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneCompany: CompanyModelType}>(`mutation updateOneCompany($data: CompanyUpdateInput!, $where: CompanyWhereUniqueInput!) { updateOneCompany(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneCoinvestor(variables: { data: CoinvestorUpdateInput, where: CoinvestorWhereUniqueInput }, resultSelector: string | ((qb: CoinvestorModelSelector) => CoinvestorModelSelector) = coinvestorModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneCoinvestor: CoinvestorModelType}>(`mutation updateOneCoinvestor($data: CoinvestorUpdateInput!, $where: CoinvestorWhereUniqueInput!) { updateOneCoinvestor(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneCompanyMember(variables: { data: CompanyMemberUpdateInput, where: CompanyMemberWhereUniqueInput }, resultSelector: string | ((qb: CompanyMemberModelSelector) => CompanyMemberModelSelector) = companyMemberModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneCompanyMember: CompanyMemberModelType}>(`mutation updateOneCompanyMember($data: CompanyMemberUpdateInput!, $where: CompanyMemberWhereUniqueInput!) { updateOneCompanyMember(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyMemberModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneCompanyNew(variables: { data: CompanyNewUpdateInput, where: CompanyNewWhereUniqueInput }, resultSelector: string | ((qb: CompanyNewModelSelector) => CompanyNewModelSelector) = companyNewModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneCompanyNew: CompanyNewModelType}>(`mutation updateOneCompanyNew($data: CompanyNewUpdateInput!, $where: CompanyNewWhereUniqueInput!) { updateOneCompanyNew(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyNewModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneCompanyVideo(variables: { data: CompanyVideoUpdateInput, where: CompanyVideoWhereUniqueInput }, resultSelector: string | ((qb: CompanyVideoModelSelector) => CompanyVideoModelSelector) = companyVideoModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneCompanyVideo: CompanyVideoModelType}>(`mutation updateOneCompanyVideo($data: CompanyVideoUpdateInput!, $where: CompanyVideoWhereUniqueInput!) { updateOneCompanyVideo(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyVideoModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneCompetitor(variables: { data: CompetitorUpdateInput, where: CompetitorWhereUniqueInput }, resultSelector: string | ((qb: CompetitorModelSelector) => CompetitorModelSelector) = competitorModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneCompetitor: CompetitorModelType}>(`mutation updateOneCompetitor($data: CompetitorUpdateInput!, $where: CompetitorWhereUniqueInput!) { updateOneCompetitor(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompetitorModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneFinalDilligenceCompany(variables: { data: FinalDilligenceCompanyUpdateInput, where: FinalDilligenceCompanyWhereUniqueInput }, resultSelector: string | ((qb: FinalDilligenceCompanyModelSelector) => FinalDilligenceCompanyModelSelector) = finalDilligenceCompanyModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneFinalDilligenceCompany: FinalDilligenceCompanyModelType}>(`mutation updateOneFinalDilligenceCompany($data: FinalDilligenceCompanyUpdateInput!, $where: FinalDilligenceCompanyWhereUniqueInput!) { updateOneFinalDilligenceCompany(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FinalDilligenceCompanyModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneFundingRound(variables: { data: FundingRoundUpdateInput, where: FundingRoundWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundModelSelector) => FundingRoundModelSelector) = fundingRoundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneFundingRound: FundingRoundModelType}>(`mutation updateOneFundingRound($data: FundingRoundUpdateInput!, $where: FundingRoundWhereUniqueInput!) { updateOneFundingRound(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneFundingRoundCoinvestor(variables: { data: FundingRoundCoinvestorUpdateInput, where: FundingRoundCoinvestorWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundCoinvestorModelSelector) => FundingRoundCoinvestorModelSelector) = fundingRoundCoinvestorModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneFundingRoundCoinvestor: FundingRoundCoinvestorModelType}>(`mutation updateOneFundingRoundCoinvestor($data: FundingRoundCoinvestorUpdateInput!, $where: FundingRoundCoinvestorWhereUniqueInput!) { updateOneFundingRoundCoinvestor(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundCoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneRealEconomySector(variables: { data: RealEconomySectorUpdateInput, where: RealEconomySectorWhereUniqueInput }, resultSelector: string | ((qb: RealEconomySectorModelSelector) => RealEconomySectorModelSelector) = realEconomySectorModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneRealEconomySector: RealEconomySectorModelType}>(`mutation updateOneRealEconomySector($data: RealEconomySectorUpdateInput!, $where: RealEconomySectorWhereUniqueInput!) { updateOneRealEconomySector(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new RealEconomySectorModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneIndustry(variables: { data: IndustryUpdateInput, where: IndustryWhereUniqueInput }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneIndustry: IndustryModelType}>(`mutation updateOneIndustry($data: IndustryUpdateInput!, $where: IndustryWhereUniqueInput!) { updateOneIndustry(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneTrend(variables: { data: TrendUpdateInput, where: TrendWhereUniqueInput }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneTrend: TrendModelType}>(`mutation updateOneTrend($data: TrendUpdateInput!, $where: TrendWhereUniqueInput!) { updateOneTrend(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneFund(variables: { where: FundWhereUniqueInput }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneFund: FundModelType}>(`mutation deleteOneFund($where: FundWhereUniqueInput!) { deleteOneFund(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneIndustry(variables: { where: IndustryWhereUniqueInput }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneIndustry: IndustryModelType}>(`mutation deleteOneIndustry($where: IndustryWhereUniqueInput!) { deleteOneIndustry(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneTrend(variables: { where: TrendWhereUniqueInput }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneTrend: TrendModelType}>(`mutation deleteOneTrend($where: TrendWhereUniqueInput!) { deleteOneTrend(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneDataRoomItem(variables: { data: DataRoomItemCreateInput }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneDataRoomItem: DataRoomItemModelType}>(`mutation createOneDataRoomItem($data: DataRoomItemCreateInput!) { createOneDataRoomItem(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneDataRoomTopic(variables: { data: DataRoomTopicCreateInput }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneDataRoomTopic: DataRoomTopicModelType}>(`mutation createOneDataRoomTopic($data: DataRoomTopicCreateInput!) { createOneDataRoomTopic(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneDataRoomItem(variables: { data: DataRoomItemUpdateInput, where: DataRoomItemWhereUniqueInput }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneDataRoomItem: DataRoomItemModelType}>(`mutation updateOneDataRoomItem($data: DataRoomItemUpdateInput!, $where: DataRoomItemWhereUniqueInput!) { updateOneDataRoomItem(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneDataRoomTopic(variables: { data: DataRoomTopicUpdateInput, where: DataRoomTopicWhereUniqueInput }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneDataRoomTopic: DataRoomTopicModelType}>(`mutation updateOneDataRoomTopic($data: DataRoomTopicUpdateInput!, $where: DataRoomTopicWhereUniqueInput!) { updateOneDataRoomTopic(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneDataRoomItem(variables: { where: DataRoomItemWhereUniqueInput }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneDataRoomItem: DataRoomItemModelType}>(`mutation deleteOneDataRoomItem($where: DataRoomItemWhereUniqueInput!) { deleteOneDataRoomItem(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneDataRoomTopic(variables: { where: DataRoomTopicWhereUniqueInput }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneDataRoomTopic: DataRoomTopicModelType}>(`mutation deleteOneDataRoomTopic($where: DataRoomTopicWhereUniqueInput!) { deleteOneDataRoomTopic(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteManyDataRoomItem(variables: { where?: DataRoomItemWhereInput }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteManyDataRoomItem: DataRoomItemModelType}>(`mutation deleteManyDataRoomItem($where: DataRoomItemWhereInput) { deleteManyDataRoomItem(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteManyDataRoomTopic(variables: { where?: DataRoomTopicWhereInput }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteManyDataRoomTopic: DataRoomTopicModelType}>(`mutation deleteManyDataRoomTopic($where: DataRoomTopicWhereInput) { deleteManyDataRoomTopic(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateManyDataRoomItem(variables: { data: DataRoomItemUpdateManyMutationInput, where?: DataRoomItemWhereInput }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateManyDataRoomItem: DataRoomItemModelType}>(`mutation updateManyDataRoomItem($data: DataRoomItemUpdateManyMutationInput!, $where: DataRoomItemWhereInput) { updateManyDataRoomItem(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateManyDataRoomTopic(variables: { data: DataRoomTopicUpdateManyMutationInput, where?: DataRoomTopicWhereInput }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateManyDataRoomTopic: DataRoomTopicModelType}>(`mutation updateManyDataRoomTopic($data: DataRoomTopicUpdateManyMutationInput!, $where: DataRoomTopicWhereInput) { updateManyDataRoomTopic(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneCompany(variables: { where: CompanyWhereUniqueInput }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneCompany: CompanyModelType}>(`mutation deleteOneCompany($where: CompanyWhereUniqueInput!) { deleteOneCompany(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneUser(variables: { where: UserWhereUniqueInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneUser: UserModelType}>(`mutation deleteOneUser($where: UserWhereUniqueInput!) { deleteOneUser(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateManyUser(variables: { data: UserUpdateManyMutationInput, where?: UserWhereInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateManyUser: UserModelType}>(`mutation updateManyUser($data: UserUpdateManyMutationInput!, $where: UserWhereInput) { updateManyUser(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneUser(variables: { data: UserUpdateInput, where: UserWhereUniqueInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneUser: UserModelType}>(`mutation updateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) { updateOneUser(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneUser(variables: { data: UserCreateInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneUser: UserModelType}>(`mutation createOneUser($data: UserCreateInput!) { createOneUser(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneMarketingEmail(variables: { data: MarketingEmailCreateInput }, resultSelector: string | ((qb: MarketingEmailModelSelector) => MarketingEmailModelSelector) = marketingEmailModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneMarketingEmail: MarketingEmailModelType}>(`mutation createOneMarketingEmail($data: MarketingEmailCreateInput!) { createOneMarketingEmail(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new MarketingEmailModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneMarketingEmail(variables: { where: MarketingEmailWhereUniqueInput }, resultSelector: string | ((qb: MarketingEmailModelSelector) => MarketingEmailModelSelector) = marketingEmailModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneMarketingEmail: MarketingEmailModelType}>(`mutation deleteOneMarketingEmail($where: MarketingEmailWhereUniqueInput!) { deleteOneMarketingEmail(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new MarketingEmailModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneUserSession(variables: { data: UserSessionCreateInput }, resultSelector: string | ((qb: UserSessionModelSelector) => UserSessionModelSelector) = userSessionModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneUserSession: UserSessionModelType}>(`mutation createOneUserSession($data: UserSessionCreateInput!) { createOneUserSession(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new UserSessionModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneUserSession(variables: { where: UserSessionWhereUniqueInput }, resultSelector: string | ((qb: UserSessionModelSelector) => UserSessionModelSelector) = userSessionModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneUserSession: UserSessionModelType}>(`mutation deleteOneUserSession($where: UserSessionWhereUniqueInput!) { deleteOneUserSession(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new UserSessionModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Creates a Dataroom item for HBCU user. Sends an email notification to all HBCU users notifying them of them new item.
    mutateCreateHBCUDataroomItem(variables: { label: string, url: string, type: UrlType, topicId: number, sendNotification?: boolean }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createHBCUDataroomItem: DataRoomItemModelType}>(`mutation createHBCUDataroomItem($label: String!, $url: String!, $type: URL_TYPE!, $topicId: Int!, $sendNotification: Boolean) { createHBCUDataroomItem(label: $label, url: $url, type: $type, topic_id: $topicId, send_notification: $sendNotification) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Updates a Dataroom item for HBCU user. Sends an email notification to all HBCU users notifying them of them new item.
    mutateUpdateHBCUDataroomItem(variables: { label: string, url: string, type: UrlType, itemId: number, sendNotification?: boolean }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateHBCUDataroomItem: DataRoomItemModelType}>(`mutation updateHBCUDataroomItem($label: String!, $url: String!, $type: URL_TYPE!, $itemId: Int!, $sendNotification: Boolean) { updateHBCUDataroomItem(label: $label, url: $url, type: $type, item_id: $itemId, send_notification: $sendNotification) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateCompany(variables: { children?: CompanyChildInput }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateCompany: CompanyModelType}>(`mutation updateCompany($children: CompanyChildInput) { updateCompany(children: $children) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateIndustry(variables: { children?: IndustryInput }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateIndustry: IndustryModelType}>(`mutation updateIndustry($children: IndustryInput) { updateIndustry(children: $children) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSortCompanyOrdinal(variables: { objectIds: number[] }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ sortCompanyOrdinal: CompanyModelType[]}>(`mutation sortCompanyOrdinal($objectIds: [Int!]!) { sortCompanyOrdinal(object_ids: $objectIds) {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSortTrendOrdinal(variables: { objectIds: number[] }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ sortTrendOrdinal: TrendModelType[]}>(`mutation sortTrendOrdinal($objectIds: [Int!]!) { sortTrendOrdinal(object_ids: $objectIds) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSortIndustryOrdinal(variables: { objectIds: number[] }, resultSelector: string | ((qb: IndustryModelSelector) => IndustryModelSelector) = industryModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ sortIndustryOrdinal: IndustryModelType[]}>(`mutation sortIndustryOrdinal($objectIds: [Int!]!) { sortIndustryOrdinal(object_ids: $objectIds) {
        ${typeof resultSelector === "function" ? resultSelector(new IndustryModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSortDataroomTopicOrdinal(variables: { objectIds: number[] }, resultSelector: string | ((qb: DataRoomTopicModelSelector) => DataRoomTopicModelSelector) = dataRoomTopicModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ sortDataroomTopicOrdinal: DataRoomTopicModelType[]}>(`mutation sortDataroomTopicOrdinal($objectIds: [Int!]!) { sortDataroomTopicOrdinal(object_ids: $objectIds) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomTopicModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSortDataroomItemOrdinal(variables: { objectIds: number[] }, resultSelector: string | ((qb: DataRoomItemModelSelector) => DataRoomItemModelSelector) = dataRoomItemModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ sortDataroomItemOrdinal: DataRoomItemModelType[]}>(`mutation sortDataroomItemOrdinal($objectIds: [Int!]!) { sortDataroomItemOrdinal(object_ids: $objectIds) {
        ${typeof resultSelector === "function" ? resultSelector(new DataRoomItemModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateTrend(variables: { children?: TrendInput }, resultSelector: string | ((qb: TrendModelSelector) => TrendModelSelector) = trendModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateTrend: TrendModelType}>(`mutation updateTrend($children: TrendInput) { updateTrend(children: $children) {
        ${typeof resultSelector === "function" ? resultSelector(new TrendModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateStages(variables: { stages?: StageInput }, resultSelector: string | ((qb: StageModelSelector) => StageModelSelector) = stageModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateStages: StageModelType[]}>(`mutation updateStages($stages: StageInput) { updateStages(stages: $stages) {
        ${typeof resultSelector === "function" ? resultSelector(new StageModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateRound(variables: { round?: RoundInput }, resultSelector: string | ((qb: FundingRoundModelSelector) => FundingRoundModelSelector) = fundingRoundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateRound: FundingRoundModelType}>(`mutation updateRound($round: RoundInput) { updateRound(round: $round) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteFundingRound(variables: { fundingRoundId: string }, resultSelector: string | ((qb: FundingRoundModelSelector) => FundingRoundModelSelector) = fundingRoundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteFundingRound: FundingRoundModelType}>(`mutation deleteFundingRound($fundingRoundId: String!) { deleteFundingRound(funding_round_id: $fundingRoundId) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Changes users password
    mutateChangePassword(variables: { email: string, password: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ changePassword: UserModelType}>(`mutation changePassword($email: String!, $password: String!) { changePassword(email: $email, password: $password) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateRegister(variables: { user?: RegisterInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ register: UserModelType}>(`mutation register($user: RegisterInput) { register(user: $user) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateLogin(variables: { user?: LoginInput }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ login: UserModelType}>(`mutation login($user: LoginInput) { login(user: $user) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSendMagicEmail(variables: { email: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ sendMagicEmail: UserModelType}>(`mutation sendMagicEmail($email: String!) { sendMagicEmail(email: $email) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateRequestAccess(variables: { name: string, organization: string, title: string, email: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ requestAccess: UserModelType}>(`mutation requestAccess($name: String!, $organization: String!, $title: String!, $email: String!) { requestAccess(name: $name, organization: $organization, title: $title, email: $email) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateTimeOnPage(variables?: {  }, resultSelector: string | ((qb: UserSessionModelSelector) => UserSessionModelSelector) = userSessionModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateTimeOnPage: UserSessionModelType}>(`mutation updateTimeOnPage { updateTimeOnPage {
        ${typeof resultSelector === "function" ? resultSelector(new UserSessionModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateWelcomeToBase10(variables: { email: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ welcomeToBase10: UserModelType}>(`mutation welcomeToBase10($email: String!) { welcomeToBase10(email: $email) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateForgotPassword(variables: { username?: string, email?: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ forgotPassword: UserModelType}>(`mutation forgotPassword($username: String, $email: String) { forgotPassword(username: $username, email: $email) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateResetPassword(variables: { email?: string, username?: string, password: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ resetPassword: UserModelType}>(`mutation resetPassword($email: String, $username: String, $password: String!) { resetPassword(email: $email, username: $username, password: $password) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteManyStage(variables: { where?: StageWhereInput }, resultSelector: string | ((qb: StageModelSelector) => StageModelSelector) = stageModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteManyStage: StageModelType}>(`mutation deleteManyStage($where: StageWhereInput) { deleteManyStage(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new StageModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneStage(variables: { data: StageCreateInput }, resultSelector: string | ((qb: StageModelSelector) => StageModelSelector) = stageModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneStage: StageModelType}>(`mutation createOneStage($data: StageCreateInput!) { createOneStage(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new StageModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateOneStage(variables: { data: StageUpdateInput, where: StageWhereUniqueInput }, resultSelector: string | ((qb: StageModelSelector) => StageModelSelector) = stageModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateOneStage: StageModelType}>(`mutation updateOneStage($data: StageUpdateInput!, $where: StageWhereUniqueInput!) { updateOneStage(data: $data, where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new StageModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneFundingRoundCoinvestor(variables: { data: FundingRoundCoinvestorCreateInput }, resultSelector: string | ((qb: FundingRoundCoinvestorModelSelector) => FundingRoundCoinvestorModelSelector) = fundingRoundCoinvestorModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneFundingRoundCoinvestor: FundingRoundCoinvestorModelType}>(`mutation createOneFundingRoundCoinvestor($data: FundingRoundCoinvestorCreateInput!) { createOneFundingRoundCoinvestor(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundCoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneFundingRoundCoinvestor(variables: { where: FundingRoundCoinvestorWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundCoinvestorModelSelector) => FundingRoundCoinvestorModelSelector) = fundingRoundCoinvestorModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneFundingRoundCoinvestor: FundingRoundCoinvestorModelType}>(`mutation deleteOneFundingRoundCoinvestor($where: FundingRoundCoinvestorWhereUniqueInput!) { deleteOneFundingRoundCoinvestor(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundCoinvestorModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateOneFundingRound(variables: { data: FundingRoundCreateInput }, resultSelector: string | ((qb: FundingRoundModelSelector) => FundingRoundModelSelector) = fundingRoundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createOneFundingRound: FundingRoundModelType}>(`mutation createOneFundingRound($data: FundingRoundCreateInput!) { createOneFundingRound(data: $data) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteOneFundingRound(variables: { where: FundingRoundWhereUniqueInput }, resultSelector: string | ((qb: FundingRoundModelSelector) => FundingRoundModelSelector) = fundingRoundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteOneFundingRound: FundingRoundModelType}>(`mutation deleteOneFundingRound($where: FundingRoundWhereUniqueInput!) { deleteOneFundingRound(where: $where) {
        ${typeof resultSelector === "function" ? resultSelector(new FundingRoundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Warning! Only run this if you know what you are doing! This copies industries from one fund to another.
    mutateCopyIndustriesToFund(variables: { fromFundId: number, toFundId: number }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ copyIndustriesToFund: FundModelType}>(`mutation copyIndustriesToFund($fromFundId: Int!, $toFundId: Int!) { copyIndustriesToFund(from_fund_id: $fromFundId, to_fund_id: $toFundId) {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Warning! Only run this if you know what you are doing! This copies trends from one fund to another.
    mutateCopyTrendsToFund(variables: { fromFundId: number, toFundId: number }, resultSelector: string | ((qb: FundModelSelector) => FundModelSelector) = fundModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ copyTrendsToFund: FundModelType}>(`mutation copyTrendsToFund($fromFundId: Int!, $toFundId: Int!) { copyTrendsToFund(from_fund_id: $fromFundId, to_fund_id: $toFundId) {
        ${typeof resultSelector === "function" ? resultSelector(new FundModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateCompanyOrdinal(variables?: {  }, resultSelector: string | ((qb: CompanyModelSelector) => CompanyModelSelector) = companyModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateCompanyOrdinal: CompanyModelType[]}>(`mutation updateCompanyOrdinal { updateCompanyOrdinal {
        ${typeof resultSelector === "function" ? resultSelector(new CompanyModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
  })))
