/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CoinvestorModel, CoinvestorModelType } from "./CoinvestorModel"
import { CoinvestorModelSelector } from "./CoinvestorModel.base"
import { CompanyMemberModel, CompanyMemberModelType } from "./CompanyMemberModel"
import { CompanyMemberModelSelector } from "./CompanyMemberModel.base"
import { CompanyNewModel, CompanyNewModelType } from "./CompanyNewModel"
import { CompanyNewModelSelector } from "./CompanyNewModel.base"
import { CompanyVideoModel, CompanyVideoModelType } from "./CompanyVideoModel"
import { CompanyVideoModelSelector } from "./CompanyVideoModel.base"
import { CompetitorModel, CompetitorModelType } from "./CompetitorModel"
import { CompetitorModelSelector } from "./CompetitorModel.base"
import { FundModel, FundModelType } from "./FundModel"
import { FundModelSelector } from "./FundModel.base"
import { FundingRoundModel, FundingRoundModelType } from "./FundingRoundModel"
import { FundingRoundModelSelector } from "./FundingRoundModel.base"
import { GrowthEnum } from "./GrowthEnum"
import { IndustryModel, IndustryModelType } from "./IndustryModel"
import { IndustryModelSelector } from "./IndustryModel.base"
import { TrendModel, TrendModelType } from "./TrendModel"
import { TrendModelSelector } from "./TrendModel.base"
import { RootStoreType } from "./index"


/**
 * CompanyBase
 * auto generated base class for the model CompanyModel.
 */
export const CompanyModelBase = ModelBase
  .named('Company')
  .props({
    __typename: types.optional(types.literal("Company"), "Company"),
    id: types.union(types.undefined, types.integer),
    fund_id: types.union(types.undefined, types.null, types.integer),
    fund: types.union(types.undefined, types.null, types.late((): any => FundModel)),
    ordinal: types.union(types.undefined, types.null, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    growth_category: types.union(types.undefined, types.null, GrowthEnum),
    gross_moic: types.union(types.undefined, types.null, types.number),
    initial_moic: types.union(types.undefined, types.null, types.number),
    show_initial_moic: types.union(types.undefined, types.null, types.boolean),
    show_ownership: types.union(types.undefined, types.null, types.boolean),
    show_valuation: types.union(types.undefined, types.null, types.boolean),
    base10_fmv: types.union(types.undefined, types.null, types.number),
    role: types.union(types.undefined, types.null, types.string),
    logo_url: types.union(types.undefined, types.null, types.string),
    bw_logo_url: types.union(types.undefined, types.null, types.string),
    funding_rounds: types.union(types.undefined, types.array(types.late((): any => FundingRoundModel))),
    about: types.union(types.undefined, types.null, types.string),
    founded_date: types.union(types.undefined, types.null, types.integer),
    invested_date: types.union(types.undefined, types.null, types.integer),
    website: types.union(types.undefined, types.null, types.string),
    headquarters: types.union(types.undefined, types.null, types.string),
    industry: types.union(types.undefined, types.array(types.late((): any => IndustryModel))),
    trend: types.union(types.undefined, types.array(types.late((): any => TrendModel))),
    primary_trend_id: types.union(types.undefined, types.null, types.integer),
    primary_industry_id: types.union(types.undefined, types.null, types.integer),
    founder_name: types.union(types.undefined, types.null, types.string),
    founder_position: types.union(types.undefined, types.null, types.string),
    founder_photo_url: types.union(types.undefined, types.null, types.string),
    founder_quote: types.union(types.undefined, types.null, types.string),
    founder_video_url: types.union(types.undefined, types.null, types.string),
    governance_maturity_index: types.union(types.undefined, types.null, types.number),
    coinvestors: types.union(types.undefined, types.array(types.late((): any => CoinvestorModel))),
    competitors: types.union(types.undefined, types.array(types.late((): any => CompetitorModel))),
    key_team_members: types.union(types.undefined, types.array(types.late((): any => CompanyMemberModel))),
    related_videos: types.union(types.undefined, types.array(types.late((): any => CompanyVideoModel))),
    related_news: types.union(types.undefined, types.array(types.late((): any => CompanyNewModel))),
    /** Assigns a random color based on the company name. */
    color: types.union(types.undefined, types.null, types.string),
    base10_total_investment: types.union(types.undefined, types.null, types.number),
    company_total_investment: types.union(types.undefined, types.null, types.number),
    /** Number of funding rounds for the company */
    num_funding_rounds: types.union(types.undefined, types.null, types.integer),
    /** The idustry in string form */
    industry_string: types.union(types.undefined, types.null, types.string),
    /** The trend(s) in string form */
    trend_string: types.union(types.undefined, types.null, types.string),
    /** Shows the company's primary trend if applicable. Null otherwise */
    primary_trend: types.union(types.undefined, types.null, types.late((): any => TrendModel)),
    /** Shows the company's primary trend if applicable. Null otherwise */
    primary_industry: types.union(types.undefined, types.null, types.late((): any => IndustryModel)),
    /** Shows the company's primary industry in string format if applicable. Null otherwise */
    primary_industry_string: types.union(types.undefined, types.null, types.string),
    /** Shows the company's primary trend in string format if applicable. Null otherwise */
    primary_trend_string: types.union(types.undefined, types.null, types.string),
    /** Trend for Industry string displayed on the companies page */
    industry_trend_string: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CompanyModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get fund_id() { return this.__attr(`fund_id`) }
  get ordinal() { return this.__attr(`ordinal`) }
  get name() { return this.__attr(`name`) }
  get growth_category() { return this.__attr(`growth_category`) }
  get gross_moic() { return this.__attr(`gross_moic`) }
  get initial_moic() { return this.__attr(`initial_moic`) }
  get show_initial_moic() { return this.__attr(`show_initial_moic`) }
  get show_ownership() { return this.__attr(`show_ownership`) }
  get show_valuation() { return this.__attr(`show_valuation`) }
  get base10_fmv() { return this.__attr(`base10_fmv`) }
  get role() { return this.__attr(`role`) }
  get logo_url() { return this.__attr(`logo_url`) }
  get bw_logo_url() { return this.__attr(`bw_logo_url`) }
  get about() { return this.__attr(`about`) }
  get founded_date() { return this.__attr(`founded_date`) }
  get invested_date() { return this.__attr(`invested_date`) }
  get website() { return this.__attr(`website`) }
  get headquarters() { return this.__attr(`headquarters`) }
  get primary_trend_id() { return this.__attr(`primary_trend_id`) }
  get primary_industry_id() { return this.__attr(`primary_industry_id`) }
  get founder_name() { return this.__attr(`founder_name`) }
  get founder_position() { return this.__attr(`founder_position`) }
  get founder_photo_url() { return this.__attr(`founder_photo_url`) }
  get founder_quote() { return this.__attr(`founder_quote`) }
  get founder_video_url() { return this.__attr(`founder_video_url`) }
  get governance_maturity_index() { return this.__attr(`governance_maturity_index`) }
  get color() { return this.__attr(`color`) }
  get base10_total_investment() { return this.__attr(`base10_total_investment`) }
  get company_total_investment() { return this.__attr(`company_total_investment`) }
  get num_funding_rounds() { return this.__attr(`num_funding_rounds`) }
  get industry_string() { return this.__attr(`industry_string`) }
  get trend_string() { return this.__attr(`trend_string`) }
  get primary_industry_string() { return this.__attr(`primary_industry_string`) }
  get primary_trend_string() { return this.__attr(`primary_trend_string`) }
  get industry_trend_string() { return this.__attr(`industry_trend_string`) }
  fund(builder?: string | FundModelSelector | ((selector: FundModelSelector) => FundModelSelector)) { return this.__child(`fund`, FundModelSelector, builder) }
  funding_rounds(builder?: string | FundingRoundModelSelector | ((selector: FundingRoundModelSelector) => FundingRoundModelSelector)) { return this.__child(`funding_rounds`, FundingRoundModelSelector, builder) }
  industry(builder?: string | IndustryModelSelector | ((selector: IndustryModelSelector) => IndustryModelSelector)) { return this.__child(`industry`, IndustryModelSelector, builder) }
  trend(builder?: string | TrendModelSelector | ((selector: TrendModelSelector) => TrendModelSelector)) { return this.__child(`trend`, TrendModelSelector, builder) }
  coinvestors(builder?: string | CoinvestorModelSelector | ((selector: CoinvestorModelSelector) => CoinvestorModelSelector)) { return this.__child(`coinvestors`, CoinvestorModelSelector, builder) }
  competitors(builder?: string | CompetitorModelSelector | ((selector: CompetitorModelSelector) => CompetitorModelSelector)) { return this.__child(`competitors`, CompetitorModelSelector, builder) }
  key_team_members(builder?: string | CompanyMemberModelSelector | ((selector: CompanyMemberModelSelector) => CompanyMemberModelSelector)) { return this.__child(`key_team_members`, CompanyMemberModelSelector, builder) }
  related_videos(builder?: string | CompanyVideoModelSelector | ((selector: CompanyVideoModelSelector) => CompanyVideoModelSelector)) { return this.__child(`related_videos`, CompanyVideoModelSelector, builder) }
  related_news(builder?: string | CompanyNewModelSelector | ((selector: CompanyNewModelSelector) => CompanyNewModelSelector)) { return this.__child(`related_news`, CompanyNewModelSelector, builder) }
  primary_trend(builder?: string | TrendModelSelector | ((selector: TrendModelSelector) => TrendModelSelector)) { return this.__child(`primary_trend`, TrendModelSelector, builder) }
  primary_industry(builder?: string | IndustryModelSelector | ((selector: IndustryModelSelector) => IndustryModelSelector)) { return this.__child(`primary_industry`, IndustryModelSelector, builder) }
}
export function selectFromCompany() {
  return new CompanyModelSelector()
}

export const companyModelPrimitives = selectFromCompany().fund_id.ordinal.name.growth_category.gross_moic.initial_moic.show_initial_moic.show_ownership.show_valuation.base10_fmv.role.logo_url.bw_logo_url.about.founded_date.invested_date.website.headquarters.primary_trend_id.primary_industry_id.founder_name.founder_position.founder_photo_url.founder_quote.founder_video_url.governance_maturity_index.color.base10_total_investment.company_total_investment.num_funding_rounds.industry_string.trend_string.primary_industry_string.primary_trend_string.industry_trend_string
