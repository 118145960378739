import axios from "axios";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
// import {Link} from 'react-router-dom'
import styled from "styled-components";
import { useQuery } from "../../models/reactUtils";
import { trackEvent } from "../../services/mixpanel";
import * as theme from "../../utils/theme";
import { Modal, Wrapper, AppText, AppTitle } from "../UI";

const SHEET_BEST_API = "https://sheet.best/api/sheets/e1fcf345-ca04-4505-b5dc-eda34820c18c";

interface Props {
  showModal: boolean;
  toggleModal: () => void;
}

const DRDisclaimerPopupComponentT: React.FunctionComponent<Props> = ({ showModal, toggleModal }) => {
  const { store } = useQuery();
  const buttonRef = useRef(null);
  const { user, logout, modalShown } = store;
  const acceptDis = async () => {
    trackEvent("DIS Accept");
    axios.post(SHEET_BEST_API, { email: user.email, time: moment().toDate() });
    modalShown();
    toggleModal();
  };
  const scrollToBottom = () => {
    buttonRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleRemoveMe = () => {
    trackEvent("DIS Remove Me");
    trackEvent("logout");
    logout();
  };
  return (
    <PaddingDiv1>
      <Modal height="fit-content" width="fit-content" showModal={showModal} toggleModal={toggleModal}>
        <Wrapper>
          <PaddingDiv2>
            <DisclaimerTitle>
              Sample of Confidentiality Terms and Conditions to Access to Electronic DataRoom
            </DisclaimerTitle>
            <Horizontal>
              <ScrollButton onClick={scrollToBottom}>Scroll to Bottom</ScrollButton>
            </Horizontal>
            <DisclaimerText>
              These conditions of access (the “Conditions of Access”) set forth the terms and conditions between you and
              Base10 Partners Management, LLC, and its affiliates (collectively, “Base10”) regarding your access and use
              of this site (the “Data Room”) in connection with evaluating the possible purchase of an equity interest
              in Base10 Partners Advancement Initiative II, LP, or any of its affiliated funds (the “Fund”). By
              selecting the “AGREE” button or by accessing or using the Data Room or any information or service provided
              herein, you acknowledge and agree, for yourself and the entity, organization or other enterprise for which
              you are evaluating an investment in the Fund (“Your Firm”), that you have read and understand these
              Conditions of Access and you agree to be bound hereby. If you do not agree to all of these terms or are
              not authorizedto do so, you are prohibited from accessing or using the Data Room and you should select the
              “REMOVEME” button.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Information.&nbsp;</span>As used herein, “Information” means all
              materials, information and data that are made available within the Data Room, together with all other
              materials, information and data that may be provided by or for Base10 to you or any Authorized Recipient
              (as defined below), whether in written, electronic or other format, and including all copies, abstracts,
              notes, summaries, analyses and other derivatives of any of the foregoing. Information may include, without
              limitation, information regarding the business, prospects, operations or financial condition of (i) the
              Fund (or other investors in the Fund); (ii) any prior investment fund sponsored by Base10 (a “Prior Fund”)
              (or any investors in a Prior Fund); (iii) Base10, any of its affiliates and any current or former member,
              partner, assignee, equity-holder, officer, employee or agent of any of the foregoing; (iv) the historical
              track record of any Prior Fund or other investments by Base10’s members, managers, officers, employees and
              other affiliates; or (v) any current, prior, or prospective investment in a portfolio company security of
              the Fund or a Prior Fund (each, a “Portfolio Investment”).
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Confidentiality.&nbsp;</span>All Information is proprietary and
              highly confidential, whether or not marked as such. You agree to maintain the Information in strict
              confidence and not to publish, distribute or otherwise disclose any of the Information to any third party
              other than to Your Firm, to any of its affiliates that is a potential investor and to any of their
              respective officers, directors, managers, members, employees, agentsor advisors who have a need to know
              for the permitted purpose (all of the foregoing, “Authorized Recipients”). No disclosure of Information to
              an Authorized Recipient is permitted, however, unless it is subject to the terms and conditions set forth
              herein, and you agree that Your Firm shall be legally responsible for any breach of these Conditions of
              Access by any Authorized Recipient. You represent andwarrant that you are authorized to agree to these
              Conditions of Access on behalf of yourself, Your Firm and each Authorized Recipient.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Restrictions.&nbsp;</span>You agree to exercise all reasonable
              precautions necessary to prevent any unauthorized access, use or disclosure of the Information. You may
              use the Information solely for the purpose of evaluating the possible purchase of an interest in the Fund,
              and not for any other purpose. You may not download, scan, print or otherwise capture any Information from
              the Data Room, except that you may print Information for which the print capability has been intentionally
              enabled. You may not copy any Information, except as necessary to accomplish the permitted purpose. You
              may not attempt to circumvent any of the security features of the Data Room, and you shall not enable or
              allow others to access the Data Room using your credentials. You agree that Base10 may terminate your
              access to the Data Room and use of the Information at any time. Promptly after Base10’s request, you will
              return or destroy all copies of the Information and all information, records and materials developed
              therefrom.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Compelled Disclosure.&nbsp;</span>Neither you nor any Authorized
              Recipient is subject to any legal, regulatory, contractual, ethical, trading exchange or other obligation
              or rule (including without limitation, public disclosure laws such as the Freedom of Information Act
              (FOIA) or any similar national, state or local disclosure law, foreign or domestic) that could result in
              the disclosure of Information without Base10’s prior consent. You will promptly notify Base10 upon
              learning of any legal or governmental order or requirement that purports to compel you to disclose any
              Information, and cooperate with Base10 in its efforts to oppose, limit or seek confidential treatment for
              such disclosure. You will also promptly notify Base10 upon learning of any unauthorized disclosure or use
              of the Information
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Disclaimers.&nbsp;</span>You acknowledge and agree that Base10 makes
              no representations or warranties, express or implied, as to the quality, accuracy or completeness of the
              Information or otherwise; that Base10 shall have no liability with respect to any use or reliance upon any
              of the Information; and that Base10 owns and retains all rights, title and interests in and to the
              Information. Nothing in the Data Room should be construed as legal, business, tax, accounting, investment
              or other advice. You are solely responsible for consulting with your own advisors and determining whether
              the Information is sufficient to properly evaluate an investment in the Fund.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Forward-Looking Statement.&nbsp;</span>Some Information may contain
              “forward-looking statements” within the meaning of the Private Securities Litigation Reform Act of 1995,
              as amended. Because such forward-looking statements involve risks and uncertainties, actual results of a
              Prior Fund and/or any individual investments may differ materially from any expectations, projections or
              predictions made or implicated insuch forward-looking statements. Forward-looking statements are intended
              to express current plans, intentions, beliefs and expectations of Base10 regarding potential future
              economic performance. Statements containing terms and expressions such as “believes,” “does not believe,”
              “forecast,” “plans,” “targets,” “expects,” “intends,” “estimates,” “anticipates,” “objective,”
              “opportunity,” “potential,” “contemplates,” and other phrases of similar meaning imply uncertainty and
              prediction. Actual events or conditions are unlikely to be consistent with, and may differ materially
              from, those assumed. Given these uncertainties, you acknowledge that you should not place undue reliance
              on such forward-looking statements.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Projections.&nbsp;</span> Any projected outcomes, proceeds and
              performance that may be set forth in the Information (the “Projections”) represent a range of potential
              outcomes and are estimated by Base10 as of the date stated in the document where such information is
              presented based upon certain assumptions about future events or conditions. They are intended only to
              illustrate hypothetical results under those assumptions anddo not constitute a forecast or investment
              advice. Not all relevant events or conditions may have been considered in developing such assumptions or
              estimates. The success or achievement of various results and objectives, including exits, realizations of
              indicated valuations, and targeted performance data is dependent upon a multitude of factors, many of
              which are beyond the control of Base10 and are subject torisks, uncertainties and change of circumstances.
              No representations are made that such targets or projections will be realized. Actual performance will
              vary due to a variety of factors, including general economic conditions. The Projections are based on
              assumptions believed to be reasonable in light of the information presently available. Such assumptions
              (and the resulting projections) are subject to further modification by Base10 in its sole and absolute
              discretion as additional information becomes available and as economic and market developments warrant.
              Any such modification could be either favorable or adverse. The Projections do not reflect any actual
              outcomes, proceeds and performance and are forward-looking statements that involve risks and
              uncertainties. Base10 has not independently verified any information obtained from third party sources.
              Base10 does not assume any obligation to provide you with subsequent revisions or updates to any
              historical or forward-looking information contained in the Information to reflect the occurrence of events
              and/or changes in circumstances after the date hereof. Given these uncertainties, you acknowledge that you
              should not place undue reliance on such Projections.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Past Performance.&nbsp;</span>Some Information may contain statements
              about investment performance. You acknowledge that past results are not necessarily indicative of future
              results and that there can be no assurance that the Fund will achieve results comparable to those of any
              Prior Fund or existing fund principals. In addition, Projections made as a result of the prior performance
              of a single Portfolio Investment or a subset of Portfolio Investments may not be representative of the
              actual or future performance of such Portfolio Investments, any similar Portfolio Investments the Fund
              currently invests in or Base10’s complete investment portfolio as a whole.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Case Studies and Examples.&nbsp;</span>
              Some Information may contain case studies and illustrative examples of investments. Such case studies,
              examples and the Portfolio Investments profiled or discussed in certain Information represent a subset of
              Base10’s prior investments and may not be representative of Base10’s investment experience or performance
              as whole. Please refer to Base10’s website https://base10.vc/companies/ for a list of companies in which
              Portfolio Investments have been made. Anyreference to a public company should not be considered a
              recommendation to purchase or sell securities in such company, and is not an indication that an investment
              in the securities of such company was or willbe profitable.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>No Agreement.&nbsp;</span>
              Neither granting access to the Data Room nor disclosing Information to you constitutes an obligation or
              commitment to enter into any transaction. Any agreement relating to an investment in theFund will be
              effective only if, when and as specified in a separate written agreement that is acceptable to and
              executed and delivered by you and the Fund.
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Governing Law and Miscellaneous.&nbsp;</span>
              Your Data Room access credentials and obligations hereunder are personal, non-assignable and
              non-transferable. These Conditions of Access are governed by the laws of the State of Delaware. No change,
              consent or waiver hereunder will be effective unless in writing and signed by Base10 and you. If any
              provision is found to be unenforceable, that provision will be limited or eliminated to the minimum extent
              necessary so that all other terms remain in full force and effect and enforceable. The obligations
              hereunder shall be continuing and will survive the termination of any other agreement between you and
              Base10
            </DisclaimerText>
            <DisclaimerText>
              <span style={{ fontWeight: "bold" }}>Third-Party Beneficiary.&nbsp;</span>
              The Fund and all of its affiliates are intended third-party beneficiaries of these Conditions of Access
              and each of them has the right, power and authority to enforce its provisions.
            </DisclaimerText>
            <EnterPasswordDiv>
              <AcceptButton onClick={handleRemoveMe}>REMOVE ME</AcceptButton>
              <AcceptButton ref={buttonRef} onClick={acceptDis}>
                AGREE
              </AcceptButton>
            </EnterPasswordDiv>
          </PaddingDiv2>
        </Wrapper>
      </Modal>
    </PaddingDiv1>
  );
};

const EnterPasswordDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const AcceptButton = styled.button`
  height: 40px;
  width: 300px;
`;

const PaddingDiv2 = styled.div`
  padding: 50px;
  min-height: 100vh;
  margin: auto auto;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
`;

const PaddingDiv1 = styled.div`
  padding: 50px;
`;

const DisclaimerText = styled(AppText)`
  font-size: 15px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
  margin-bottom: 10px;
`;

const DisclaimerHeading = styled(AppTitle)`
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: ${theme.BLACK_COLOR};
  margin-top: 15px;
`;

const DisclaimerTitle = styled(AppTitle)`
  font-size: 20px;
  color: ${theme.BLACK_COLOR};
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: underline;
`;

const Horizontal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
`;

const ScrollButton = styled(AcceptButton)`
  width: 150px;
`;

const DRDisclaimerPopupComponent = observer(DRDisclaimerPopupComponentT);

export { DRDisclaimerPopupComponent };
